import * as React from "react";
import { useLocation } from "react-router-dom";
import { API_URL } from "../utils/constants";
import { useAuth } from "../utils/auth";

const LoginRedirectPage = () => {
  const { setToken, setUser } = useAuth();
  const [text, setText] = React.useState("Loading...");
  const location = useLocation();

  React.useEffect(() => {
    // Successfully logged with the provider
    // Now logging with strapi by using the access_token (given by the provider) in props.location.search
    fetch(`${API_URL}/api/auth/auth0/callback${location.search}`)
      .then(res => {
        if (res.status !== 200) {
          throw new Error(`Couldn't login to Strapi. Status: ${res.status}`);
        }
        return res;
      })
      .then(res => res.json())
      .then(res => {
        // Successfully logged with Strapi
        // Now saving the jwt to use it for future authenticated requests to Strapi
        setToken(res.jwt);
        setUser(res.user);
        setText(
          "You have been successfully logged in. You will be redirected in a few seconds..."
        );

        setTimeout(() => (window.location.href = "/"), 2000); // Redirect to homepage after 2 sec
      })
      .catch(err => {
        setToken("");
        setUser(null);
        console.log(err);
        setText("An error occurred, please see the developer console.");
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]);

  return (
    <p className="flex h-screen w-screen items-center justify-center">{text}</p>
  );
};

export default LoginRedirectPage;
