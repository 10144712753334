import { Menu, Transition } from "@headlessui/react";
import * as React from "react";
import { Link, Navigate, NavLink, Outlet, useLocation } from "react-router-dom";
import Logo from "../components/Logo";
import Spinner from "../components/Spinner";
import { AUTH_STATUS, useAuth } from "../utils/auth";
import { API_URL } from "../utils/constants";
import { useTheme } from "../utils/theme";
import { ExternalLinkIcon, SearchIcon } from "@heroicons/react/outline";
import { useQuery } from "react-query";
import { findAllBoards } from "../utils/queries";
import Fuse from "fuse.js";
import useKeyPress from "../hooks/useKeyPress";
import StoreCredit, {
  NoCreditsAlert,
  NoSubscriptionWarning,
} from "../components/StoreCredit";

const ExpandIcon = () => {
  return (
    <svg
      fill="currentColor"
      width="12px"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 448 512"
    >
      <path d="M128 32H32C14.31 32 0 46.31 0 64v96c0 17.69 14.31 32 32 32s32-14.31 32-32V96h64c17.69 0 32-14.31 32-32S145.7 32 128 32zM416 32h-96c-17.69 0-32 14.31-32 32s14.31 32 32 32h64v64c0 17.69 14.31 32 32 32s32-14.31 32-32V64C448 46.31 433.7 32 416 32zM128 416H64v-64c0-17.69-14.31-32-32-32s-32 14.31-32 32v96c0 17.69 14.31 32 32 32h96c17.69 0 32-14.31 32-32S145.7 416 128 416zM416 320c-17.69 0-32 14.31-32 32v64h-64c-17.69 0-32 14.31-32 32s14.31 32 32 32h96c17.69 0 32-14.31 32-32v-96C448 334.3 433.7 320 416 320z" />
    </svg>
  );
};

const CompressIcon = () => {
  return (
    <svg
      fill="currentColor"
      width="12px"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 448 512"
    >
      <path d="M128 320H32c-17.69 0-32 14.31-32 32s14.31 32 32 32h64v64c0 17.69 14.31 32 32 32s32-14.31 32-32v-96C160 334.3 145.7 320 128 320zM416 320h-96c-17.69 0-32 14.31-32 32v96c0 17.69 14.31 32 32 32s32-14.31 32-32v-64h64c17.69 0 32-14.31 32-32S433.7 320 416 320zM320 192h96c17.69 0 32-14.31 32-32s-14.31-32-32-32h-64V64c0-17.69-14.31-32-32-32s-32 14.31-32 32v96C288 177.7 302.3 192 320 192zM128 32C110.3 32 96 46.31 96 64v64H32C14.31 128 0 142.3 0 160s14.31 32 32 32h96c17.69 0 32-14.31 32-32V64C160 46.31 145.7 32 128 32z" />
    </svg>
  );
};

const AppMenu = () => {
  const { status, store, user, role, logout } = useAuth();

  if (status !== AUTH_STATUS.AUTHENTICATED) {
    return null;
  }

  return (
    <Menu as="div" className="relative inline-block text-left">
      <Menu.Button className="ml-2 h-9 w-9 min-w-[2.25rem] rounded-full bg-slate-400 py-1 px-2 text-sm font-bold uppercase">
        {store?.name.slice(0, 1)}
      </Menu.Button>
      <Transition
        as={React.Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-50 mt-1 w-80 origin-top-right divide-y divide-gray-100 rounded bg-white text-gray-800 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="flex items-center p-3">
            <div className="w-full overflow-x-hidden text-ellipsis whitespace-nowrap">
              <p className="w-full font-semibold uppercase">{store?.name}</p>
              {user?.email && (
                <small className="w-full text-slate-500" title={user?.email}>
                  {user?.email}
                </small>
              )}
              <div className="mt-1">
                <Menu.Item>
                  <StoreCredit />
                </Menu.Item>
              </div>
            </div>
          </div>
          {role === "admin" && (
            <div className="px-1 py-1">
              <Menu.Item>
                {({ active }) => (
                  <Link
                    to="/"
                    className={`${
                      active ? "bg-gray-100" : ""
                    } group flex w-full items-center rounded-sm px-2 py-2 text-sm`}
                  >
                    Home
                  </Link>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <Link
                    to="/settings"
                    className={`${
                      active ? "bg-gray-100" : ""
                    } group flex w-full items-center rounded-sm px-2 py-2 text-sm`}
                  >
                    Settings
                  </Link>
                )}
              </Menu.Item>
              {/* <Menu.Item>
                {({ active }) => (
                  <Link
                    to="/settings/credits"
                    className={`${
                      active ? "bg-gray-100" : ""
                    } group flex w-full items-center rounded-sm px-2 py-2 text-sm`}
                  >
                    Billing
                  </Link>
                )}
              </Menu.Item> */}
            </div>
          )}

          <div className="px-1 py-1">
            <Menu.Item>
              {({ active }) => (
                <a
                  className={`${
                    active ? "bg-gray-100" : ""
                  } group flex w-full items-center rounded-sm px-2 py-2 text-sm`}
                  href="mailto:support@concertrx.com"
                >
                  Contact support{" "}
                  <ExternalLinkIcon className="ml-2 h-4 w-4 text-slate-500" />
                </a>
              )}
            </Menu.Item>
          </div>
          <div className="px-1 py-1">
            <Menu.Item>
              {({ active }) =>
                role === "ip_login" ? (
                  <button
                    onClick={() => {
                      window.location.href = `${API_URL}/api/connect/auth0`;
                    }}
                    className={`${
                      active ? "bg-gray-100" : ""
                    } group flex w-full items-center rounded-sm px-2 py-2 text-sm`}
                  >
                    Login
                  </button>
                ) : (
                  <button
                    onClick={logout}
                    className={`${
                      active ? "bg-gray-100" : ""
                    } group flex w-full items-center rounded-sm px-2 py-2 text-sm`}
                  >
                    Logout
                  </button>
                )
              }
            </Menu.Item>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

const AppFullscreen = () => {
  const [fullscreen, setFullscreen] = React.useState(false);

  const toggleFullScreen = () => {
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen();
    } else if (document.exitFullscreen) {
      document.exitFullscreen();
    }
  };

  React.useEffect(() => {
    const onFullScreenChange = () => {
      setFullscreen(v => !v);
    };

    document.addEventListener("fullscreenchange", onFullScreenChange);

    return () => {
      document.removeEventListener("fullscreenchange", onFullScreenChange);
    };
  }, []);

  return (
    <button
      className="ml-2 flex items-center rounded py-1 px-2 text-sm hover:bg-black/5"
      onClick={toggleFullScreen}
    >
      {fullscreen ? <CompressIcon /> : <ExpandIcon />}
      <span className="ml-2">
        {fullscreen ? "Exit Fullscreen" : "Fullscreen"}
      </span>
    </button>
  );
};

const AppSearch = () => {
  const { data } = useQuery(["/boards"], findAllBoards);
  const [open, setOpen] = React.useState(false);
  const [query, setQuery] = React.useState("");
  const [results, setResults] = React.useState([]);

  const input = React.useRef(null);
  const fuse = React.useRef(null);

  const boards = React.useMemo(() => data?.data || [], [data]);

  React.useEffect(() => {
    fuse.current = new Fuse(boards, {
      includeScore: true,
      threshold: 0.4,
      keys: ["attributes.title", "attributes.description"],
      useExtendedSearch: false,
    });
  }, [boards]);

  React.useEffect(() => {
    if (query !== "") {
      let results = fuse.current.search(query);
      setResults(results.slice(0, 8).map(r => r.item));
    } else {
      setResults([]);
    }
  }, [query]);

  const onOpen = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  useKeyPress({
    targetKey: "k",
    modifiers: ["metaKey"],
    callback: () => {
      onOpen();
      input.current?.focus();
    },
    disable: !data,
  });

  const { status, store, role } = useAuth();

  if (
    status !== AUTH_STATUS.AUTHENTICATED ||
    (!store?.approved && role !== "admin") ||
    !data
  ) {
    return null;
  }

  return (
    <Menu as="div" className="relative hidden items-center sm:flex">
      {open && <div className="fixed inset-0 z-50" onClick={onClose} />}
      <input
        ref={input}
        className="z-50 h-7 min-w-[16rem] rounded border !border-black/20 bg-white/25 pr-2 pl-6 text-xs placeholder:text-inherit"
        type="text"
        placeholder="Search Boards..."
        value={query}
        onChange={e => setQuery(e.target.value)}
        onFocus={e => {
          e.target.select();
          onOpen();
        }}
      />
      <SearchIcon className="absolute left-2 h-3 w-3 uppercase text-black/50" />
      {/* <small className="absolute right-2  tracking-wider text-black/50">
        ⌘K
      </small> */}
      <Transition
        show={open}
        as={React.Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute top-7 right-0 z-50 mt-2 w-96 origin-top-right rounded bg-white text-gray-800 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          {query ? (
            <div className="px-1 py-1">
              {results.length > 0 ? (
                results.map(item => (
                  <Menu.Item key={item.id}>
                    {({ active }) => (
                      <Link
                        onClick={onClose}
                        to={`/boards/${item.id}`}
                        className={`${
                          active ? "bg-gray-100" : ""
                        } group flex w-full items-center rounded-sm px-2 py-2 text-sm`}
                      >
                        {item.attributes.title}
                      </Link>
                    )}
                  </Menu.Item>
                ))
              ) : (
                <div className="flex items-center px-3 py-2">
                  <small className="text-gray-400">No results found.</small>
                </div>
              )}
            </div>
          ) : (
            <div className="flex items-center p-3">
              <small className="text-gray-400">
                Start typing to see results...
              </small>
            </div>
          )}
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

const AppNav = () => {
  const { status, store } = useAuth();

  if (status === AUTH_STATUS.UNAUTHENTICATED) {
    return (
      <a
        href={`${API_URL}/api/connect/auth0`}
        className="ml-2 rounded border border-black/10 py-1 px-2 text-sm"
      >
        Login
      </a>
    );
  }

  if (store?.compoundingEnabled)
    return (
      <NavLink
        to="/compounding"
        className={({ isActive }) =>
          `${
            isActive ? "border-blue-600 ring-2 ring-offset-0" : ""
          } ml-2 rounded border border-black/10 bg-transparent py-1 px-2 text-sm`
        }
      >
        Compounding
      </NavLink>
    );

  return null;
};

const AppContent = () => {
  const { store, status, role } = useAuth();
  const location = useLocation();

  if (status === AUTH_STATUS.LOADING) {
    return (
      <div className="p-4">
        <Spinner />
      </div>
    );
  }

  if (location?.pathname.includes("/settings") && role !== "admin") {
    return <Navigate to="/" />;
  }

  if (
    location?.pathname !== "/settings" &&
    !store?.approved &&
    role === "admin"
  ) {
    return <Navigate to="/settings" />;
  }

  if (!store?.approved && role !== "admin") {
    return (
      <div className="p-4">
        <p>
          <span className="font-semibold uppercase">{store?.name}</span> has not
          been approved yet.
        </p>
        <p className="mt-4 text-sm text-gray-600">
          Contact the Admin for more details.
          <span className="mx-2 text-xs text-gray-500">OR</span>
          <a href={`${API_URL}/api/connect/auth0`} className="underline">
            Login
          </a>{" "}
          if you are the Admin.
        </p>
      </div>
    );
  }

  if (status === AUTH_STATUS.AUTHENTICATED) {
    return <Outlet />;
  }

  return null;
};

const AppLayout = () => {
  const { bgColor, textColor, setColor } = useTheme();
  const location = useLocation();

  React.useEffect(() => {
    if (
      ["/", "/boards", "/settings", "/compounding"].includes(location.pathname)
    ) {
      setColor(null);
    }
  }, [location, setColor]);

  return (
    <div
      style={{
        backgroundColor: bgColor,
        color: textColor,
        minHeight: "100vh",
      }}
    >
      <header
        className={`flex h-12 items-center justify-between bg-black/10 px-4`}
      >
        <div className="flex items-center">
          <NavLink to="/" className="no-underline">
            <Logo className="-mt-1 h-10" />
          </NavLink>
        </div>
        <div className="flex items-center">
          <AppSearch />
          <AppNav />
          <AppFullscreen />
          <AppMenu />
        </div>
      </header>
      <main className="flex flex-col items-start">
        <NoCreditsAlert />
        <NoSubscriptionWarning />
        <AppContent />
      </main>
    </div>
  );
};

export default AppLayout;
