import { useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import { useAuth } from "../utils/auth";
import { classNames } from "../utils/helpers";
import { isValid } from "ipaddr.js";
import { updateStore } from "../utils/mutations";
import Alert from "../components/Alert";

const AccountAlert = () => {
  const { store } = useAuth();

  const isApproved = store?.approved;
  const isReviewed = store?.reviewed;
  const isComplete =
    store?.name &&
    store?.abn &&
    store?.ip &&
    store?.address &&
    store?.alphanumericSenderId;

  if (isApproved) {
    return (
      <Alert
        type="success"
        content={
          <p>
            Your pharmacy details have been approved! Contact{" "}
            <a href="mailto:support@concertrx.com" className="underline">
              support@concertrx.com
            </a>{" "}
            to make any changes.
          </p>
        }
      />
    );
  }

  if (isReviewed) {
    return (
      <Alert
        type="error"
        content={
          <p>
            Your pharmacy details have been rejected!! For further details
            contact{" "}
            <a href="mailto:support@concertrx.com" className="underline">
              support@concertrx.com
            </a>
            .{" "}
          </p>
        }
      />
    );
  }

  if (isComplete) {
    return (
      <Alert
        type="warning"
        content="Your pharmacy details are being reviewed. Confirm your details below. Once reviewed you will not be able to make changes."
      />
    );
  }

  return (
    <Alert
      type="info"
      content="Update your pharmacy details below to be approved for use on ConcertRx."
    />
  );
};
const AccountPage = () => {
  const { store } = useAuth();
  const queryClient = useQueryClient();
  const isReviewed = store?.reviewed;

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      name: store?.name,
      ip: store?.ip,
      address: store?.address,
      alphanumericSenderId: store?.alphanumericSenderId,
      utcOffset: store?.utcOffset,
      startTime: store?.startTime?.slice(0, 5),
      stopTime: store?.stopTime?.slice(0, 5),
    },
    shouldUnregister: true,
  });

  const { mutate, isLoading } = useMutation(updateStore, {
    onSuccess: data => {
      queryClient.setQueryData(["/store"], oldData => {
        return {
          ...oldData,
          store: {
            ...oldData.store,
            ...data.store,
          },
        };
      });
      queryClient.refetchQueries(["/store"]);
      reset();
      toast.success("Details updated");
    },
    onError: () => {
      toast.error("Error updating details");
    },
  });

  const onSubmit = data => {
    mutate({
      data: {
        name: data.name,
        ip: data.ip,
        abn: data.abn,
        address: data.address,
        alphanumericSenderId: data.alphanumericSenderId,
        utcOffset: data.utcOffset,
        startTime: `${data.startTime}:00.000`,
        stopTime: `${data.stopTime}:00.000`,
      },
    });
  };

  const isDisabled = isLoading || isReviewed;

  return (
    <div className="w-full">
      <h2 className="w-full text-xl font-semibold text-slate-700">Account</h2>
      <p className="text-xs text-slate-600">Update your pharmacy details.</p>
      <hr className="my-4" />
      <AccountAlert />
      <form onSubmit={handleSubmit(onSubmit)}>
        <h3 className="mt-4 w-full text-lg font-medium text-slate-700">
          Pharmacy Details
        </h3>
        <div className="grid grid-cols-1 gap-x-4 md:grid-cols-2">
          <div className="form-group">
            <label htmlFor="name">Name</label>
            <input
              type="text"
              id="name"
              className={`${classNames(errors.name && "form-error")}`}
              placeholder="Enter Name..."
              disabled={isDisabled}
              {...register("name", {
                required: {
                  value: true,
                  message: "Pharmacy Name is required",
                },
                maxLength: {
                  value: 100,
                  message: "Pharmacy Name must be less than 100 characters",
                },
              })}
            />
            {errors.name && (
              <small className="form-error-text">{errors.name.message}</small>
            )}
          </div>
          <div className="form-group">
            <label htmlFor="abn">ABN</label>
            <input
              type="text"
              id="abn"
              className={`${classNames(errors.abn && "form-error")}`}
              placeholder="Enter ABN..."
              disabled={isDisabled}
              {...register("abn", {
                required: {
                  value: true,
                  message: "Pharmacy ABN is required",
                },
              })}
            />

            {errors.abn && (
              <small className="form-error-text">{errors.abn.message}</small>
            )}
          </div>
          <div className="form-group">
            <label htmlFor="ip">Sender ID</label>
            <input
              type="text"
              id="alphanumericSenderId"
              className={`${classNames(
                errors.alphanumericSenderId && "form-error"
              )}`}
              placeholder="Enter Sender ID..."
              disabled={isDisabled}
              {...register("alphanumericSenderId", {
                required: {
                  value: true,
                  message: "Pharmacy Sender ID is required",
                },
                minLength: {
                  value: 5,
                  message:
                    "Pharmacy Sender ID cannot be less then 5 characters",
                },
                maxLength: {
                  value: 11,
                  message:
                    "Pharmacy Sender ID cannot be more then 11 characters",
                },
                validate: value => {
                  return (
                    value.match(/^(?=.*[a-zA-Z])([a-zA-Z0-9\s]+)$/)[0] ===
                      value ||
                    "Pharmacy Sender ID can only contain letter, numbers and spaces and must contain at least one letter."
                  );
                },
              })}
            />
            <small className="form-info-text">
              A unique 11 digit alpha-numeric sender ID. Customers will receive
              messages from this ID.
            </small>
            {errors.alphanumericSenderId && (
              <small className="form-error-text">
                {errors.alphanumericSenderId.message}
              </small>
            )}
          </div>
          <div className="form-group">
            <label htmlFor="ip">IP Address</label>
            <input
              type="text"
              id="ip"
              className={`${classNames(errors.ip && "form-error")}`}
              placeholder="Enter IP..."
              disabled={isDisabled}
              {...register("ip", {
                validate: value => {
                  return (
                    isValid(value) ||
                    "Pharmacy IP must be a valid IPv4 or IPv6 address"
                  );
                },
              })}
            />
            <small className="form-info-text">
              Static IP Address of the pharmacy. This allows users accessing the
              app from the pharmacy's network to perform basic actions without
              logging in.
            </small>
            {errors.ip && (
              <small className="form-error-text">{errors.ip.message}</small>
            )}
          </div>
          <div className="form-group">
            <label htmlFor="address">Address</label>
            <textarea
              id="address"
              placeholder="Enter Address..."
              disabled={isDisabled}
              className={`${classNames(errors.address && "form-error")}`}
              rows={2}
              {...register("address", {
                required: {
                  value: true,
                  message: "Pharmacy Address is required",
                },
              })}
            />
            <small className="form-info-text">
              Please specify the address in the following format: ADDRESS LINE
              1, SUBURB STATE POSTCODE
            </small>
            {errors.address && (
              <small className="form-error-text">
                {errors.address.message}
              </small>
            )}
          </div>
        </div>

        <h3 className="mt-4 w-full text-lg font-medium text-slate-700">
          SMS delivery window
        </h3>
        <p className="my-2 inline-block text-sm text-gray-500">
          Messages will not be delivered outside of these timings, and be queued
          for the next day.
        </p>
        <div className="grid grid-cols-1 gap-x-4 md:grid-cols-2">
          <div className="form-group">
            <label htmlFor="startTime">Store opening time</label>
            <input
              type="time"
              id="startTime"
              className={`${classNames(errors.startTime && "form-error")}`}
              placeholder="Enter opening time..."
              disabled={isDisabled}
              {...register("startTime", {
                required: {
                  value: true,
                  message: "Pharmacy opening time is required",
                },
              })}
            />
            <small className="form-info-text">
              The time that the pharmacy opens at.
            </small>
            {errors.startTime && (
              <small className="form-error-text">
                {errors.startTime.message}
              </small>
            )}
          </div>
          <div className="form-group">
            <label htmlFor="stopTime">Store closing time</label>
            <input
              type="time"
              id="stopTime"
              className={`${classNames(errors.stopTime && "form-error")}`}
              placeholder="Enter closing time..."
              disabled={isDisabled}
              {...register("stopTime", {
                required: {
                  value: true,
                  message: "Pharmacy closing time is required",
                },
              })}
            />
            <small className="form-info-text">
              The time that the pharmacy closes at.
            </small>
            {errors.stopTime && (
              <small className="form-error-text">
                {errors.stopTime.message}
              </small>
            )}
          </div>
          <div className="form-group">
            <label htmlFor="utcOffset">Timezone</label>
            <select
              className={`${classNames(errors.utcOffset && "form-error")}`}
              name="utcOffset"
              placeholder="Select Timezone..."
              disabled={isDisabled}
              {...register("utcOffset", {
                required: {
                  value: true,
                  message: "Store Timezone is required",
                },
              })}
            >
              <option value={"00:00"}>Select Timezone...</option>
              <option value={"10:00"}>
                Australian Eastern Standard Time (AEST)
              </option>
              <option value={"9:30"}>
                Australian Central Standard Time (ACST)
              </option>
              <option value={"8:00"}>
                Australian Western Standard Time (AWST)
              </option>
              <option value={"11:00"}>
                Norfolk Island Standard Time (NFT)
              </option>
              <option value={"5:00"}>Other</option>
            </select>
            {errors.utcOffset && (
              <small className="form-error-text">
                {errors.utcOffset.message}
              </small>
            )}
          </div>
        </div>
        <button
          className="btn-primary ml-auto mb-10 block min-w-[120px]"
          disabled={isDisabled}
        >
          Save
        </button>
      </form>
    </div>
  );
};

export default AccountPage;
