import React from "react";
import { useQuery } from "react-query";
import { Link, useLocation } from "react-router-dom";
import { ArrowRightIcon, CheckIcon } from "@heroicons/react/outline";
import { classNames } from "../utils/helpers";
import {
  getBillingPortal,
  getOrCreateStoreCredit,
  getSubscriptionSession,
} from "../utils/queries";
import { toast } from "react-toastify";
import Spinner from "./Spinner";
import Alert from "./Alert";

const StoreCredit = ({ linkToCredits = true, onClick = () => {} }) => {
  const { data, isFetching } = useQuery(
    ["/store/credit"],
    getOrCreateStoreCredit,
    {
      retry: false,
    }
  );

  if (!data) {
    return null;
  }

  const Component = linkToCredits ? Link : "span";

  return (
    <Component
      to="/settings/credits"
      className={classNames(
        "flex items-center rounded border p-2 text-xs",
        data.credit.amount <= 0
          ? "border-red-500 bg-red-50 text-red-600"
          : data.credit.amount < 20
          ? "border-yellow-500 bg-yellow-50 text-yellow-600"
          : "border-blue-500 bg-blue-50 text-blue-600"
      )}
      onClick={onClick}
    >
      <img
        src={isFetching ? "/ConcertRxCoin.gif" : "/ConcertRxCoin.png"}
        alt="coin"
        className="mr-1 h-4 w-4 mix-blend-multiply"
      />
      {data.credit.amount <= 0 ? (
        "No Credits Available. Rechange Now!"
      ) : (
        <>
          Available Credits:{" "}
          <strong className="ml-1">{data.credit.amount}</strong>{" "}
        </>
      )}
    </Component>
  );
};

export const NoCreditsAlert = () => {
  const { data } = useQuery(["/store/credit"], getOrCreateStoreCredit, {
    retry: false,
  });

  if (!data) {
    return null;
  }

  if (data.credit.amount <= 0) {
    return (
      <Link
        to="/settings/credits"
        className={classNames(
          "flex w-full items-center border-2 border-red-500 bg-red-100 p-2 text-xs text-black"
        )}
      >
        <span className="relative mx-2 h-2.5 w-2.5 rounded-full bg-red-500">
          <span className="absolute inset-0 h-2.5 w-2.5 animate-ping rounded-full bg-red-700" />
        </span>
        No Credits Available. Messages will not be sent. Rechange Now!
      </Link>
    );
  }

  if (data.credit.amount <= 20) {
    return (
      <Link
        to="/settings/credits"
        className={classNames(
          "flex w-full items-center border-2 border-yellow-500 bg-yellow-100 p-2 text-xs text-black"
        )}
      >
        <span className="relative mx-2 h-2.5 w-2.5 rounded-full bg-yellow-500">
          <span className="absolute inset-0 h-2.5 w-2.5 animate-ping rounded-full bg-yellow-700" />
        </span>
        Low Credits. Rechange Now!
      </Link>
    );
  }

  return null;
};

const SubscribeNow = () => {
  const location = useLocation();

  const params = new URLSearchParams(location.search);

  const status = params.get("status");

  const { refetch, isFetching, remove } = useQuery(
    ["/subscribe"],
    getSubscriptionSession,
    {
      enabled: false,
      onSuccess: data => {
        window.location.href = data.sessionUrl;
      },
      onError: () => {
        toast.error("Something went wrong");
      },
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      retryOnMount: false,
      retry: false,
    }
  );

  return (
    <button
      className={classNames(
        "flex w-full cursor-pointer items-center justify-center rounded p-3 text-center text-xs font-bold uppercase tracking-wider md:p-4 md:text-sm",
        "bg-[#49B649] text-white transition-opacity hover:opacity-90 focus:outline-none focus:ring-2 focus:ring-[#49B64999] focus:ring-offset-2"
      )}
      onClick={() => {
        remove();
        refetch();
      }}
    >
      {status === "cancel" ? "Retry" : "Subscribe Now"}
      {isFetching ? (
        <span className="ml-2">
          <Spinner />
        </span>
      ) : (
        <ArrowRightIcon className="ml-2 h-4 w-4" />
      )}
    </button>
  );
};

const OpenPortal = () => {
  const { remove, refetch, isFetching } = useQuery(
    ["/billing-portal"],
    getBillingPortal,
    {
      enabled: false,
      onSuccess: data => {
        window.location.href = data.sessionUrl;
      },
      onError: () => {
        toast.error("Something went wrong");
      },
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      retryOnMount: false,
      retry: false,
    }
  );

  return (
    <button
      className={classNames(
        "flex w-full cursor-pointer items-center justify-center rounded p-3 text-center text-xs font-bold uppercase tracking-wider md:p-4 md:text-sm",
        "bg-[#49B649] text-white transition-opacity hover:opacity-90 focus:outline-none focus:ring-2 focus:ring-[#49B64999] focus:ring-offset-2"
      )}
      onClick={() => {
        remove();
        refetch();
      }}
    >
      Open Billing Portal
      {isFetching ? (
        <span className="ml-2">
          <Spinner />
        </span>
      ) : (
        <ArrowRightIcon className="ml-2 h-4 w-4" />
      )}
    </button>
  );
};

export const NoSubscriptionWarning = () => {
  const location = useLocation();

  const params = new URLSearchParams(location.search);

  const status = params.get("status");

  const { data } = useQuery(["/store/credit"], getOrCreateStoreCredit, {
    retry: false,
  });

  if (!data) {
    return null;
  }

  if (!data?.credit?.stripeSubscription) {
    return (
      <>
        <div className="fixed inset-0 z-[500] bg-black bg-opacity-25" />

        <div className="fixed inset-8 z-[1000] flex flex-col items-center justify-center">
          <div className="flex w-full max-w-lg flex-col rounded-xl bg-white p-8 ">
            {status === "cancel" && (
              <Alert type="error" content="Failed to Subscribe" />
            )}
            <p className="my-2 text-lg font-semibold text-slate-900">
              Subscribe to ConcertRx
            </p>

            <div className="mb-5 flex">
              <span className="mr-1 mt-0.5 text-base font-semibold text-slate-700 md:text-lg">
                $
              </span>
              <span className="text-4xl font-semibold text-slate-900 lg:text-5xl">
                29.95
              </span>
              <span className="mb-1 ml-1 self-end text-xs font-semibold text-slate-700 md:text-sm">
                {" "}
                / mo
              </span>
            </div>
            <p className="mb-2 text-base font-medium text-slate-700">
              What you get:
            </p>

            <ul>
              {[
                "Unlimited boards",
                "Free SENDER ID",
                "IP address login",
                "Buy SMS credits for $0.1 / credit",
                "GST included in pricing",
                "Credits do not expire",
              ].map((feature, index) => (
                <li
                  className="mb-3 flex items-center text-base font-medium text-slate-900"
                  key={index}
                >
                  <CheckIcon className="mr-2 h-5 w-5 text-slate-400" />
                  <p className="text-sm md:text-base">{feature}</p>
                </li>
              ))}
            </ul>
            <div className="mt-6">
              <SubscribeNow />
            </div>
          </div>
        </div>
      </>
    );
  }

  if (
    data?.credit?.subscriptionStatus === "active" &&
    status === "success" &&
    location.pathname === "/"
  ) {
    return (
      <>
        <div className="fixed inset-0 z-[500] bg-black bg-opacity-25" />

        <div className="fixed inset-8 z-[1000] flex flex-col items-center justify-center">
          <div className="flex w-full max-w-lg flex-col rounded-xl bg-white p-8 ">
            <Alert
              type="success"
              content="Yayy! You're subscribed to ConcertRx now"
            />

            <p className="mb-2 mt-4 text-base font-medium text-slate-700">
              You can now enjoy the following features:
            </p>

            <ul>
              {[
                "Unlimited boards",
                "Free SENDER ID",
                "IP address login",
                "Buy SMS credits for $0.1 / credit",
                "GST included in pricing",
                "Credits do not expire",
              ].map((feature, index) => (
                <li
                  className="mb-3 flex items-center text-base font-medium text-slate-900"
                  key={index}
                >
                  <CheckIcon className="mr-2 h-5 w-5 text-slate-400" />
                  <p className="text-sm md:text-base">{feature}</p>
                </li>
              ))}
            </ul>
            <div className="mt-4">
              <Link
                to="/"
                className={classNames(
                  "flex w-full cursor-pointer items-center justify-center rounded p-3 text-center text-xs font-bold uppercase tracking-wider md:p-4 md:text-sm",
                  "bg-[#49B649] text-white transition-opacity hover:opacity-90 focus:outline-none focus:ring-2 focus:ring-[#49B64999] focus:ring-offset-2"
                )}
              >
                Let's go <ArrowRightIcon className="ml-2 h-4 w-4" />
              </Link>
            </div>
          </div>
        </div>
      </>
    );
  }

  if (data?.credit?.subscriptionStatus !== "active") {
    return (
      <>
        <div className="fixed inset-0 z-[500] bg-black bg-opacity-25" />

        <div className="fixed inset-8 z-[1000] flex flex-col items-center justify-center">
          <div className="flex w-full max-w-lg flex-col rounded-xl bg-white p-8 ">
            <Alert
              type="error"
              content="Your ConcertRx Subscription is not active."
            />

            <p className="my-8 text-base font-medium text-slate-700">
              Visit the Billing Portal to manage your subscription and payment
              methods.
            </p>

            <OpenPortal />
          </div>
        </div>
      </>
    );
  }

  return null;
};

export default StoreCredit;
