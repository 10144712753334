import * as React from "react";
import { useQuery } from "react-query";
import useCookie from "react-use-cookie";
import useLocalStorage from "../hooks/useLocalStorage";
import { API_URL, AUTH0_CLIENT_ID, AUTH0_DOMAIN } from "./constants";
import { getOrCreateStore } from "./queries";

export const AUTH_STATUS = {
  LOADING: "LOADING",
  AUTHENTICATED: "AUTHENTICATED",
  UNAUTHENTICATED: "UNAUTHENTICATED",
};

export const AuthContext = React.createContext({
  status: AUTH_STATUS.LOADING,
  token: "",
  setToken: () => {},
  user: null,
  setUser: () => {},
  store: null,
  role: null,
});

export const useAuth = () => {
  return React.useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
  const [token, setToken] = useCookie("crt_token", "");
  const [user, setUser] = useLocalStorage("crt_user", null);
  const [status, setStatus] = React.useState(AUTH_STATUS.LOADING);

  const { data } = useQuery(["/store"], getOrCreateStore, {
    retry: false,
    onSuccess: data => {
      if (data.store) {
        setStatus(AUTH_STATUS.AUTHENTICATED);
      }
    },
    onError: () => {
      setStatus(AUTH_STATUS.UNAUTHENTICATED);
      window.location.href = `${API_URL}/api/connect/auth0`;
    },
  });

  const { store, role } = data || {};

  const logout = async () => {
    setToken("");
    setUser(null);
    window.location.href = `https://${AUTH0_DOMAIN}/v2/logout?client_id=${AUTH0_CLIENT_ID}&returnTo=${window.location.origin}&federated`;
  };

  return (
    <AuthContext.Provider
      value={{
        token,
        setToken,
        user,
        setUser,
        store,
        role: role ? role : store ? "ip_login" : null,
        status,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
