import { Dialog, Transition } from "@headlessui/react";
import React from "react";
import { classNames } from "../../utils/helpers";

const FormDialog = ({
  open,
  onClose,
  title,
  disableClose,
  expand,
  children,
}) => {
  const handleClose = () => {
    // if (disableClose) return;
    // onClose();
  };

  return (
    <Transition appear show={open} as={React.Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-50 overflow-y-auto bg-black/50"
        onClose={handleClose}
      >
        <div className="min-h-screen px-4 text-center">
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0" />
          </Transition.Child>

          <span
            className="inline-block h-screen align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div
              className={classNames(
                "my-8 inline-block w-full transform overflow-hidden rounded bg-white p-6 text-left align-middle shadow-xl transition-all",
                expand ? "max-w-5xl" : "max-w-xl"
              )}
            >
              {title && (
                <Dialog.Title
                  as="h3"
                  className="text-center text-xl font-medium leading-6 text-gray-900"
                >
                  {title}
                </Dialog.Title>
              )}

              <div className="mt-4">{children}</div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

export default FormDialog;
