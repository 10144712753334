import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import relativeTime from "dayjs/plugin/relativeTime";
import utc from "dayjs/plugin/utc";
import { ErrorBoundary } from "react-error-boundary";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import {
  BrowserRouter as Router,
  Outlet,
  Route,
  Routes,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import AppLayout from "./layouts/AppLayout";
import BoardsLayout from "./layouts/BoardsLayout";
import SettingsLayout from "./layouts/SettingsLayout";
import NotFoundPage from "./pages/404";
import CreditsPage from "./pages/Credits";
import BoardPage from "./pages/Board";
import BoardsPage from "./pages/Boards";
import LoginRedirectPage from "./pages/LoginRedirect";
import AccountPage from "./pages/Account";
import SubscriptionPage from "./pages/Subscription";
import IntegrationsPage from "./pages/Integrations";
import PaymentMethodsPage from "./pages/PaymentMethods";
import SubscriberAgreementPage from "./pages/SubscriberAgreement";
import UsersPage from "./pages/Users";
import { AuthProvider } from "./utils/auth";
import { ThemeProvider } from "./utils/theme";

import "react-toastify/dist/ReactToastify.min.css";
import CompoundingPage from "./pages/Compounding";

dayjs.extend(relativeTime);
dayjs.extend(utc);
dayjs.extend(isBetween);

const AppRoutes = () => {
  return (
    <Routes>
      <Route path="connect/auth0/redirect" element={<LoginRedirectPage />} />
      <Route path="/" element={<AppLayout />}>
        <Route path="/" element={<BoardsLayout />}>
          <Route index element={<BoardsPage />} />
          <Route path="boards" element={<Outlet />}>
            <Route index element={<BoardsPage />} />
            <Route path=":boardId" element={<BoardPage />} />
          </Route>
        </Route>
        <Route path="settings" element={<SettingsLayout />}>
          <Route index element={<AccountPage />} />
          <Route path="account" element={<AccountPage />} />
          <Route path="subscription" element={<SubscriptionPage />} />
          <Route
            path="subscriber-agreement"
            element={<SubscriberAgreementPage />}
          />
          <Route path="credits" element={<CreditsPage />} />
          <Route path="payment-methods" element={<PaymentMethodsPage />} />
          <Route path="users" element={<UsersPage />} />
          <Route path="integrations" element={<IntegrationsPage />} />
        </Route>
        <Route path="compounding" element={<CompoundingPage />} />
      </Route>
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
};

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {},
  },
});

const ErrorFallback = ({ error }) => {
  return (
    <div className="flex min-h-screen w-screen flex-col items-center justify-center p-4">
      <div className="w-full max-w-3xl">
        <h2 className="mb-4 text-2xl text-red-600">Oops! Something Broke...</h2>
        <p className="mb-2">Send this to the devs so they can fix it!</p>
        <p className="text-sm text-gray-500">Error Message:</p>
        <pre className="mb-2 whitespace-pre-wrap border p-4 text-xs">
          {error.message}
        </pre>
        <p className="text-sm text-gray-500">Error Stack:</p>
        <pre className="mb-4 whitespace-pre-wrap border p-4 text-xs">
          {error.stack}
        </pre>
        <button
          className="btn-primary-outline"
          onClick={() => {
            window.location.reload();
          }}
        >
          Reload
        </button>
      </div>
    </div>
  );
};

const App = () => {
  return (
    <>
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <Router>
          <QueryClientProvider client={queryClient}>
            <AuthProvider>
              <ThemeProvider>
                <AppRoutes />
              </ThemeProvider>
            </AuthProvider>
            <ReactQueryDevtools />
          </QueryClientProvider>
        </Router>
      </ErrorBoundary>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable={false}
        pauseOnHover={false}
        closeButton={false}
      />
    </>
  );
};

export default App;
