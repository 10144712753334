import { DotsVerticalIcon, DuplicateIcon } from "@heroicons/react/outline";
import * as React from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import ArchiveButton from "../components/ArchiveButton";
import ConfirmDialog from "../components/dialogs/ConfirmDialog";
import FormDialog from "../components/dialogs/FormDialog";
import ColorSelector from "../components/forms/ColorSelector";
import Kanban from "../components/Kanban";
import PinBoardButton from "../components/PinBoardButton";
import Spinner from "../components/Spinner";
import useBoolean from "../hooks/useBoolean";
import { BOARD_COLORS } from "../utils/constants";
import { classNames } from "../utils/helpers";
import { duplicateBoard, updateBoard } from "../utils/mutations";
import { findBoardById } from "../utils/queries";
import { useTheme } from "../utils/theme";

const DuplicateBoardButton = ({ board }) => {
  const navigate = useNavigate();
  const [dialogOpen, setDialogOpen] = useBoolean(false);

  const { mutate, isLoading } = useMutation(duplicateBoard, {
    onSuccess: data => {
      setDialogOpen.off();
      navigate(`/boards/${data.data.id}`);
      toast.success(`Duplicated ${board.attributes.title}`);
    },
    onError: () => {
      toast.error(`Error duplicating ${board.attributes.title}`);
    },
  });

  const handleCancel = () => {
    setDialogOpen.off();
  };

  const handleConfirm = () => {
    mutate({
      data: {
        boardId: board.id,
      },
    });
  };

  return (
    <>
      <button
        type="button"
        onClick={setDialogOpen.on}
        className="flex h-7 w-7 min-w-[1.75rem] items-center justify-center rounded bg-black/5 p-1 transition-colors hover:bg-black/10"
      >
        <DuplicateIcon className="h-4 w-4" />
      </button>
      <ConfirmDialog
        open={dialogOpen}
        onCancel={handleCancel}
        onConfirm={handleConfirm}
        icon={
          <span className="flex h-28 w-28 items-center justify-center rounded-full bg-blue-100">
            <DuplicateIcon className="h-16 w-16 text-blue-500" />
          </span>
        }
        title={`Create a copy of ${board.attributes.title}?`}
        subtitle={`This action will clone ${board.attributes.title} and all of its columns.`}
        disabled={isLoading}
        extraConfirmation={false}
      />
    </>
  );
};

const EditBoardButton = ({ board }) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const [dialogOpen, setDialogOpen] = useBoolean(false);

  const {
    register,
    unregister,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    setValue,
  } = useForm({
    defaultValues: {
      title: board.attributes.title,
      description: board.attributes.description,
      color: board.attributes.color,
    },
    shouldUnregister: true,
  });

  React.useEffect(() => {
    if (dialogOpen) {
      register("color", {});
    } else {
      unregister("color");
    }

    return () => {
      unregister("color");
    };
  }, [register, unregister, dialogOpen]);

  const selectedColor = watch("color");
  const setColor = color => {
    setValue("color", color);
  };

  const { mutate, isLoading } = useMutation(updateBoard, {
    onSuccess: (data, variables) => {
      queryClient.setQueryData(
        ["/boards", { boardId: `${board.id}` }],
        oldData => {
          if (!oldData) {
            return oldData;
          }

          return {
            ...oldData,
            data: {
              ...oldData.data,
              attributes: {
                ...oldData.data.attributes,
                ...variables.formData,
              },
            },
          };
        }
      );
      queryClient.refetchQueries(["/boards", { boardId: `${board.id}` }]);
      queryClient.refetchQueries(["/boards"], { exact: true });

      reset({
        ...variables.formData,
      });
      setDialogOpen.off();
      toast.success("Edited conductor");
    },
    onError: () => {
      toast.error("Error editing conductor");
    },
  });

  const onSubmit = data => {
    mutate({
      data: {
        title: data.title,
        description: data.description,
        color: data.color,
      },
      boardId: board.id,
      formData: data,
    });
  };

  const onCancel = () => {
    reset();
    setDialogOpen.off();
  };

  const onArchive = () => {
    onCancel();
    navigate(`/boards`);
    queryClient.setQueryData(["/boards", { boardId: `${board.id}` }], null);
    queryClient.setQueryData(["/boards"], oldData => {
      if (!oldData) {
        return oldData;
      }

      return {
        ...oldData,
        data: oldData.data.filter(b => b.id !== board.id),
      };
    });
  };

  return (
    <>
      <button
        className="flex h-7 w-7 min-w-[1.75rem] items-center justify-center rounded bg-black/5 p-1 transition-colors hover:bg-black/10"
        onClick={setDialogOpen.on}
      >
        <DotsVerticalIcon className="h-4 w-4" />
      </button>
      <FormDialog
        open={dialogOpen}
        onClose={onCancel}
        title="Edit Conductor"
        disableClose={isLoading}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="form-group">
            <label htmlFor="title">Title</label>
            <input
              type="text"
              id="title"
              autoFocus
              className={`${classNames(
                dialogOpen && errors.title && "form-error"
              )}`}
              placeholder="Enter conductor title..."
              disabled={isLoading}
              {...register("title", {
                required: {
                  value: true,
                  message: "Title is required",
                },
                maxLength: {
                  value: 100,
                  message: "Title must be less than 100 characters",
                },
              })}
            />
            {dialogOpen && errors.title && (
              <small className="form-error-text">{errors.title.message}</small>
            )}
          </div>

          <div className="form-group">
            <label htmlFor="description">Description</label>
            <textarea
              id="description"
              placeholder="Enter conductor description..."
              disabled={isLoading}
              rows={2}
              {...register("description", {})}
            />
          </div>

          <div className="form-group">
            <label htmlFor="color">Color</label>
            <ColorSelector
              colors={BOARD_COLORS}
              value={selectedColor}
              setValue={setColor}
              disabled={isLoading}
            />
          </div>

          <div className="grid grid-cols-3 gap-4">
            <button
              type="button"
              className="btn-danger-outline"
              disabled={isLoading}
              onClick={onCancel}
            >
              Cancel
            </button>
            <ArchiveButton
              disabled={isLoading}
              contentType="board"
              contentId={board.id}
              onSuccess={onArchive}
            />
            <button type="submit" className="btn-primary" disabled={isLoading}>
              Save
            </button>
          </div>
        </form>
      </FormDialog>
    </>
  );
};

const BoardPage = () => {
  let { boardId } = useParams();
  const { data, error } = useQuery(
    ["/boards", { boardId: `${boardId}` }],
    findBoardById
  );
  const { setColor } = useTheme();

  React.useEffect(() => {
    if (data) {
      setColor(data.data.attributes.color || null);
    }
  }, [data, setColor]);

  if (data) {
    return (
      <>
        <div className="flex h-12 w-full items-center justify-between px-2">
          <div className="flex items-baseline overflow-clip">
            <PinBoardButton board={data.data} />
            <h1 className="ml-3 whitespace-nowrap text-2xl">
              {data.data.attributes.title}
            </h1>
            <p className="ml-3 mr-3 w-full overflow-x-hidden text-ellipsis whitespace-nowrap text-sm opacity-80">
              {data.data.attributes.description}
            </p>
          </div>
          <div className="flex space-x-2">
            <DuplicateBoardButton board={data.data} />
            <EditBoardButton board={data.data} />
          </div>
        </div>
        <div className="w-full">
          <div className="h-board flex flex-1 flex-col">
            <Kanban boardId={boardId} allowCardAdd allowColumnEdit />
          </div>
        </div>
      </>
    );
  }

  if (error) {
    return (
      <>
        <div className="p-4">Error</div>
      </>
    );
  }

  return (
    <>
      <div className="p-4">
        <Spinner />
      </div>
    </>
  );
};

export default BoardPage;
