import { PlusIcon } from "@heroicons/react/outline";
import dayjs from "dayjs";
import * as React from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import FormDialog from "../components/dialogs/FormDialog";
import ColorSelector from "../components/forms/ColorSelector";
import PinBoardButton from "../components/PinBoardButton";
import Spinner from "../components/Spinner";
import useBoolean from "../hooks/useBoolean";
import { BOARD_COLORS } from "../utils/constants";
import { classNames, textColor } from "../utils/helpers";
import { createBoard } from "../utils/mutations";
import { findAllBoards } from "../utils/queries";
import { useTheme } from "../utils/theme";

const CreateBoardCard = () => {
  const navigate = useNavigate();

  const [dialogOpen, setDialogOpen] = useBoolean(false);

  const {
    register,
    unregister,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    setValue,
  } = useForm({
    defaultValues: { title: "", description: "", color: BOARD_COLORS[0] },
    shouldUnregister: true,
  });

  React.useEffect(() => {
    if (dialogOpen) {
      register("color", {});
    } else {
      unregister("color");
    }

    return () => {
      unregister("color");
    };
  }, [register, unregister, dialogOpen]);

  const selectedColor = watch("color");
  const setColor = color => {
    setValue("color", color);
  };

  const { mutate, isLoading } = useMutation(createBoard, {
    onSuccess: data => {
      setDialogOpen.off();
      navigate(`/boards/${data.data.id}`);
      toast.success("Created new conductor");
    },
    onError: () => {
      toast.error("Error creating conductor");
    },
  });

  const onSubmit = data => {
    mutate({
      data: {
        title: data.title,
        description: data.description,
        color: data.color,
      },
    });
  };

  const onCancel = () => {
    reset();
    setDialogOpen.off();
  };

  return (
    <>
      <button
        className="flex min-h-[120px] flex-col items-center justify-center rounded border p-4 hover:bg-gray-50"
        onClick={setDialogOpen.on}
      >
        <PlusIcon className="h-6 w-6" />
        <span className="mt-1 font-bold">Add New Conductor</span>
      </button>
      <FormDialog
        open={dialogOpen}
        onClose={onCancel}
        title="Add New Conductor"
        disableClose={isLoading}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="form-group">
            <label htmlFor="title">Title</label>
            <input
              type="text"
              id="title"
              autoFocus
              className={`${classNames(
                dialogOpen && errors.title && "form-error"
              )}`}
              placeholder="Enter conductor title..."
              disabled={isLoading}
              {...register("title", {
                required: {
                  value: true,
                  message: "Title is required",
                },
                maxLength: {
                  value: 100,
                  message: "Title must be less than 100 characters",
                },
              })}
            />
            {dialogOpen && errors.title && (
              <small className="form-error-text">{errors.title.message}</small>
            )}
          </div>

          <div className="form-group">
            <label htmlFor="description">Description</label>
            <textarea
              id="description"
              placeholder="Enter conductor description..."
              disabled={isLoading}
              rows={2}
              {...register("description", {})}
            />
          </div>

          <div className="form-group">
            <label htmlFor="color">Color</label>
            <ColorSelector
              colors={BOARD_COLORS}
              value={selectedColor}
              setValue={setColor}
              disabled={isLoading}
            />
          </div>

          <div className="grid grid-cols-2 gap-4">
            <button
              type="button"
              className="btn-danger-outline"
              disabled={isLoading}
              onClick={onCancel}
            >
              Cancel
            </button>
            <button type="submit" className="btn-primary" disabled={isLoading}>
              Create
            </button>
          </div>
        </form>
      </FormDialog>
    </>
  );
};

const BoardCard = ({ board }) => {
  const { setColor } = useTheme();

  const color = board.attributes.color;

  return (
    <Link
      to={`/boards/${board.id}`}
      style={{
        backgroundColor: color,
        color: textColor(color),
      }}
      className={classNames(
        "relative min-h-[120px] rounded border px-4 py-2",
        color ? "hover:opacity-95" : "hover:bg-gray-50"
      )}
      onClick={e => {
        setColor(color || null);
      }}
    >
      <div className="-mb-1 flex items-center justify-between">
        <h6 className="mr-2 w-full overflow-x-hidden overflow-ellipsis whitespace-nowrap text-lg font-bold">
          {board.attributes.title}
        </h6>
        <PinBoardButton board={board} />
      </div>
      <small className="text-xs opacity-75">
        Last Updated: {dayjs(board.attributes.updatedAt).fromNow()}
      </small>
      <p className="mt-2 text-sm opacity-80">
        {board.attributes.description?.length > 100
          ? `${board.attributes.description.slice(0, 50)}...`
          : board.attributes.description}
      </p>
    </Link>
  );
};

const BoardsPage = () => {
  const { data, error } = useQuery(["/boards"], findAllBoards);

  const pinnedBoards = React.useMemo(
    () =>
      data?.data
        ?.filter(board => board.attributes.pinned)
        ?.sort((a, b) => {
          return dayjs(a.attributes.updatedAt).isBefore(
            dayjs(b.attributes.updatedAt)
          )
            ? 1
            : -1;
        }) || [],
    [data]
  );

  const otherBoards = React.useMemo(
    () =>
      data?.data
        ?.filter(board => !board.attributes.pinned)
        ?.sort((a, b) => {
          return dayjs(a.attributes.updatedAt).isBefore(
            dayjs(b.attributes.updatedAt)
          )
            ? 1
            : -1;
        }) || [],
    [data]
  );

  if (data) {
    return (
      <>
        <div className="mx-auto grid w-full max-w-6xl grid-cols-1 gap-6 p-4 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
          <CreateBoardCard />
        </div>
        {pinnedBoards.length > 0 && (
          <>
            <h2 className="mx-auto mt-4 w-full max-w-6xl px-4 text-xl font-bold">
              Pinned Boards
            </h2>
            <div className="mx-auto grid w-full max-w-6xl grid-cols-1 gap-6 p-4 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
              {pinnedBoards.map(board => (
                <BoardCard key={board.id} board={board} />
              ))}
            </div>
          </>
        )}
        <h2 className="mx-auto mt-4 w-full max-w-6xl px-4 text-xl font-bold">
          Boards
        </h2>
        <div className="mx-auto mb-10 grid w-full max-w-6xl grid-cols-1 gap-6 p-4 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
          {otherBoards.map(board => (
            <BoardCard key={board.id} board={board} />
          ))}
        </div>
      </>
    );
  }

  if (error) {
    return <div>Error</div>;
  }

  return (
    <div className="p-4">
      <Spinner />
    </div>
  );
};

export default BoardsPage;
