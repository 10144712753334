import Board from "@asseinfo/react-kanban";
import "@asseinfo/react-kanban/dist/styles.css";
import { Disclosure } from "@headlessui/react";
import {
  BanIcon,
  ChatAltIcon,
  CheckIcon,
  ClockIcon,
  CubeIcon,
  FastForwardIcon,
  PrinterIcon,
  SwitchHorizontalIcon,
  UploadIcon,
  XIcon,
  SearchIcon,
  SwitchVerticalIcon,
  ArchiveIcon,
  CashIcon,
} from "@heroicons/react/outline";
import {
  ChevronDownIcon,
  DotsHorizontalIcon,
  PencilAltIcon,
  PlusIcon,
} from "@heroicons/react/solid";
import { Document, Page, pdf, Text, View } from "@react-pdf/renderer";
import * as csv from "csvtojson";
import dayjs from "dayjs";
import parsePhoneNumber, { isValidPhoneNumber } from "libphonenumber-js/mobile";
import * as React from "react";
import { useDropzone } from "react-dropzone";
import ReactGoogleAutocomplete from "react-google-autocomplete";
import { useForm } from "react-hook-form";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import useBoolean from "../hooks/useBoolean";
import useInterval from "../hooks/useInterval";
import { useAuth } from "../utils/auth";
import { CARD_COLORS, GOOGLE_MAPS_API_KEY } from "../utils/constants";
import {
  blobToBase64,
  classNames,
  getTimerInMinutes,
  getTimerInUnit,
  getUnitFromTimer,
} from "../utils/helpers";
import {
  bulkCreateCards,
  createCard,
  createColumn,
  createPrintJob,
  lodgeParcel,
  moveCardv2,
  updateCard,
  updateColumn,
  validateAddress,
} from "../utils/mutations";
import {
  findAllBoards,
  findBoardById,
  getOrCreateStoreCredit,
} from "../utils/queries";
import ArchiveButton from "./ArchiveButton";
import FormDialog from "./dialogs/FormDialog";
import ColorSelector from "./forms/ColorSelector";
import TimerUnitSelector from "./forms/TimerUnitSelector";
import "./kanban.css";
import Spinner from "./Spinner";
import Fuse from "fuse.js";

const AddColumnButton = ({ boardId, columns }) => {
  const { store } = useAuth();
  const queryClient = useQueryClient();

  const [dialogOpen, setDialogOpen] = useBoolean(false);

  const { data: boardsData } = useQuery(["/boards"], findAllBoards, {
    enabled: dialogOpen,
  });

  const boards = boardsData?.data;

  const {
    register,
    unregister,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    setValue,
  } = useForm({
    defaultValues: {
      title: "",
      message: "",
      timer: 0,
      timerUnit: "mins",
      timerBoardId: null,
      timerColumnId: null,
      type: null,
      printerId: null,
    },
    shouldUnregister: true,
  });

  React.useEffect(() => {
    if (dialogOpen) {
      register("timerUnit", {});
    } else {
      unregister("timerUnit");
    }

    return () => {
      unregister("timerUnit");
    };
  }, [register, unregister, dialogOpen]);

  const selectedTimerUnit = watch("timerUnit");
  const setTimerUnit = timerUnit => {
    setValue("timerUnit", timerUnit);
  };

  const selectedType = watch("type");
  const selectedBoard = watch("timerBoardId");
  const messageCharCount = watch("message")?.length || 0;
  const textMessageCount = Math.ceil(messageCharCount / 160);

  const { mutate, isLoading } = useMutation(createColumn, {
    onSuccess: data => {
      queryClient.setQueryData(
        ["/boards", { boardId: `${boardId}` }],
        oldData => {
          return {
            ...oldData,
            data: {
              ...oldData.data,
              attributes: {
                ...oldData.data.attributes,
                columns: {
                  data: [...oldData.data.attributes.columns.data, data.data],
                },
              },
            },
          };
        }
      );
      queryClient.refetchQueries(["/boards", { boardId: `${boardId}` }]);
      reset();
      setDialogOpen.off();
      toast.success("Column added");
    },
    onError: () => {
      toast.error("Error adding column");
    },
  });

  const onSubmit = data => {
    mutate({
      data: {
        board: {
          id: boardId,
        },
        position: columns.length,
        title: data.title,
        message: data.message,
        type: data.type || "default",
        timer: data.timer
          ? getTimerInMinutes(parseInt(data.timer), data.timerUnit)
          : 0,

        timerColumn: data.timerColumnId
          ? {
              id: parseInt(data.timerColumnId),
            }
          : null,
        printer: data.printerId
          ? {
              id: parseInt(data.printerId),
            }
          : null,
      },
    });
  };

  const onCancel = () => {
    reset();
    setDialogOpen.off();
  };

  return (
    <>
      <button
        className="mr-2 -ml-2 flex h-16 min-w-[284px] max-w-[284px] flex-col items-center justify-center rounded bg-slate-50 p-1 text-gray-600 transition-colors hover:bg-slate-200"
        onClick={setDialogOpen.on}
      >
        <PlusIcon className="h-6 w-6" />
      </button>
      <FormDialog
        open={dialogOpen}
        onClose={onCancel}
        title="Add New Column"
        disableClose={isLoading}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="form-group">
            <label htmlFor="title">Title</label>
            <input
              type="text"
              id="title"
              autoFocus
              className={`${classNames(
                dialogOpen && errors.title && "form-error"
              )}`}
              placeholder="Enter Title..."
              disabled={isLoading}
              {...register("title", {
                required: {
                  value: true,
                  message: "Title is required",
                },
                maxLength: {
                  value: 100,
                  message: "Title must be less than 100 characters",
                },
              })}
            />
            {dialogOpen && errors.title && (
              <small className="form-error-text">{errors.title.message}</small>
            )}
          </div>

          <div className="form-group">
            <label htmlFor="message">SMS Message</label>
            <textarea
              id="message"
              placeholder="Enter Message..."
              disabled={isLoading}
              rows={2}
              {...register("message", {})}
            />
            <small className="form-info-text">
              {`${messageCharCount} ${
                messageCharCount === 1 ? "Character" : "Charaters"
              }`}{" "}
              ({`${textMessageCount} SMS`})
            </small>
          </div>

          <Disclosure>
            {({ open }) => (
              <>
                <Disclosure.Button className="flex w-full items-center justify-between rounded bg-gray-100 p-3  hover:bg-gray-200">
                  <p className="mr-2 overflow-x-hidden text-ellipsis whitespace-nowrap text-sm">
                    Additional Options
                  </p>
                  <span className="flex min-w-fit items-center">
                    <ChevronDownIcon
                      className={`h-5 w-5 min-w-[1.25rem] ${
                        open ? "rotate-180 transform" : ""
                      }`}
                    />
                  </span>
                </Disclosure.Button>
                <Disclosure.Panel className="relative w-full pt-3 text-sm">
                  <div className="form-group">
                    <label htmlFor="type">Type</label>
                    <select
                      name="type"
                      placeholder="Select Type"
                      disabled={isLoading}
                      {...register("type", {})}
                    >
                      <option value={""}>Select Type...</option>
                      <option value={"default"}>Default</option>
                      <option value={"archive"}>Archive</option>
                      <option value={"print_label"}>Print Label</option>
                      <option value={"lodge_parcel"}>Lodge Parcel</option>
                      {store.phones.length > 0 && (
                        <option value={"two_way"}>Two Way</option>
                      )}
                    </select>
                  </div>

                  {store.printers.length > 0 && selectedType === "print_label" && (
                    <div className="form-group">
                      <label htmlFor="printerId">Printer</label>
                      <select
                        name="printer"
                        placeholder="Select Printer"
                        disabled={isLoading}
                        {...register("printerId", {})}
                      >
                        <option value={""}>Select Printer...</option>
                        {store.printers.map(printer => (
                          <option key={printer.id} value={printer.id}>
                            {printer.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  )}

                  <div className="form-group">
                    <label htmlFor="timer">Timer</label>
                    <div className="grid grid-cols-2 gap-4">
                      <input
                        type="number"
                        min={0}
                        id="timer"
                        autoFocus
                        placeholder="Enter Timer..."
                        disabled={isLoading}
                        {...register("timer", {})}
                      />
                      <TimerUnitSelector
                        value={selectedTimerUnit}
                        setValue={setTimerUnit}
                        disabled={isLoading}
                      />
                    </div>
                  </div>

                  {selectedType !== "archive" && boards && (
                    <>
                      <label className="font-bold">Move to:</label>

                      <div className="grid grid-cols-2 gap-4">
                        <div className="form-group">
                          <label htmlFor="timerBoardId">Conductor</label>
                          <select
                            name="board"
                            placeholder="Select Board"
                            disabled={isLoading}
                            {...register("timerBoardId", {})}
                          >
                            <option value={""}>Select Board...</option>
                            {boards.map(board => (
                              <option key={board.id} value={board.id}>
                                {board.attributes.title}
                              </option>
                            ))}
                          </select>
                        </div>

                        {selectedBoard && (
                          <div className="form-group">
                            <label htmlFor="timerColumnId">Column</label>
                            <select
                              name="board"
                              placeholder="Select Column"
                              disabled={isLoading}
                              {...register("timerColumnId", {})}
                            >
                              <option value={""}>Select Column...</option>
                              {boards
                                .find(b => b.id === parseInt(selectedBoard))
                                .sort(
                                  (a, b) =>
                                    a.attributes.position -
                                    b.attributes.position
                                )
                                .map(column => (
                                  <option key={column.id} value={column.id}>
                                    {column.attributes.title}
                                  </option>
                                ))}
                            </select>
                          </div>
                        )}
                      </div>
                    </>
                  )}
                  <hr />
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>

          <div className="mt-4 grid grid-cols-2 gap-4">
            <button
              type="button"
              className="btn-danger-outline"
              disabled={isLoading}
              onClick={onCancel}
            >
              Cancel
            </button>
            <button type="submit" className="btn-primary" disabled={isLoading}>
              Create
            </button>
          </div>
        </form>
      </FormDialog>
    </>
  );
};

const EditColumnButton = ({ boardId, column }) => {
  const { store } = useAuth();
  const queryClient = useQueryClient();

  const [dialogOpen, setDialogOpen] = useBoolean(false);

  const { data: boardsData } = useQuery(["/boards"], findAllBoards, {
    enabled: dialogOpen,
  });

  const boards = boardsData?.data;

  const {
    register,
    unregister,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    setValue,
  } = useForm({
    defaultValues: {
      title: column.title,
      message: column.message,
      type: column.type,
      timer: getTimerInUnit(column.timer),
      timerUnit: getUnitFromTimer(column.timer),
      timerBoardId:
        column?.timerColumn?.data?.attributes?.board?.data?.id || null,
      timerColumnId: column?.timerColumn?.data?.id,
      printerId: column?.printer?.data?.id,
    },
    shouldUnregister: true,
  });

  React.useEffect(() => {
    if (dialogOpen) {
      register("timerUnit", {});
    } else {
      unregister("timerUnit");
    }

    return () => {
      unregister("timerUnit");
    };
  }, [register, unregister, dialogOpen]);

  const selectedTimerUnit = watch("timerUnit");
  const setTimerUnit = timerUnit => {
    setValue("timerUnit", timerUnit);
  };

  const selectedType = watch("type");
  const selectedBoard = watch("timerBoardId");
  const messageCharCount = watch("message")?.length || 0;
  const textMessageCount = Math.ceil(messageCharCount / 160);

  const { mutate, isLoading } = useMutation(updateColumn, {
    onSuccess: (data, variables) => {
      queryClient.setQueryData(
        ["/boards", { boardId: `${boardId}` }],
        oldData => {
          return {
            ...oldData,
            data: {
              ...oldData.data,
              attributes: {
                ...oldData.data.attributes,
                columns: {
                  data: oldData.data.attributes.columns.data.map(col => {
                    if (col.id === column.id) {
                      return {
                        ...col,
                        attributes: {
                          ...col.attributes,
                          ...data.data.attributes,
                        },
                      };
                    }
                    return col;
                  }),
                },
              },
            },
          };
        }
      );
      queryClient.refetchQueries(["/boards", { boardId: `${boardId}` }]);
      reset({
        ...variables.formData,
      });
      setDialogOpen.off();
      toast.success("Column edited");
    },
    onError: () => {
      toast.error("Error editing column");
    },
  });

  const onSubmit = data => {
    mutate({
      data: {
        title: data.title,
        message: data.message,
        timer: data.timer
          ? getTimerInMinutes(parseInt(data.timer), data.timerUnit)
          : 0,
        timerColumn: data.timerColumnId
          ? {
              id: parseInt(data.timerColumnId),
            }
          : null,
        printer: data.printerId
          ? {
              id: parseInt(data.printerId),
            }
          : null,
        type: data.type || "default",
      },
      columnId: column.id,
      formData: data,
    });
  };

  const onCancel = () => {
    reset();
    setDialogOpen.off();
  };

  const onArchive = () => {
    onCancel();

    queryClient.setQueryData(
      ["/boards", { boardId: `${boardId}` }],
      oldData => {
        return {
          ...oldData,
          data: {
            ...oldData.data,
            attributes: {
              ...oldData.data.attributes,
              columns: {
                data: oldData.data.attributes.columns.data.filter(
                  col => col.id !== column.id
                ),
              },
            },
          },
        };
      }
    );
  };

  return (
    <>
      <button
        className="mr-2 flex h-7 w-7 min-w-[1.75rem] items-center justify-center rounded bg-gray-200 p-1 text-gray-600 transition-colors hover:bg-blue-100 hover:text-blue-600"
        onClick={setDialogOpen.on}
      >
        <PencilAltIcon className="h-4 w-4" />
      </button>
      <FormDialog
        open={dialogOpen}
        onClose={onCancel}
        title="Edit Column"
        disableClose={isLoading}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="form-group">
            <label htmlFor="title">Title</label>
            <input
              type="text"
              id="title"
              autoFocus
              className={`${classNames(
                dialogOpen && errors.title && "form-error"
              )}`}
              placeholder="Enter Title..."
              disabled={isLoading}
              {...register("title", {
                required: {
                  value: true,
                  message: "Title is required",
                },
                maxLength: {
                  value: 100,
                  message: "Title must be less than 100 characters",
                },
              })}
            />
            {dialogOpen && errors.title && (
              <small className="form-error-text">{errors.title.message}</small>
            )}
          </div>

          <div className="form-group">
            <label htmlFor="message">SMS Message</label>
            <textarea
              id="message"
              placeholder="Enter Message..."
              disabled={isLoading}
              rows={2}
              {...register("message", {})}
            />
            <small className="form-info-text">
              {`${messageCharCount} ${
                messageCharCount === 1 ? "Character" : "Charaters"
              }`}{" "}
              ({`${textMessageCount} SMS`})
            </small>
          </div>

          <Disclosure>
            {({ open }) => (
              <>
                <Disclosure.Button className="flex w-full items-center justify-between rounded bg-gray-100 p-3  hover:bg-gray-200">
                  <p className="mr-2 overflow-x-hidden text-ellipsis whitespace-nowrap text-sm">
                    Additional Options
                  </p>
                  <span className="flex min-w-fit items-center">
                    <ChevronDownIcon
                      className={`h-5 w-5 min-w-[1.25rem] ${
                        open ? "rotate-180 transform" : ""
                      }`}
                    />
                  </span>
                </Disclosure.Button>
                <Disclosure.Panel className="relative w-full pt-3 text-sm">
                  <div className="form-group">
                    <label htmlFor="type">Type</label>
                    <select
                      name="type"
                      placeholder="Select Type"
                      disabled={isLoading}
                      {...register("type", {})}
                    >
                      <option value={""}>Select Type...</option>
                      <option value={"default"}>Default</option>
                      <option value={"archive"}>Archive</option>
                      <option value={"print_label"}>Print Label</option>
                      <option value={"lodge_parcel"}>Lodge Parcel</option>
                      {store.phones.length > 0 && (
                        <option value={"two_way"}>Two Way</option>
                      )}
                    </select>
                  </div>

                  {store.printers.length > 0 && selectedType === "print_label" && (
                    <div className="form-group">
                      <label htmlFor="printerId">Printer</label>
                      <select
                        name="printer"
                        placeholder="Select Printer"
                        disabled={isLoading}
                        {...register("printerId", {})}
                      >
                        <option value={""}>Select Printer...</option>
                        {store.printers.map(printer => (
                          <option key={printer.id} value={printer.id}>
                            {printer.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  )}

                  <div className="form-group">
                    <label htmlFor="timer">Timer</label>
                    <div className="grid grid-cols-2 gap-4">
                      <input
                        type="number"
                        min={0}
                        id="timer"
                        autoFocus
                        placeholder="Enter Timer..."
                        disabled={isLoading}
                        {...register("timer", {})}
                      />
                      <TimerUnitSelector
                        value={selectedTimerUnit}
                        setValue={setTimerUnit}
                        disabled={isLoading}
                      />
                    </div>
                  </div>

                  {selectedType !== "archive" && boards && (
                    <>
                      <label className="font-bold">Move to:</label>

                      <div className="grid grid-cols-2 gap-4">
                        <div className="form-group">
                          <label htmlFor="timerBoardId">Conductor</label>
                          <select
                            placeholder="Select Board"
                            disabled={isLoading}
                            {...register("timerBoardId", {})}
                          >
                            <option value={""}>Select Board...</option>
                            {boards.map(board => (
                              <option key={board.id} value={board.id}>
                                {board.attributes.title}
                              </option>
                            ))}
                          </select>
                        </div>

                        {selectedBoard && (
                          <div className="form-group">
                            <label htmlFor="timerColumnId">Column</label>
                            <select
                              placeholder="Select Column"
                              disabled={isLoading}
                              {...register("timerColumnId", {})}
                            >
                              <option value={""}>Select Column...</option>
                              {boards
                                .find(b => b.id === parseInt(selectedBoard))
                                .attributes.columns.data.filter(
                                  col => col.id !== column.id
                                )
                                .sort(
                                  (a, b) =>
                                    a.attributes.position -
                                    b.attributes.position
                                )
                                .map(column => (
                                  <option key={column.id} value={column.id}>
                                    {column.attributes.title}
                                  </option>
                                ))}
                            </select>
                          </div>
                        )}
                      </div>
                    </>
                  )}

                  <div className="my-2 flex w-full items-center justify-between rounded bg-red-50 p-3">
                    <p>Archive this column?</p>

                    <ArchiveButton
                      disabled={isLoading}
                      contentType="column"
                      contentId={column.id}
                      onSuccess={onArchive}
                    />
                  </div>
                  <hr />
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>

          <div className="mt-4 grid grid-cols-2 gap-4">
            <button
              type="button"
              className="btn-danger-outline"
              disabled={isLoading}
              onClick={onCancel}
            >
              Cancel
            </button>
            <button type="submit" className="btn-primary" disabled={isLoading}>
              Save
            </button>
          </div>
        </form>
      </FormDialog>
    </>
  );
};

const AddCardButton = ({ boardId, column }) => {
  const queryClient = useQueryClient();
  const { store } = useAuth();

  const [dialogOpen, setDialogOpen] = useBoolean(false);

  const {
    register,
    unregister,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    setValue,
  } = useForm({
    defaultValues: {
      phoneNumber: "",
      firstName: "",
      lastName: "",
      address: "",
      description: "",
      color: "",
    },
    shouldUnregister: true,
  });

  React.useEffect(() => {
    if (dialogOpen) {
      register("color", {});
      register("address", {});
    } else {
      unregister("color");
      unregister("address");
    }

    return () => {
      unregister("color");
      unregister("address");
    };
  }, [register, unregister, dialogOpen]);

  const selectedAddress = watch("address");
  const setAddress = address => {
    setValue("address", address);
  };
  const selectedColor = watch("color");
  const setColor = color => {
    setValue("color", color);
  };

  const { mutate, isLoading } = useMutation(createCard, {
    onSuccess: data => {
      queryClient.setQueryData(
        ["/boards", { boardId: `${boardId}` }],
        oldData => {
          return {
            ...oldData,
            data: {
              ...oldData.data,
              attributes: {
                ...oldData.data.attributes,
                columns: {
                  data: oldData.data.attributes.columns.data.map(col => {
                    if (col.id === column.id) {
                      return {
                        ...col,
                        attributes: {
                          ...col.attributes,
                          cards: {
                            data: [...col.attributes.cards.data, data.data],
                          },
                        },
                      };
                    }
                    return col;
                  }),
                },
              },
            },
          };
        }
      );
      setDialogOpen.off();
      toast.success("Created new card");
    },
    onError: () => {
      toast.error("Error creating card");
    },
  });

  const onSubmit = data => {
    const phoneNumber = isValidPhoneNumber(data.phoneNumber, "AU")
      ? parsePhoneNumber(data.phoneNumber, "AU").number
      : parsePhoneNumber(data.phoneNumber, "PK").number;

    mutate({
      data: {
        phoneNumber: phoneNumber,
        firstName: data.firstName,
        lastName: data.lastName,
        address: data.address,
        description: data.description,
        color: data.color,
        position: column.cards.length,
        column: {
          id: column.id,
        },
      },
    });
  };

  const onCancel = () => {
    reset();
    setDialogOpen.off();
  };

  return (
    <>
      <button
        className="mr-2 flex h-7 w-full items-center justify-center rounded bg-gray-200 p-1 text-gray-600 transition-colors hover:bg-blue-100 hover:text-blue-600"
        onClick={setDialogOpen.on}
      >
        <PlusIcon className="h-5 w-5" />
      </button>
      <FormDialog
        open={dialogOpen}
        onClose={onCancel}
        title="Add New Card"
        disableClose={isLoading}
      >
        <h4 className="-mt-3 mb-4 text-center text-sm text-gray-500">
          to {column.title}
        </h4>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="form-group">
            <label htmlFor="phoneNumber">Phone Number</label>
            <input
              type="text"
              id="phoneNumber"
              autoFocus
              className={`${classNames(
                dialogOpen && errors.phoneNumber && "form-error"
              )}`}
              placeholder="Enter Phone Number..."
              disabled={isLoading}
              {...register("phoneNumber", {
                required: {
                  value: true,
                  message: "Phone Number is required",
                },
                validate: value => {
                  return (
                    isValidPhoneNumber(value, "AU") ||
                    isValidPhoneNumber(value, "PK") ||
                    "Phone Number must be a valid AU Phone Number"
                  );
                },
              })}
            />
            {dialogOpen && errors.phoneNumber && (
              <small className="form-error-text">
                {errors.phoneNumber.message}
              </small>
            )}
          </div>
          <div className="grid grid-cols-2 gap-4">
            <div className="form-group">
              <label htmlFor="firstName">First Name</label>
              <input
                type="text"
                id="firstName"
                className={`${classNames(
                  dialogOpen && errors.firstName && "form-error"
                )}`}
                placeholder="Enter first name..."
                disabled={isLoading}
                {...register("firstName", {
                  required: {
                    value: true,
                    message: "First Name is required",
                  },
                  maxLength: {
                    value: 50,
                    message: "First Name must be less than 50 characters",
                  },
                })}
              />
              {dialogOpen && errors.firstName && (
                <small className="form-error-text">
                  {errors.firstName.message}
                </small>
              )}
            </div>

            <div className="form-group">
              <label htmlFor="lastName">Last Name</label>
              <input
                type="text"
                id="lastName"
                className={`${classNames(
                  dialogOpen && errors.lastName && "form-error"
                )}`}
                placeholder="Enter last name..."
                disabled={isLoading}
                {...register("lastName", {
                  maxLength: {
                    value: 50,
                    message: "Last Name must be less than 50 characters",
                  },
                })}
              />
              {dialogOpen && errors.lastName && (
                <small className="form-error-text">
                  {errors.lastName.message}
                </small>
              )}
            </div>
          </div>

          <div className="form-group">
            <label htmlFor="description">Description</label>
            <textarea
              id="description"
              placeholder="Enter description..."
              disabled={isLoading}
              rows={2}
              {...register("description", {})}
            />
          </div>

          <div className="form-group">
            <label htmlFor="address">Address</label>

            <ReactGoogleAutocomplete
              className="mt-2"
              type="text"
              id="address"
              placeholder="Enter address..."
              disabled={isLoading}
              apiKey={GOOGLE_MAPS_API_KEY}
              onPlaceSelected={place => {
                setAddress(place.formatted_address);
              }}
              onChange={e => {
                setAddress(e.target.value);
              }}
              language="en-AU"
              options={{
                types: ["address"],
                componentRestrictions: { country: "au" },
              }}
              defaultValue={""}
            />

            {store.auspost &&
              column.type === "lodge_parcel" &&
              selectedAddress && (
                <ValidateAddressButton address={selectedAddress} />
              )}
          </div>

          <div className="form-group">
            <label htmlFor="color">Color</label>
            <ColorSelector
              colors={CARD_COLORS}
              value={selectedColor}
              setValue={setColor}
              disabled={isLoading}
            />
          </div>

          {/* <Disclosure>
            {({ open }) => (
              <>
                <Disclosure.Button className="flex w-full items-center justify-between rounded bg-gray-100 p-3  hover:bg-gray-200">
                  <p className="mr-2 overflow-x-hidden text-ellipsis whitespace-nowrap text-sm">
                    Additional Options
                  </p>
                  <span className="flex min-w-fit items-center">
                    <ChevronDownIcon
                      className={`h-5 w-5 min-w-[1.25rem] ${
                        open ? "rotate-180 transform" : ""
                      }`}
                    />
                  </span>
                </Disclosure.Button>
                <Disclosure.Panel className="relative w-full pt-3 text-sm">

                  <hr />
                </Disclosure.Panel>
              </>
            )}
          </Disclosure> */}

          <div className="mt-4 grid grid-cols-2 gap-4">
            <button
              type="button"
              className="btn-danger-outline"
              disabled={isLoading}
              onClick={onCancel}
            >
              Cancel
            </button>
            <button type="submit" className="btn-primary" disabled={isLoading}>
              Create
            </button>
          </div>
        </form>
      </FormDialog>
    </>
  );
};

const BulkAddCardsButton = ({ boardId, column }) => {
  const queryClient = useQueryClient();

  const [data, setData] = React.useState(null);
  const [dialogOpen, setDialogOpen] = useBoolean(false);

  const { mutate, isLoading, reset } = useMutation(bulkCreateCards, {
    onSuccess: data => {
      queryClient.setQueryData(
        ["/boards", { boardId: `${boardId}` }],
        oldData => {
          return {
            ...oldData,
            data: {
              ...oldData.data,
              attributes: {
                ...oldData.data.attributes,
                columns: {
                  data: oldData.data.attributes.columns.data.map(col => {
                    if (col.id === column.id) {
                      return {
                        ...col,
                        attributes: {
                          ...col.attributes,
                          cards: {
                            data: [...col.attributes.cards.data, ...data.data],
                          },
                        },
                      };
                    }
                    return col;
                  }),
                },
              },
            },
          };
        }
      );
      setDialogOpen.off();
      toast.success("Created new cards");
    },
    onError: () => {
      toast.error("Error uploading cards");
    },
  });

  const onCancel = () => {
    setDialogOpen.off();
  };

  const onDrop = React.useCallback(() => {
    setData(null);
    reset();
  }, [reset]);

  const {
    getRootProps,
    getInputProps,
    open: openFileDialog,
    isDragActive,
    acceptedFiles,
    fileRejections,
  } = useDropzone({
    onDrop,
    noKeyboard: true,
    maxFiles: 1,
    multiple: false,
    accept: [".csv"],
  });

  const parseFile = React.useCallback(async () => {
    if (acceptedFiles[0]) {
      const text = await acceptedFiles[0].text();
      const result = await csv().fromString(text);
      const cards = result.map((row, idx) => {
        const phoneNumber = parsePhoneNumber(row.mobile, "AU").number;

        return {
          phoneNumber: phoneNumber,
          firstName: row.firstName,
          lastName: row.lastName,
          address: `${row.address}\n${row.suburb} ${row.state} ${row.postCode}`,
          description: row.concession,
          position: column.cards.length + idx,
          column: {
            id: column.id,
          },
        };
      });
      setData(cards);
    }
  }, [acceptedFiles, column.cards.length, column.id]);

  React.useEffect(() => {
    parseFile();
  }, [parseFile]);

  const onSubmit = () => {
    mutate({
      data: data,
    });
  };

  const isBtnDisabled =
    isDragActive || isLoading || acceptedFiles.length === 0 || !data;

  return (
    <>
      <button
        className="flex h-6 w-6 min-w-[1.5rem] items-center justify-center rounded bg-gray-200 p-1 text-gray-600 transition-colors hover:bg-blue-100 hover:text-blue-600"
        onClick={setDialogOpen.on}
      >
        <UploadIcon className="h-4 w-4" />
      </button>
      <FormDialog
        open={dialogOpen}
        onClose={onCancel}
        title="Bulk Upload Cards"
        disableClose={isLoading}
      >
        <h4 className="-mt-3 mb-4 text-center text-sm text-gray-500">
          to {column.title}
        </h4>
        <div
          {...getRootProps()}
          className={classNames(
            `mb-4 flex flex-col items-center justify-center rounded-lg p-8`,
            isDragActive
              ? "bg-blue-50 text-gray-400"
              : "bg-blue-100 text-gray-700",
            isLoading && "pointer-events-none cursor-not-allowed opacity-50"
          )}
        >
          <input {...getInputProps()} />
          {isDragActive ? (
            <p
              className={`flex h-40 flex-col items-center justify-center text-gray-700`}
            >
              Drop the file here ...
            </p>
          ) : (
            <div className="flex h-40 flex-col items-center justify-center">
              <UploadIcon className="w-12" />
              <p className={`mt-4 text-lg`}>Drag & Drop to Upload CSV</p>
              <button
                className="p-1 text-xs text-blue-500 hover:underline"
                onClick={openFileDialog}
              >
                or browse
              </button>
            </div>
          )}
        </div>
        {!isDragActive && (
          <div className="mb-4">
            {acceptedFiles.length > 0 && (
              <div className="flex items-center justify-between overflow-hidden whitespace-nowrap">
                <small
                  className="mt-1 mr-3 block w-full overflow-hidden text-ellipsis text-xs text-blue-600"
                  title={acceptedFiles[0].name}
                >
                  File Name: <strong>{acceptedFiles[0].name}</strong>
                </small>
                <small
                  className="mt-1 block text-xs text-green-600"
                  title={`${data?.length || 0} records found`}
                >
                  {data?.length > 0 ? (
                    <>
                      <strong>{data.length}</strong> records found
                    </>
                  ) : (
                    "No records found"
                  )}
                </small>
              </div>
            )}
            {fileRejections.length > 0 && (
              <small className="mt-1 block text-xs text-red-600">
                {fileRejections[0].errors[0].message}
              </small>
            )}
          </div>
        )}
        <button
          className={`btn-primary w-full`}
          onClick={onSubmit}
          disabled={isBtnDisabled}
        >
          Submit
        </button>
      </FormDialog>
    </>
  );
};

const ColumnHeader = ({
  boardId,
  column,
  allowCardAdd,
  allowColumnEdit,
  filter,
  sort,
  sortColumnCards,
  clearColumnSort,
  filterColumnCards,
  clearColumnFilter,
}) => {
  const { store } = useAuth();

  return (
    <div className="relative mb-2 overflow-x-hidden text-ellipsis whitespace-nowrap pr-1">
      <Disclosure>
        {({ open }) => (
          <>
            <Disclosure.Button className="mb-1 flex w-full items-center justify-between px-1">
              <p className="mr-2 overflow-x-hidden text-ellipsis whitespace-nowrap">
                {column.title} {column.id}
              </p>
              <span className="flex min-w-fit items-center">
                {filter && (
                  <SearchIcon className="mr-1 h-5 w-5 text-blue-500" />
                )}
                {sort && (
                  <SwitchVerticalIcon className="mr-1 h-5 w-5 text-blue-500" />
                )}
                {store.printers.length > 0 && column.type === "print_label" && (
                  <PrinterIcon className="mr-1 h-5 w-5 text-green-600" />
                )}
                {store.auspost && column.type === "lodge_parcel" && (
                  <CubeIcon className="mr-1 h-5 w-5 text-red-600" />
                )}
                {store.phones.length > 0 && column.type === "two_way" && (
                  <SwitchHorizontalIcon className="mr-1 h-5 w-5 text-sky-600" />
                )}
                {column.type === "archive" && (
                  <ArchiveIcon className="mr-1 h-5 w-5 text-amber-600" />
                )}
                {column.type === "payment" && (
                  <CashIcon className="mr-1 h-5 w-5 text-emerald-600" />
                )}
                <ChevronDownIcon
                  className={`h-5 w-5 min-w-[1.25rem] ${
                    open ? "rotate-180 transform" : ""
                  }`}
                />
              </span>
            </Disclosure.Button>

            <Disclosure.Panel className="relative w-full px-1 pb-1 text-sm text-gray-500">
              {column.message ? (
                <div className="mb-1 flex">
                  <ChatAltIcon className="mr-2 mt-0.5 h-4 w-4 min-w-[1.25rem] text-blue-600" />
                  <p className="whitespace-pre-wrap">{column.message}</p>
                </div>
              ) : (
                <>
                  <div className="mb-1 flex">
                    <BanIcon className="mr-2 mt-0.5 h-4 w-4 min-w-[1.25rem] text-blue-600" />
                    <p className="whitespace-pre-wrap">Staging Column</p>
                  </div>
                  <div className="absolute right-0.5 top-0">
                    <BulkAddCardsButton column={column} boardId={boardId} />
                  </div>
                </>
              )}
              {column.timer ? (
                <div className="mb-1 flex">
                  <ClockIcon className="mr-2 mt-0.5 h-4 w-4 min-w-[1.25rem] text-blue-600" />
                  <p className="whitespace-pre-wrap">
                    {getTimerInUnit(column.timer)}{" "}
                    {getUnitFromTimer(column.timer)}
                  </p>
                </div>
              ) : null}
              {column?.timerColumn?.data ? (
                <div className="mb-1 flex">
                  <FastForwardIcon className="mr-2 mt-0.5 h-4 w-4 min-w-[1.25rem] text-blue-600" />
                  <p className="whitespace-pre-wrap">
                    Move to{" "}
                    <strong>
                      {
                        column?.timerColumn?.data?.attributes?.board?.data
                          ?.attributes?.title
                      }
                    </strong>{" "}
                    &rarr;{" "}
                    <strong>
                      {column?.timerColumn?.data?.attributes?.title}
                    </strong>
                  </p>
                </div>
              ) : null}
            </Disclosure.Panel>

            <div className="px-1 pt-1">
              <div className="relative mb-2 flex items-center">
                <input
                  className="h-6 w-full rounded px-6 text-xs placeholder:text-inherit"
                  type="text"
                  placeholder="Filter cards (by name or phone)"
                  value={filter}
                  onChange={e => filterColumnCards(e.target.value)}
                  onFocus={e => {
                    e.target.select();
                  }}
                />
                <SearchIcon className="absolute left-2 h-3 w-3 text-slate-600" />
                {filter && (
                  <button
                    className="absolute right-1 flex h-4 w-4 cursor-pointer items-center justify-center rounded-full hover:bg-gray-100"
                    onClick={clearColumnFilter}
                  >
                    <XIcon className="h-3 w-3 text-slate-600" />
                  </button>
                )}
              </div>
              <div className="relative mb-4 flex items-center">
                <select
                  className="h-6 w-full rounded py-0 pl-6 pr-11 text-xs placeholder:text-inherit"
                  type="text"
                  placeholder="Sort cards"
                  value={sort}
                  onChange={e => sortColumnCards(e.target.value)}
                >
                  <option value="">Sort cards</option>
                  <option value="name_ASC">Name (ascending)</option>
                  <option value="name_DESC">Name (descending)</option>
                  <option value="movedAt_ASC">Date moved (newest first)</option>
                  <option value="movedAt_DESC">
                    Date moved (oldest first)
                  </option>
                  <option value="createdAt_ASC">
                    Date created (newest first)
                  </option>
                  <option value="createdAt_DESC">
                    Date created (oldest first)
                  </option>
                </select>
                <SwitchVerticalIcon className="absolute left-2 h-3 w-3 text-slate-600" />
                {sort && (
                  <button
                    className="absolute right-7 flex h-4 w-4 cursor-pointer items-center justify-center rounded-full hover:bg-gray-100"
                    onClick={clearColumnSort}
                  >
                    <XIcon className="h-3 w-3 text-slate-600" />
                  </button>
                )}
              </div>
            </div>
          </>
        )}
      </Disclosure>
      <div className="mt-2 flex items-center justify-between">
        {allowCardAdd && <AddCardButton boardId={boardId} column={column} />}
        {allowColumnEdit && (
          <EditColumnButton boardId={boardId} column={column} />
        )}
        <span className="ml-auto w-5 min-w-fit rounded bg-blue-100 p-0.5 text-center text-xs text-blue-600">
          {column.cards.length}
        </span>
      </div>
    </div>
  );
};

const CardProgress = ({ card }) => {
  const [progress, setProgress] = React.useState(() => {
    if (!card.col.attributes?.timer) {
      return 0;
    }
    const diffInMinutes = dayjs().diff(dayjs(card.movedAt)) / 60000;
    const p = Math.min((diffInMinutes / card.col.attributes.timer) * 100, 100);

    return p;
  });

  useInterval(
    () => {
      if (!card.col.attributes?.timer || progress >= 100) {
        return;
      }
      const diffInMinutes = dayjs().diff(dayjs(card.movedAt)) / 60000;
      const p = Math.min(
        (diffInMinutes / card.col.attributes.timer) * 100,
        100
      );

      setProgress(p);
    },
    !card.col.attributes?.timer || progress >= 100 ? null : 1000
  );

  const colorClassName = () => {
    if (progress >= 100) {
      return "bg-red-500";
    } else if (progress >= 85) {
      return "bg-orange-500";
    } else if (progress >= 70) {
      return "bg-yellow-500";
    } else {
      return "bg-green-500";
    }
  };

  return (
    <>
      <p className="mt-1 text-xs">
        {card.col.attributes.timer && card.col.attributes.timerColumn.data
          ? `Card Moves ${dayjs()
              .add(
                ((100 - progress) / 100) * card.col.attributes.timer,
                "minute"
              )
              .fromNow()}`
          : `Created: ${dayjs(card.createdAt).fromNow()}`}
      </p>
      {card.col.attributes?.type !== "archive" && card.col.attributes?.timer ? (
        <div
          className={`relative mt-1 h-1.5 w-full rounded-full ${
            card.color ? "bg-gray-50" : "bg-gray-200"
          }`}
        >
          <div
            className={`absolute left-0 top-0 bottom-0 rounded-full ${colorClassName()}`}
            style={{
              width: `${progress}%`,
            }}
          />
        </div>
      ) : null}
    </>
  );
};

const ValidateAddressButton = ({ address, setValid }) => {
  const { data, mutate, isLoading, isSuccess } = useMutation(validateAddress, {
    onSuccess: data => {
      if (data.data.valid) {
        toast.success("Address valid");
        if (setValid) {
          setValid();
        }
      } else {
        toast.error(data.data.error || "Address invalid");
      }
    },
    onError: () => {
      toast.error("Error validating address");
    },
  });

  return (
    <div className="absolute right-0 -top-0.5 flex items-center">
      {!isLoading && isSuccess ? (
        <>
          {data?.data?.valid ? (
            <CheckIcon className="h-4 w-4 text-green-500" />
          ) : (
            <XIcon className="h-4 w-4 text-red-500" />
          )}
        </>
      ) : null}
      {isLoading && <Spinner />}
      <button
        className={classNames(
          "rounded bg-gray-200 px-2 py-1 text-xs font-bold text-gray-600 transition-colors hover:bg-gray-300",
          "ml-1 disabled:pointer-events-none"
        )}
        type="button"
        onClick={() => {
          mutate({ address });
        }}
      >
        Validate
      </button>
    </div>
  );
};

const LodgeParcelButton = ({
  address,
  firstName,
  lastName,
  phoneNumber,
  cardId,
  disabled,
}) => {
  const { mutate, isLoading } = useMutation(lodgeParcel, {
    onSuccess: data => {
      toast.success("Parcel sent");
    },
    onError: () => {
      toast.error("Error lodging parcel");
    },
  });

  return (
    <div className="flex items-center justify-end">
      {isLoading && <Spinner />}
      <button
        title="Lodge eParcel"
        disabled={isLoading || disabled}
        className={classNames("h-8 overflow-y-clip pr-6")}
        type="button"
        onClick={() => {
          mutate({
            data: {
              address,
              phoneNumber: phoneNumber,
              name: `${firstName} ${lastName}`,
              cardId: cardId,
            },
          });
        }}
      >
        <img
          src="/lodge-eparcel-btn.png"
          className="h-8 scale-[2]"
          alt="Lodge eParcel"
        />
      </button>
    </div>
  );
};

const Card = ({ boardId, card, cardBag }) => {
  const queryClient = useQueryClient();
  const { store } = useAuth();

  const [dialogOpen, setDialogOpen] = useBoolean(false);
  const [addressValid, setAddressValid] = useBoolean(false);

  const {
    register,
    unregister,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    setValue,
  } = useForm({
    defaultValues: {
      phoneNumber: card.phoneNumber,
      firstName: card.firstName,
      lastName: card.lastName,
      address: card.address,
      description: card.description,
      color: card.color,
    },
    shouldUnregister: true,
  });

  React.useEffect(() => {
    if (dialogOpen) {
      register("color", {});
      register("address", {});
    } else {
      unregister("color");
      unregister("address");
    }

    return () => {
      unregister("color");
      unregister("address");
    };
  }, [dialogOpen, register, unregister]);

  const selectedFirstname = watch("firstName", card.firstName);
  const selectedLastname = watch("lastName", card.lastName);
  const selectedPhoneNumber = watch("phoneNumber", card.phoneNumber);
  const selectedAddress = watch("address", card.address);
  const setAddress = address => {
    setValue("address", address);
  };

  const selectedColor = watch("color", card.color);
  const setColor = color => {
    setValue("color", color);
  };

  const { mutate, isLoading } = useMutation(updateCard, {
    onSuccess: (data, variables) => {
      queryClient.setQueryData(
        ["/boards", { boardId: `${boardId}` }],
        oldData => {
          if (!oldData) {
            return oldData;
          }
          return {
            ...oldData,
            data: {
              ...oldData.data,
              attributes: {
                ...oldData.data.attributes,
                columns: {
                  data: oldData.data.attributes.columns.data.map(col => {
                    if (col.id === card.col.id) {
                      return {
                        ...col,
                        attributes: {
                          ...col.attributes,
                          cards: {
                            data: col.attributes.cards.data.map(c => {
                              if (c.id === card.id) {
                                return {
                                  ...data.data,
                                };
                              }
                              return c;
                            }),
                          },
                        },
                      };
                    }
                    return col;
                  }),
                },
              },
            },
          };
        }
      );
      queryClient.refetchQueries(["/boards", { boardId: `${boardId}` }]);

      reset({
        ...variables.formData,
      });
      setDialogOpen.off();
      toast.success("Edited card");
    },
    onError: () => {
      toast.error("Error editing card");
    },
  });

  const onSubmit = data => {
    const phoneNumber = isValidPhoneNumber(data.phoneNumber, "AU")
      ? parsePhoneNumber(data.phoneNumber, "AU").number
      : parsePhoneNumber(data.phoneNumber, "PK").number;

    mutate({
      data: {
        phoneNumber: phoneNumber,
        firstName: data.firstName,
        lastName: data.lastName,
        address: data.address,
        description: data.description,
        color: data.color,
      },
      cardId: card.id,
      formData: data,
    });
  };

  const onCancel = () => {
    reset();
    setDialogOpen.off();
  };

  const onArchive = () => {
    onCancel();

    queryClient.setQueryData(
      ["/boards", { boardId: `${boardId}` }],
      oldData => {
        if (!oldData) {
          return oldData;
        }
        return {
          ...oldData,
          data: {
            ...oldData.data,
            attributes: {
              ...oldData.data.attributes,
              columns: {
                data: oldData.data.attributes.columns.data.map(col => {
                  if (col.id === card.col.id) {
                    return {
                      ...col,
                      attributes: {
                        ...col.attributes,
                        cards: {
                          data: col.attributes.cards.data.filter(
                            c => c.id !== card.id
                          ),
                        },
                      },
                    };
                  }
                  return col;
                }),
              },
            },
          },
        };
      }
    );
  };

  return (
    <div
      className={classNames(
        `relative mt-2 w-full max-w-[284px] overflow-x-hidden rounded p-1.5`,
        cardBag.dragging ? "rotate-3 opacity-75 shadow-md" : "shadow"
      )}
      style={{
        borderTop: `5px solid ${card.color || "#fff"}`,
        backgroundColor: `${card.color ? card.color + "22" : "#fff"}`,
      }}
      onClick={setDialogOpen.on}
    >
      <div className="relative w-full">
        <button
          className="absolute right-0 -bottom-5 rounded p-0.5 text-gray-500 hover:bg-black/5"
          onClick={setDialogOpen.on}
        >
          <DotsHorizontalIcon className="h-4 w-4" />
        </button>
        <p className="-mb-1 text-sm font-bold">
          {card.firstName} {card.lastName}
        </p>
        <p className="text-xs text-gray-600">{card.phoneNumber}</p>
        {card.description && (
          <p className="my-1 text-xs text-gray-500">
            {card.description.length > 100
              ? card.description.slice(0, 100) + "..."
              : card.description}
          </p>
        )}
      </div>
      <CardProgress card={card} />

      <FormDialog
        open={dialogOpen}
        onClose={onCancel}
        title="Edit Card"
        disableClose={isLoading}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="form-group">
            <label htmlFor="phoneNumber">Phone Number</label>
            <input
              type="text"
              id="phoneNumber"
              autoFocus
              className={`${classNames(
                dialogOpen && errors.phoneNumber && "form-error"
              )}`}
              placeholder="Enter Phone Number..."
              disabled={isLoading}
              {...register("phoneNumber", {
                required: {
                  value: true,
                  message: "Phone Number is required",
                },
                validate: value => {
                  return (
                    isValidPhoneNumber(value, "AU") ||
                    isValidPhoneNumber(value, "PK") ||
                    "Phone Number must be a valid AU Mobile Number"
                  );
                },
              })}
            />
            {dialogOpen && errors.phoneNumber && (
              <small className="form-error-text">
                {errors.phoneNumber.message}
              </small>
            )}
          </div>
          <div className="grid grid-cols-2 gap-4">
            <div className="form-group">
              <label htmlFor="firstName">First Name</label>
              <input
                type="text"
                id="firstName"
                className={`${classNames(
                  dialogOpen && errors.firstName && "form-error"
                )}`}
                placeholder="Enter name..."
                disabled={isLoading}
                {...register("firstName", {
                  required: {
                    value: true,
                    message: "First Name is required",
                  },
                  maxLength: {
                    value: 50,
                    message: "First Name must be less than 50 characters",
                  },
                })}
              />
              {dialogOpen && errors.firstName && (
                <small className="form-error-text">
                  {errors.firstName.message}
                </small>
              )}
            </div>

            <div className="form-group">
              <label htmlFor="lastName">Last Name</label>
              <input
                type="text"
                id="lastName"
                className={`${classNames(
                  dialogOpen && errors.lastName && "form-error"
                )}`}
                placeholder="Enter name..."
                disabled={isLoading}
                {...register("lastName", {
                  maxLength: {
                    value: 50,
                    message: "Last Name must be less than 50 characters",
                  },
                })}
              />
              {dialogOpen && errors.lastName && (
                <small className="form-error-text">
                  {errors.lastName.message}
                </small>
              )}
            </div>
          </div>

          <div className="form-group">
            <label htmlFor="description">Description</label>
            <textarea
              id="description"
              placeholder="Enter description..."
              disabled={isLoading}
              rows={2}
              {...register("description", {})}
            />
          </div>

          <div className="form-group">
            <label htmlFor="address">Address</label>

            <ReactGoogleAutocomplete
              className="mt-2"
              type="text"
              id="address"
              placeholder="Enter address..."
              disabled={isLoading}
              apiKey={GOOGLE_MAPS_API_KEY}
              onPlaceSelected={place => {
                setAddress(place.formatted_address);
              }}
              onChange={e => {
                setAddress(e.target.value);
              }}
              language="en-AU"
              options={{
                types: ["address"],
                fields: ["formatted_address", "place_id"],
                componentRestrictions: { country: "au" },
              }}
              defaultValue={card.address}
            />

            {store.auspost &&
              card.col.attributes.type === "lodge_parcel" &&
              card.address && (
                <ValidateAddressButton
                  disabled={isLoading}
                  address={selectedAddress || card.address}
                  setValid={setAddressValid.on}
                />
              )}
            {store.auspost && addressValid && (
              <div className="mt-2 flex justify-end">
                <LodgeParcelButton
                  address={selectedAddress || card.address}
                  firstName={selectedFirstname || card.firstName}
                  lastName={selectedLastname || card.lastName}
                  phoneNumber={selectedPhoneNumber || card.phoneNumber}
                  disabled={isLoading}
                  cardId={card.id}
                />
              </div>
            )}
          </div>

          <div className="form-group">
            <label htmlFor="color">Color</label>
            <ColorSelector
              colors={CARD_COLORS}
              value={selectedColor}
              setValue={setColor}
              disabled={isLoading}
            />
          </div>

          <Disclosure>
            {({ open }) => (
              <>
                <Disclosure.Button className="flex w-full items-center justify-between rounded bg-gray-100 p-3  hover:bg-gray-200">
                  <p className="mr-2 overflow-x-hidden text-ellipsis whitespace-nowrap text-sm">
                    Additional Options
                  </p>
                  <span className="flex min-w-fit items-center">
                    <ChevronDownIcon
                      className={`h-5 w-5 min-w-[1.25rem] ${
                        open ? "rotate-180 transform" : ""
                      }`}
                    />
                  </span>
                </Disclosure.Button>
                <Disclosure.Panel className="relative w-full pt-3 text-sm">
                  <div className="my-2 flex w-full items-center justify-between rounded bg-red-50 p-3">
                    <p>Archive this card?</p>

                    <ArchiveButton
                      disabled={isLoading}
                      contentType="card"
                      contentId={card.id}
                      onSuccess={onArchive}
                    />
                  </div>
                  <hr />
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>

          <div className="mt-4 grid grid-cols-2 gap-4">
            <button
              type="button"
              className="btn-danger-outline"
              disabled={isLoading}
              onClick={onCancel}
            >
              Cancel
            </button>

            <button type="submit" className="btn-primary" disabled={isLoading}>
              Save
            </button>
          </div>
        </form>
      </FormDialog>
    </div>
  );
};

const PrintDoc = ({
  name,
  address1,
  address2,
  xDimension,
  yDimension,
  xOffset,
  yOffset,
  fontSize,
}) => (
  <Document producer="concertrx" author="concertrx">
    <Page
      size={{
        width: `${xDimension + xOffset}mm`,
        height: `${yDimension + yOffset}mm`,
      }}
    >
      <View
        style={{
          width: `${xDimension}mm`,
          height: `${yDimension}mm`,
          marginLeft: `${xOffset}mm`,
          marginTop: `${yOffset}mm`,
          padding: "2mm",
          fontSize: `${fontSize}mm`,
        }}
      >
        <Text>{name}</Text>
        <Text>{address1}</Text>
        <Text>{address2}</Text>
      </View>
    </Page>
  </Document>
);

const getPrinter = ({ store, column }) => {
  if (column?.printer?.data) {
    return column.printer.data.attributes;
  }

  const { printers } = store;
  const printer = printers?.find(p => p.default);
  return printer;
};

const printPdf = async ({ card, column, store }) => {
  try {
    const [a1, a2] = card.address.split("\n");

    const name = `${card.firstName} ${card.lastName || ""}`;
    const address1 = `${a1}`;
    const address2 = `${a2 || ""}`;

    const printer = getPrinter({ store, column });

    if (!printer) {
      return;
    }

    const pdfBlob = await pdf(
      <PrintDoc
        name={name}
        address1={address1}
        address2={address2}
        xDimension={printer.xDimension}
        yDimension={printer.yDimension}
        xOffset={printer.xOffset}
        yOffset={printer.yOffset}
        fontSize={printer.fontSize}
      />
    ).toBlob();

    const pdfBase64 = await blobToBase64(pdfBlob);
    const pdfContent = pdfBase64.slice(pdfBase64.indexOf(",") + 1);

    createPrintJob({
      data: {
        content: pdfContent,
        contentType: "pdf_base64",
        printerId: printer.printerId,
        title: `${card.firstName}${
          card.lastName ? ` ${card.lastName}` : ""
        }'s Delivery Label`,
      },
    });
    toast.info("Request sent to print address label.");
  } catch (e) {
    console.log(e);
    toast.error("Error while requesting to print address label.");
  }
};

const Kanban = ({ boardId, allowCardAdd = false, allowColumnEdit = false }) => {
  const { store } = useAuth();
  const queryClient = useQueryClient();
  const { data, error } = useQuery(
    ["/boards", { boardId: `${boardId}` }],
    findBoardById,
    {
      refetchInterval: 10 * 1000,
    }
  );

  const { mutate, isLoading } = useMutation(moveCardv2, {
    onMutate: ({ data: { cardId, card, sourceId, destinationId } }) => {
      queryClient.setQueryData(
        ["/boards", { boardId: `${boardId}` }],
        oldData => {
          return {
            ...oldData,
            data: {
              ...oldData.data,
              attributes: {
                ...oldData.data.attributes,
                columns: {
                  data: oldData.data.attributes.columns.data.map(col => {
                    if (col.id === sourceId) {
                      return {
                        ...col,
                        attributes: {
                          ...col.attributes,
                          cards: {
                            data: col.attributes.cards.data
                              .filter(c => c.id !== cardId)
                              .sort(
                                (a, b) =>
                                  a.attributes.position - b.attributes.position
                              )
                              .map((c, idx) => ({
                                ...c,
                                attributes: {
                                  ...c.attributes,
                                  position: idx,
                                },
                              })),
                          },
                        },
                      };
                    }
                    if (col.id === destinationId) {
                      const sortedCards = col.attributes.cards.data.sort(
                        (a, b) => a.attributes.position - b.attributes.position
                      );

                      return {
                        ...col,
                        attributes: {
                          ...col.attributes,
                          cards: {
                            data: [
                              { id: card.id, attributes: { ...card } },
                              ...sortedCards,
                            ].map((c, idx) => ({
                              ...c,
                              attributes: {
                                ...c.attributes,
                                position: idx,
                              },
                            })),
                          },
                        },
                      };
                    }
                    return col;
                  }),
                },
              },
            },
          };
        }
      );
      toast.loading("Moving card...", {
        toastId: `moveCard-${cardId}`,
      });
    },
    onSuccess: (data, { data: { cardId } }) => {
      queryClient
        .refetchQueries(["/boards", { boardId: `${boardId}` }])
        .then(() => {
          if (data?.data?.message === "Message queued") {
            toast.update(`moveCard-${cardId}`, {
              render: "Message queued. Will be sent during daytime hours",
              type: "warning",
              isLoading: false,
              autoClose: 3000,
            });
          } else {
            toast.update(`moveCard-${cardId}`, {
              render: "Card moved successfully.",
              type: "success",
              isLoading: false,
              autoClose: 3000,
            });
          }
        });
    },
    onError: (error, { data: { cardId } }) => {
      console.log(error);
      toast.update(`moveCard-${cardId}`, {
        render: "Error while moving card.",
        type: "error",
        isLoading: false,
        autoClose: 3000,
      });
      queryClient.refetchQueries(["/boards", { boardId: `${boardId}` }]);
    },
  });

  const [filters, setFilters] = React.useState({});

  const filterColumnCards = columnId => query => {
    setFilters(f => ({ ...f, [columnId]: query }));
  };

  const clearColumnFilter = columnId => () => {
    setFilters(f => ({ ...f, [columnId]: "" }));
  };

  // const clearAllFilters = () => {
  //   setFilters({});
  // };

  const [sorts, setSorts] = React.useState({});

  const sortColumnCards = columnId => query => {
    setSorts(s => ({ ...s, [columnId]: query }));
  };

  const clearColumnSort = columnId => () => {
    setSorts(s => ({ ...s, [columnId]: "" }));
  };

  // const clearAllSorts = () => {
  //   setSorts({});
  // };

  const { data: creditData } = useQuery(
    ["/store/credit"],
    getOrCreateStoreCredit,
    {
      retry: false,
    }
  );

  if (data) {
    const columns = data.data.attributes.columns.data
      .map(col => {
        const filter = filters[col.id];
        const sort = sorts[col.id];
        const isArchive = col.attributes.type === "archive";

        let cards = col.attributes.cards
          ? col.attributes.cards.data
              .map(card => ({
                id: card.id,
                col: col,
                ...card.attributes,
              }))
              .sort((a, b) => a.position - b.position)
          : [].sort((a, b) => a.position - b.position);
        if (isArchive && col.attributes.timer) {
          const archiveDate = dayjs().subtract(col.attributes.timer, "minute");
          cards = cards.filter(card =>
            dayjs(card.movedAt).isAfter(archiveDate)
          );
        }
        if (filter) {
          const fuse = new Fuse(cards, {
            includeScore: true,
            threshold: 0.0,
            keys: [
              {
                name: "name",
                getFn: card => `${card.firstName} ${card.lastName}`,
              },
              "phoneNumber",
            ],
            useExtendedSearch: false,
            ignoreLocation: true,
          });
          cards = fuse.search(filter).map(result => result.item);
        }
        if (sort) {
          const [sortBy, sortOrder] = sort.split("_");
          cards = cards.sort((a, b) => {
            if (sortBy === "name") {
              return sortOrder === "ASC"
                ? b.firstName.localeCompare(a.firstName)
                : a.firstName.localeCompare(b.firstName);
            }
            if (sortBy === "createdAt") {
              return sortOrder === "ASC"
                ? dayjs(a.createdAt).isBefore(dayjs(b.createdAt))
                  ? 1
                  : -1
                : dayjs(a.createdAt).isAfter(dayjs(b.createdAt))
                ? 1
                : -1;
            }
            if (sortBy === "movedAt") {
              return sortOrder === "ASC"
                ? dayjs(a.movedAt).isBefore(dayjs(b.movedAt))
                  ? 1
                  : -1
                : dayjs(a.movedAt).isAfter(dayjs(b.movedAt))
                ? 1
                : -1;
            }
            return 0;
          });
        }
        return {
          id: col.id,
          ...col.attributes,
          cards: cards,
        };
      })
      .sort((a, b) => a.position - b.position);

    const handleCardDragEnd = async (card, source, destination) => {
      if (source.fromColumnId === destination.toColumnId) {
        return;
      }

      const sourceColumn = columns.find(col => col.id === source.fromColumnId);

      const destinationColumn = columns.find(
        col => col.id === destination.toColumnId
      );

      if (
        destinationColumn.message &&
        sourceColumn.position > destinationColumn.position
      ) {
        toast.error("Cannot move card to a previous column");
        return;
      }

      // const now = dayjs().toISOString();
      // const storeStart = dayjs(
      //   `${now.split("T")[0]}T${store.startTime}`
      // ).toISOString();
      // const storeStop = dayjs(
      //   `${now.split("T")[0]}T${store.stopTime}`
      // ).toISOString();
      // const isInHours = dayjs(now).isBetween(
      //   storeStart,
      //   storeStop,
      //   "minute",
      //   "[]"
      // );

      if (
        store.printers.length > 0 &&
        destinationColumn.type === "print_label"
      ) {
        printPdf({
          card,
          store,
          column: destinationColumn,
        });
      }

      console.log({
        card,
        cardId: card.id,
        storeId: store.id,
        sourceId: source.fromColumnId,
        destinationId: destination.toColumnId,
      });

      mutate({
        data: {
          card,
          cardId: card.id,
          storeId: store.id,
          sourceId: source.fromColumnId,
          destinationId: destination.toColumnId,
        },
      });
    };

    return (
      <div className="board">
        <Board
          disableCardDrag={
            isLoading || (creditData && creditData?.credit?.amount === 0)
          }
          disableColumnDrag
          onCardDragEnd={handleCardDragEnd}
          renderCard={(card, cardBag) => (
            <Card boardId={boardId} card={card} cardBag={cardBag} />
          )}
          renderColumnHeader={column => (
            <ColumnHeader
              boardId={boardId}
              column={column}
              allowCardAdd={allowCardAdd}
              allowColumnEdit={allowColumnEdit}
              filter={filters[column.id]}
              filterColumnCards={filterColumnCards(column.id)}
              clearColumnFilter={clearColumnFilter(column.id)}
              sort={sorts[column.id]}
              sortColumnCards={sortColumnCards(column.id)}
              clearColumnSort={clearColumnSort(column.id)}
            />
          )}
        >
          {{ columns }}
        </Board>
        {columns.length < 6 && (
          <AddColumnButton boardId={boardId} columns={columns} />
        )}
      </div>
    );
  }

  if (error) {
    return <div>Error</div>;
  }

  return (
    <div className="px-2">
      <Spinner />
    </div>
  );
};

export default Kanban;
