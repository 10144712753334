import React from "react";
import {
  CheckCircleIcon,
  XCircleIcon,
  ExclamationCircleIcon,
  ExclamationIcon,
} from "@heroicons/react/solid";

const Alert = ({ show = true, type = "info", content = "" }) => {
  if (!show) return null;

  if (type === "error") {
    return (
      <div className="flex items-center rounded border border-red-500 bg-red-100 p-2 text-sm text-slate-700">
        <XCircleIcon className="mr-2 h-5 w-5 min-w-[1.25rem] text-red-600" />
        {content}
      </div>
    );
  }
  if (type === "warning") {
    return (
      <div className="flex items-center rounded border border-yellow-500 bg-yellow-100 p-2 text-sm text-slate-700">
        <ExclamationIcon className="mr-2 h-5 w-5 min-w-[1.25rem] text-yellow-500" />
        {content}
      </div>
    );
  }
  if (type === "success") {
    return (
      <div className="flex items-center rounded border border-green-500 bg-green-100 p-2 text-sm text-slate-700">
        <CheckCircleIcon className="mr-2 h-5 w-5 min-w-[1.25rem] text-green-600" />
        {content}
      </div>
    );
  }
  if (type === "info") {
    return (
      <div className="flex items-center rounded border border-blue-500 bg-blue-100 p-2 text-sm text-slate-700">
        <ExclamationCircleIcon className="mr-2 h-5 w-5 min-w-[1.25rem] text-blue-600" />
        {content}
      </div>
    );
  }

  return (
    <div className="flex items-center rounded border border-slate-500 bg-slate-100 p-2 text-sm text-slate-700">
      <ExclamationCircleIcon className="mr-2 h-5 w-5 min-w-[1.25rem] text-slate-600" />
      {content}
    </div>
  );
};

export default Alert;
