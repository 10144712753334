import { PrinterIcon } from "@heroicons/react/outline";
import { useAuth } from "../utils/auth";

const SubscriberAgreementPage = () => {
  const { store } = useAuth();

  return (
    <div className="w-full">
      <div className="flex flex-col items-start sm:flex-row sm:items-end sm:justify-between">
        <div>
          <h2 className="w-full text-xl font-semibold text-slate-700">
            Subscriber Agreement
          </h2>
          <p className="text-xs text-slate-500">
            Subscriber Agreement between ConcertRx and your pharmacy.
          </p>
        </div>
        <button
          className="mt-2 flex items-center rounded border p-2 text-xs"
          onClick={window.print}
        >
          PRINT
          <PrinterIcon className="ml-2 h-4 w-4" />
        </button>
      </div>
      <hr className="my-4" />
      <pre className="w-full whitespace-pre-wrap bg-white print:absolute print:inset-0 print:z-[1000] print:p-10">
        {`
Entity Name: ${store.name}
Address: ${store.address}
ABN: ${store.abn}

1.	TERM OF AGREEMENT

1.1.	This Agreement commences on the Effective Date and continues unless cancelled by the Subscriber or terminated by ConcertRx. Subscriber

2.	THE SUBSCRIPTION

2.1.	From the Effective Date, ConcertRx grants the Subscriber, a non-exclusive, non-transferrable and non-sublicensable Subscription to enable the Subscriber to use the ConcertRx Applications (Subscription).

2.2.	As a precondition to entry into the Subscription, the Subscriber must:
(a)	create a Subscriber profile on the ConcertRx website and provide the Mandatory Subscriber Information and any other information requested; and
(b)	enter into an End User Licence Agreement,
and ConcertRx will review the new Subscriber account application before the account is approved to use the ConcertRx Applications.

2.3.	ConcertRx maintains an account for each Subscriber, which will be used to pay the Subscription Fees and SMS Fees via Stripe Billing. No Subscriber credit card or banking details will be stored by ConcertRx. The Subscriber must pay funds into its account in advance to cover SMS Fees.

2.4.	The Subscriber account balance can be checked by logging into a ConcertRx Application. An account balance cannot be transferred.The account balance will be reduced by the SMS Fee for each SMS sent.

3.	SMSs

3.1.	The Subscriber can create and send an SMS to a Patient via the Subscriber account on the ConcertRx Applications in accordance with the Subscriber user instructions and guidelines on the ConcertRx Applications.

3.2.	ConcertRx will charge the Subscriber an SMS Fee for each SMS sent. An SMS will only be sent if there are sufficient funds in the Subscriber account to cover the SMS Fee.

3.3.	SMS status can be viewed on the ConcertRx Applications by Subscribers.

3.4.	In relation to any GST that is payable in respect of a taxable supply (as defined in the GST Act) made under this Agreement, the Subscriber shall pay applicable GST to ConcertRx, in addition to the SMS Fee A tax invoice will be issued to the Subscriber following payment via Stripe .

3.5.	ConcertRx reserves the right to disable a Subscription if the Subscriber, or any Subscriber personnel acting on its behalf, misuse the ConcertRx Applications, including by sending an SMS which is false or misleading, does not comply with Australian law, or is otherwise harmful or offensive.

3.6.	SMS Fees paid will not be refunded if an SMS is not received by the Patient due to Subscriber error, Patient error, or any fault of a telecommunications provider.

4.	SUBSCRIBER RESPONSIBILITIES

4.1.	The Subscriber is responsible for maintaining control over and access to, and must supervise all access to and use of the ConcertRx Applications under its Subscription and must ensure that each Pharmacy User is an individual who is either:
(i)	an employee of the Subscriber or a Related Body Corporate of the Subscriber; or
(ii)	an individual who is a direct contractor of the Subscriber or a Related Body Corporate of the Subscriber,
and that each Pharmacy User enter into an End User Licence Agreement.

4.2.	The Subscriber must conduct themselves honestly and in good faith at all times when using the ConcertRx Applications.

4.3.	The Subscriber must notify ConcertRx via email if there are any changes to the Mandatory Subscriber Information.

5.	CONFIDENTIALITY

5.1.	ConcertRx and Subscriber each agree to keep the other party’s Confidential Information confidential. Without limiting the above, neither party may use, disclose or make public any Confidential Information, other than as required by law without the prior written approval of the other party. This provision shall survive termination of this Agreement.
5.2.	Each party shall ensure that any personnel who have access to the other party’s Confidential Information are aware of and also comply with the obligations of confidentiality set out in this clause.

6.

6.1.	This Agreement may be terminated:
(a)	by the Subscriber at any time by closing its Subscriber account on the ConcertRx Applications;
(b)	by ConcertRx if the Subscriber commits a material breach of its responsibilities under this Agreement or fails to pay the Subscription Fee when due; or
(c)	by either party, without notice, if the other party goes into administration or liquidation or enters into any arrangement with that party’s creditors.

7.	PRIVACY

7.1.	In performing this Agreement, ConcertRx and Subscriber must comply, and must make sure that all of their personnel and subcontractors also comply, with all Privacy Laws in respect of all Personal Information collected, accessed, used, disclosed and otherwise handled by them under or in connection with this Agreement or the ConcertRx Applications.

8.	DISCLAIMER OF WARRANTIES

8.1.	To the maximum extent permitted by Law (including the Australian Consumer Law), ConcertRx makes no representations or warranties of any kind to Subscriber or to any other person or entity with respect to the ConcertRx Applications and disclaims all express and implied warranties, including without limitation warranties of merchantability and fitness for a particular purpose.

8.2.	ConcertRx assumes no responsibility for the Subscriber entering into an any agreement with a third party on the ConcertRx Application.

9.	LIMITATION OF LIABILITY

9.1.	Notwithstanding anything else in this Agreement or otherwise, neither party and each of their officers, directors, employees and agents will be liable to the other party with respect to any subject matter of this Agreement under any contract, negligence, strict liability or other legal or equitable theory (i) for any amounts in excess of the total Subscription Fees actually paid to ConcertRx by Subscriber under this Agreement in the one-year period preceding the event giving rise to the liability; or (ii) for any punitive, special, incidental, indirect or consequential damages or lost data, even if it has been advised of the possibility of such damages; or (iii) for cost of procurement of substitute goods, technology or services; or (iv) for loss or corruption of data or interruption of use.

10.	INDEMNITIES

10.1.	Without limiting any other indemnities given by Subscriber under this Agreement, Subscriber shall defend, hold harmless and indemnify ConcertRx, its Related Bodies Corporate and personnel (the ConcertRx Indemnified Parties) from and against any Loss suffered or incurred by the ConcertRx Indemnified Parties arising out of or in connection with:
(a)	any fraud, wilful misconduct or negligence by Subscriber or its personnel; or
(b)	any third-party claim relating to the Subscriber's, or any Pharmacy User's, use of the ConcertRx Applications or any Third Party Content or service (other than a claim arising from ConcertRx's breach of this Agreement or negligent conduct).

11.	NOTICES

11.1.	Any notice, demand, consent or other communication given or made under this Agreement must be in writing and signed by the sender or a person duly authorised by the sender (or in the case of email, set out the full name and position or title of the sender or person duly authorised by the sender).

12.	GOVERNING LAW

12.1.	This Agreement is governed by the laws of New South Wales and the Parties submit to the jurisdiction of the Courts of New South Wales in relation to this Agreement.

13.	NO WAIVER

13.1.	No failure to exercise and no delay in exercising any right, power or remedy under this Agreement will operate as a waiver, nor will any single or partial exercise of any right, power or remedy preclude any other or further exercise, of that or any other right, power or remedy.

14.	FORCE MAJEURE

14.1.	Neither party shall be under any liability to the other for failure or delay in the performance of any obligation under this Agreement (other than obligations to pay money) to the extent and for the period that such performance is prevented by reason of Force Majeure, provided that the party claiming the benefit of this clause gives written notice of the Force Majeure to the other. Force Majeure shall include any circumstance beyond the reasonable control of either Party which results in a Party being unable to observe or perform on time an obligation under this Agreement.

15.	SEVERABILITY AND ENTIRE AGREEMENT

15.1.	In the event that any provision of this Agreement is held to be invalid, illegal or unenforceable, that provision must, to the extent of the invalidity, illegality or unenforceability be ignored in the interpretation of this Agreement and all the other provisions of this Agreement will remain in full force and effect.

15.2.	This Agreement contains the entire agreement of the Parties with respect to its subject matter and supersedes all prior representations and agreements in connection with that subject matter.

15.3.	This Agreement may be amended only by a further agreement in writing executed by the Parties.


In this document unless the context otherwise requires:

Australian Consumer Law means Schedule 2 of the Competition and Consumer Act 2010 (Cth), as amended or replaced from time to time.

ConcertRx Applications means the frontend applications and back-end portal, and includes the ConcertRx website, the IOS application, the website application, databases, proprietary software and information technology systems utilised to provide the Subscription service and utilised by Subscribers.

Confidential Information means all information relating to the business of a party, the ConcertRx Applications, the Subscription or any confidential information, including but not limited to all information or data which has been or may be disclosed by or on behalf of a party (whether orally, in writing or otherwise) including but not limited to designs, specifications, systems, processes, statements, trade secrets, technical information or data related to the operations and dealings of a party and its business, its finances, marketing or product information, technology, trade-secrets, business secrets, knowhow and confidential information but does not include information which is in or becomes part of the public domain other than through a breach of this Agreement by a party receiving Confidential Information.

Effective Date means the date on which the Subscription commences.

End User Licence Agreement means the licence agreement entered into between ConcertRx with the Subscriber and each Pharmacy User regarding access to and use of the ConcertRx Applications.

GST means Goods and Services Tax, as defined in the GST Act.

GST Act means the A New Tax System (Goods and Services Tax) Act 1999 (Cth) as amended.

Intellectual Property Rights means all intellectual and industrial property rights throughout the world, whether registered or unregistered, including trademarks, designs, patents, inventions, circuit layouts, copyright and analogous rights, confidential information, know-how and all other intellectual property rights as defined in Article 2 of the convention establishing the World Intellectual Property Organisation of 14 July 1967 as amended from time to time.

Mandatory Subscriber Information means Subscriber name (individual or entity name), Subscriber Street address, Subscriber ABN, Pharmacy name, Pharmacy street address.

Patient means a patient or customer of the Subscriber who’s details are entered into the ConcertRx Applications.

Personal Information means any information or an opinion about an identified individual, or an individual who is reasonably identifiable, as defined in the Privacy Laws.

Pharmacy means the retail pharmacy where the ConcertRx Applications will be used.

Pharmacy User means a pharmacist or other employee or consultant of the Subscriber who uses the ConcertRx Applications in the course of employment or provision of services on behalf of the Subscriber.

Privacy Laws means:
(a)	the Privacy Act 1988 (Cth);
(b)	the Australian Privacy Principles contained in Schedule 1 of the Privacy Act 1988;
(c)	any approved privacy codes that apply to any of the parties; and
(d)	all other applicable laws that require a person to observe privacy or confidentiality obligations in respect of Personal Information,
in each case as amended from time to time.

SMS means a message sent from the ConcertRx Applications to a Patient.

SMS Fee means the fee of $0.10 (including GST) paid by the Subscriber for each SMS.

Subscriber means a pharmacy or pharmacist with an active Subscription.

Subscription Fee means the monthly fee of $29.95, 39.95 or $89.95 (including GST) paid by the Subscriber to maintain a Standard, Pro or Pro Plus Subscription.

Third Party Content means content developed for, or used in, the ConcertRx Applications by a party who is not the Subscriber or its personnel or ConcertRx.
`}
      </pre>
    </div>
  );
};

export default SubscriberAgreementPage;
