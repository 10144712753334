import { useMutation, useQuery, useQueryClient } from "react-query";
import { getStorePaymentMethods } from "../utils/queries";
import { toast } from "react-toastify";
import {
  attachStorePaymentMethod,
  detachStorePaymentMethod,
} from "../utils/mutations";
import Alert from "../components/Alert";
import { CreditCardIcon, PlusIcon, TrashIcon } from "@heroicons/react/solid";
import { useLocation } from "react-router-dom";
import Spinner from "../components/Spinner";
import useBoolean from "../hooks/useBoolean";
import ConfirmDialog from "../components/dialogs/ConfirmDialog";
import { XIcon } from "@heroicons/react/outline";

const AttachPaymentMethod = () => {
  const { mutate, isLoading } = useMutation(attachStorePaymentMethod, {
    onSuccess: data => {
      window.location.href = data.sessionUrl;
    },
    onError: () => {
      toast.error("Something went wrong");
    },
  });

  const isDisabled = isLoading;

  return (
    <button
      onClick={mutate}
      disabled={isDisabled}
      className="flex items-center rounded border border-blue-600 bg-blue-600 px-3 py-2 text-xs text-white hover:border-blue-700 hover:bg-blue-700 disabled:cursor-not-allowed disabled:border-gray-200 disabled:bg-gray-200 disabled:text-gray-500"
    >
      <PlusIcon className="mr-1 h-4 w-4" /> Add Payment Method
    </button>
  );
};

const PaymentMethod = ({ paymentMethod }) => {
  const [dialogOpen, setDialogOpen] = useBoolean(false);
  const queryClient = useQueryClient();

  const { card } = paymentMethod;

  const { mutate, isLoading } = useMutation(detachStorePaymentMethod, {
    onSuccess: (data, variables) => {
      setDialogOpen.off();
      toast.success(`Deleted payment method`);
      queryClient.refetchQueries([`/store/payment-method`]);
    },
    onError: () => {
      toast.error(`Error deleting payment method`);
    },
  });

  const handleCancel = () => {
    setDialogOpen.off();
  };

  const handleConfirm = () => {
    mutate({
      paymentMethodId: paymentMethod.id,
    });
  };

  return (
    <>
      <div className="relative mt-4 w-full max-w-sm rounded-md p-4 shadow">
        <button
          onClick={setDialogOpen.on}
          className="absolute right-4 top-4 rounded bg-red-50 p-1.5 text-red-500 hover:bg-red-100"
        >
          <TrashIcon className="h-3 w-3" />
        </button>
        <p className="font-semibold capitalize">{card.brand}</p>
        <p className="flex items-center justify-between lowercase">
          <span className="text-sm">xxxx-xxxx-xxxx-{card.last4}</span>
          <span className="text-xs text-slate-500">
            {card.exp_month} / {card.exp_year}
          </span>
        </p>
      </div>
      <ConfirmDialog
        open={dialogOpen}
        onCancel={handleCancel}
        onConfirm={handleConfirm}
        icon={
          <span className="flex h-28 w-28 items-center justify-center rounded-full bg-red-100">
            <XIcon className="h-16 w-16 text-red-500" />
          </span>
        }
        title={`Are you sure you want to delete this payment method?`}
        subtitle="Once completed, this action cannot be reversed."
        confirmBtnClassName="btn-danger"
        cancelBtnClassName="btn-primary-outline"
        confirmText="Yes, Delete"
        cancelText="No, Cancel"
        disabled={isLoading}
      />
    </>
  );
};

const PaymentMethodsPage = () => {
  const location = useLocation();

  const params = new URLSearchParams(location.search);

  const status = params.get("status");

  const { data, isLoading } = useQuery(
    ["/store/payment-method"],
    getStorePaymentMethods,
    {
      retry: false,
    }
  );

  return (
    <div className="w-full">
      <div className="flex flex-col items-start sm:flex-row sm:items-end sm:justify-between">
        <div>
          <h2 className="w-full text-xl font-semibold text-slate-700">
            Payment Methods
          </h2>
          <p className="text-xs text-slate-600">
            Manage your payment methods here
          </p>
        </div>
        <div className="mt-2">
          <AttachPaymentMethod />
        </div>
      </div>
      <hr className="my-4" />
      {isLoading ? (
        <Spinner />
      ) : (
        <div>
          {status === "success" && (
            <Alert
              type="success"
              content="Payment method added successfully."
            />
          )}
          {status === "cancel" && (
            <Alert
              type="error"
              content="Error occurred while adding payment method."
            />
          )}
          {data?.paymentMethods?.length > 0 ? (
            <div>
              {data.paymentMethods.map(paymentMethod => (
                <PaymentMethod
                  key={paymentMethod.id}
                  paymentMethod={paymentMethod}
                />
              ))}
            </div>
          ) : (
            <div className="flex w-full flex-col items-center py-10">
              <CreditCardIcon className="h-10 w-10" />
              <p className="mt-2 mb-4 text-xs text-slate-600">
                You have no payment methods
              </p>
              <AttachPaymentMethod />
            </div>
          )}
          <div></div>
          <small className="mt-4 block max-w-prose whitespace-pre-wrap text-xs text-slate-400">
            <p>
              We use Stripe to process your payment. It's the same payment
              provider used by products such as Twitter, Pinterest, and Lyft. We
              do not handle your credit card information directly.
            </p>
          </small>
        </div>
      )}
    </div>
  );
};

export default PaymentMethodsPage;
