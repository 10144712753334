import { Dialog, Transition } from "@headlessui/react";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { getRandomPin } from "../../utils/helpers";

const ConfirmDialog = ({
  open,
  icon,
  title,
  subtitle,
  confirmText = "Confirm",
  cancelText = "Cancel",
  onConfirm,
  onCancel,
  confirmBtnClassName = "btn-primary",
  cancelBtnClassName = "btn-danger-outline",
  disabled,
  extraConfirmation = true,
}) => {
  const [randomPin, setRandomPin] = useState(() => getRandomPin());
  const [pin, setPin] = useState();
  useEffect(() => {
    if (extraConfirmation && open) {
      setRandomPin(getRandomPin());
    }
  }, [extraConfirmation, open]);

  const handleConfirm = () => {
    if (extraConfirmation && pin !== randomPin) {
      return;
    }

    onConfirm();
  };

  const handleCancel = () => {
    onCancel();
  };

  return (
    <Transition appear show={open} as={React.Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-[100] overflow-y-auto bg-black bg-opacity-50"
        onClose={handleCancel}
      >
        <div className="min-h-screen px-4 text-center">
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="pointer-events-none fixed inset-0" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the theme contents. */}
          <span
            className="inline-block h-screen align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="my-8 inline-block w-full max-w-sm transform overflow-hidden rounded bg-white p-6 text-left align-middle shadow-xl transition-all">
              {icon && (
                <div className="mt-12 mb-12 flex items-center justify-center">
                  {icon}
                </div>
              )}
              {title && (
                <Dialog.Title
                  as="h3"
                  className="mt-12 mb-4 text-center text-xl font-medium leading-6 text-gray-900"
                >
                  {title}
                </Dialog.Title>
              )}
              {subtitle && (
                <p className="mt-4 mb-8 text-center text-sm text-gray-500">
                  {subtitle}
                </p>
              )}
              {extraConfirmation && (
                <div className="text-center text-sm text-gray-900">
                  <p className="mb-4">
                    Enter <strong>{randomPin}</strong> below to confirm action
                  </p>
                  <input
                    type="text"
                    id="title"
                    autoFocus
                    placeholder="XXXX"
                    disabled={disabled}
                    value={pin}
                    onChange={e => setPin(e.target.value)}
                  />
                </div>
              )}
              <div className="mt-4 grid w-full grid-cols-2 gap-4">
                <button
                  type="button"
                  className={cancelBtnClassName}
                  disabled={disabled}
                  onClick={handleCancel}
                >
                  {cancelText}
                </button>

                <button
                  className={confirmBtnClassName}
                  disabled={
                    disabled || (extraConfirmation && pin !== randomPin)
                  }
                  onClick={handleConfirm}
                >
                  {confirmText}
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

export default ConfirmDialog;
