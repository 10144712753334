import * as React from "react";
import { Link, Outlet, useLocation } from "react-router-dom";
import { classNames, textColor } from "../utils/helpers";
import { useTheme } from "../utils/theme";
import { ExternalLinkIcon } from "@heroicons/react/outline";
import { useQuery } from "react-query";
import { getBillingPortal } from "../utils/queries";
import { toast } from "react-toastify";
import Spinner from "../components/Spinner";

const BillingLink = () => {
  const { setColor } = useTheme();
  const { remove, refetch, isFetching } = useQuery(
    ["/billing-portal"],
    getBillingPortal,
    {
      enabled: false,
      onSuccess: data => {
        window.location.href = data.sessionUrl;
      },
      onError: () => {
        toast.error("Something went wrong");
      },
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      retryOnMount: false,
      retry: false,
    }
  );

  return (
    <button
      className={classNames(
        "m-0 flex h-full w-fit items-center justify-center border border-black/10 bg-white p-0 px-2 py-1 text-sm",
        "focus:outline focus:outline-2 focus:outline-offset-[-2px] focus:outline-black/75"
      )}
      style={{
        backgroundColor: `#0000`,
        color: textColor(null),
      }}
      onClick={() => {
        setColor(null);
        remove();
        refetch();
      }}
    >
      Billing{" "}
      {isFetching ? (
        <span className="ml-2">
          <Spinner />
        </span>
      ) : (
        <ExternalLinkIcon className="ml-2 h-4 w-4" />
      )}
    </button>
  );
};

const SETTINGS_LINKS = [
  {
    name: "Account",
    to: "/settings/account",
    activeLocation: ["/settings", "/settings/account"],
  },
  {
    component: <BillingLink />,
  },
  {
    name: "Subscriber Agreement",
    to: "/settings/subscriber-agreement",
    activeLocation: ["/settings/subscriber-agreement"],
  },
  {
    name: "Credits",
    to: "/settings/credits",
    activeLocation: ["/settings/credits"],
  },
  {
    name: "Users",
    to: "/settings/users",
    activeLocation: ["/settings/users"],
  },
];

const SettingsLinks = () => {
  const location = useLocation();
  const { setColor } = useTheme();

  return (
    <div className="my-4 flex h-8 w-full">
      {SETTINGS_LINKS.map((link, i) => {
        if (link.component) {
          return link.component;
        }
        return (
          <Link
            key={link.to}
            to={link.to}
            className={classNames(
              "w-fit bg-white p-0",
              i === SETTINGS_LINKS.length - 1 && "rounded-r",
              i === 0 && "rounded-l"
            )}
            onClick={() => {
              setColor(null);
            }}
          >
            <div
              className={classNames(
                "m-0 flex h-full w-full items-center justify-center border border-black/10 px-2 py-1 text-sm",
                link.activeLocation.includes(location?.pathname) &&
                  "outline outline-2 outline-offset-[-2px] outline-black/75",
                i === SETTINGS_LINKS.length - 1 && "rounded-r",
                i === 0 && "rounded-l"
              )}
              style={{
                backgroundColor: `#0000`,
                color: textColor(null),
              }}
            >
              {link.name}
            </div>
          </Link>
        );
      })}
    </div>
  );
};

const SettingsLayout = () => {
  const { setColor } = useTheme();

  React.useEffect(() => {
    if (setColor) {
      setColor(null);
    }
  }, [setColor]);
  return (
    <>
      <div className="mx-auto w-full max-w-6xl p-4">
        <h1 className="text-2xl font-semibold">Settings</h1>
        <p className="text-sm text-slate-600">
          Manage your account settings here.
        </p>
        <SettingsLinks />
        <Outlet />
      </div>
    </>
  );
};

export default SettingsLayout;
