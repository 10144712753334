import { useTheme } from "../utils/theme";

const Logo = ({ className = "w-auto h-8" }) => {
  const { textColor, color } = useTheme();
  const base = {
    fill: color ? textColor : `#1E1E1E`,
  };
  const highlight = {
    fill: color ? textColor : `#48B649`,
  };

  return (
    <svg
      version="1.1"
      id="concertrx-logo-full"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 587.01 202.88"
      xmlSpace="preserve"
      className={className}
    >
      <g>
        <g>
          <path
            style={highlight}
            d="M112.27,18.53c-0.81-0.75-2.08-0.7-2.83,0.11c-0.75,0.81-0.7,2.08,0.11,2.83l32.35,30
			c0.81,0.75,2.08,0.7,2.83-0.11c0.75-0.81,0.7-2.08-0.11-2.83L112.27,18.53z"
          />
          <path
            style={highlight}
            d="M112.73,123.75h-3.1v-3.1c0-1.1-0.9-2-2-2c-1.1,0-2,0.9-2,2v3.1h-3.1c-1.1,0-2,0.9-2,2c0,1.1,0.9,2,2,2h3.1
			v3.1c0,1.1,0.9,2,2,2c1.1,0,2-0.9,2-2v-3.1h3.1c1.1,0,2-0.9,2-2C114.73,124.65,113.84,123.75,112.73,123.75z"
          />
          <path
            style={highlight}
            d="M51.51,163.84c0,1.11,0.9,2,2,2c1.1,0,2-0.89,2-2v-2.6h-4V163.84z"
          />
          <path
            style={base}
            d="M51.51,153.65c0,1.1,0.9,2,2,2c1.1,0,2-0.9,2-2v-40.2c0-0.02-0.01-0.05-0.01-0.07c0-0.07-0.01-0.14-0.02-0.21
			c-0.01-0.06-0.02-0.12-0.03-0.18c-0.02-0.06-0.04-0.12-0.06-0.18c-0.02-0.06-0.04-0.12-0.07-0.18c-0.03-0.06-0.06-0.11-0.09-0.17
			c-0.03-0.05-0.06-0.11-0.1-0.16c-0.04-0.05-0.08-0.1-0.13-0.15c-0.04-0.05-0.08-0.1-0.13-0.14c-0.05-0.04-0.09-0.08-0.14-0.12
			c-0.06-0.04-0.11-0.09-0.17-0.12c-0.02-0.01-0.04-0.03-0.06-0.04l0,0c-4.11-2.33-8.73-5.49-13.21-9.73
			c-0.55-0.52-1.1-1.06-1.64-1.62l10.07-8.32c0.82,0.8,1.65,1.57,2.49,2.28l0,0c2.34,2.21,4.98,3.88,7.05,5.02
			c2.09,1.15,3.61,1.76,3.67,1.78c1.03,0.41,2.19-0.09,2.6-1.11c0.41-1.03-0.09-2.19-1.11-2.6l-0.01,0
			c-0.12-0.05-1.53-0.63-3.4-1.67c-1.87-1.04-4.19-2.55-6.12-4.38l-0.08-0.07c-3.37-2.88-6.63-6.48-9.31-10.91
			c-1.8-3-3.35-6.4-4.49-10.34c-1.14-3.93-1.89-8.41-2.02-13.56l0,0.01c-0.14-5.72-4.82-10.25-10.51-10.25l-0.25,0
			C22.53,46.39,18,51.05,18,56.73c0,0.09,0,0.18,0,0.27c0.31,12.62,3.48,23.31,8.2,31.92c3.53,6.45,7.87,11.73,12.33,15.96l0,0
			c4.39,4.16,8.89,7.31,12.97,9.71V153.65z"
          />
          <polygon style={base} points="26.21,88.92 26.21,88.92 26.21,88.92 		" />
          <polygon
            style={base}
            points="126.17,94.32 126.17,94.32 126.16,94.32 		"
          />
          <path
            style={base}
            d="M139.84,104.89L139.84,104.89c4.46-4.24,8.8-9.51,12.33-15.96c4.72-8.61,7.9-19.29,8.2-31.92
			c0-0.09,0-0.18,0-0.27c0-5.68-4.53-10.34-10.23-10.49l-0.25,0c-2.84,0-5.44,1.14-7.34,2.98c-1.9,1.84-3.1,4.42-3.17,7.27l0-0.01
			c-0.14,5.15-0.88,9.62-2.02,13.56c-0.25,0.87-0.55,1.74-0.85,2.62c-0.35,1.03-0.87,2.26-1.34,3.31c-0.47,1.04-0.92,1.93-1.08,2.23
			c-0.27,0.5-0.74,1.32-1.05,1.9c-0.8,1.52-2.56,3.92-3.45,4.98c-1.95,2.35-3.96,4.45-6.03,6.21l-0.01,0.01
			c-1.9,1.65-4.33,3.13-7.06,4.2c-2.73,1.06-5.77,1.71-8.91,1.71c-2.68,0-5.45-0.47-8.21-1.54c-1.03-0.4-2.19,0.11-2.59,1.14
			c-0.4,1.03,0.11,2.19,1.14,2.59c3.24,1.26,6.51,1.81,9.66,1.81c3.69,0,7.2-0.75,10.36-1.98c3.15-1.23,5.95-2.94,8.22-4.89
			c0.84-0.72,1.66-1.49,2.47-2.29l10.09,8.34c-0.54,0.56-1.09,1.1-1.64,1.62c-4.48,4.24-9.1,7.4-13.21,9.73
			c-0.02,0.01-0.04,0.03-0.07,0.05c-0.05,0.03-0.1,0.07-0.15,0.11c-0.06,0.04-0.12,0.09-0.17,0.14c-0.04,0.04-0.07,0.07-0.11,0.11
			c-0.05,0.06-0.1,0.12-0.15,0.18c-0.03,0.04-0.05,0.08-0.08,0.12c-0.04,0.07-0.08,0.14-0.11,0.21c-0.02,0.04-0.04,0.09-0.05,0.14
			c-0.03,0.08-0.05,0.15-0.07,0.23c-0.01,0.05-0.02,0.09-0.02,0.14c-0.01,0.09-0.02,0.17-0.03,0.26c0,0.02-0.01,0.04-0.01,0.05
			v57.34c0,1.9-0.76,3.6-2.01,4.84c-1.25,1.24-2.94,2-4.84,2.01l-0.22,0.01l-0.01,0c-0.33,0.03-7.77,0.76-53.16,3.09
			c4.14-19.68,5.51-32.85,5.51-42.57c0-9.48-1.31-15.71-2.51-21.26c-0.3-1.38-0.44-2.75-0.44-4.02c0-1.31,0.16-2.53,0.45-3.55
			c0.29-1.02,0.72-1.84,1.2-2.41c0.72-0.84,0.61-2.1-0.23-2.82c-0.84-0.72-2.1-0.61-2.82,0.23v0c-0.94,1.11-1.59,2.45-2,3.9
			c-0.41,1.45-0.6,3.02-0.61,4.65c0,1.58,0.18,3.22,0.53,4.86c1.19,5.55,2.42,11.3,2.42,20.42c0,8.85-1.19,20.91-4.81,38.74
			c-0.92-0.46-1.73-1.1-2.36-1.91c-0.91-1.17-1.45-2.62-1.45-4.22v-1.96c0-1.1-0.9-2-2-2c-1.1,0-2,0.9-2,2v1.96
			c0,2.52,0.86,4.85,2.31,6.69c1.2,1.54,2.82,2.73,4.68,3.44c-0.11,0.51-0.21,1.01-0.32,1.53c-0.13,0.61,0.03,1.25,0.43,1.72
			c0.4,0.47,1.01,0.73,1.63,0.7c24.64-1.26,38.62-2.07,46.44-2.56c7.6-0.48,9.38-0.66,9.54-0.68c5.9-0.11,10.65-4.91,10.65-10.84
			V114.6C130.95,112.2,135.44,109.04,139.84,104.89z"
          />
          <polygon
            style={base}
            points="152.17,88.92 152.17,88.92 152.17,88.92 		"
          />

          <ellipse
            transform="matrix(0.094 -0.9956 0.9956 0.094 3.128 159.4793)"
            style={base}
            cx="89.19"
            cy="78.02"
            rx="17.09"
            ry="17.09"
          />
          <path
            style={base}
            d="M72.38,158.1c-1.11,0-2,0.9-2,2c0,1.11,0.9,2,2,2c1.11,0,2-0.9,2-2C74.39,159,73.49,158.1,72.38,158.1z"
          />
          <path
            style={base}
            d="M75.83,150.26c0-1.11-0.9-2-2-2c-1.11,0-2,0.9-2,2c0,1.11,0.9,2,2,2C74.93,152.26,75.83,151.37,75.83,150.26z
			"
          />
          <path
            style={base}
            d="M77.27,140.42c0-1.11-0.9-2-2-2c-1.11,0-2,0.9-2,2c0,1.11,0.9,2,2,2C76.37,142.42,77.27,141.52,77.27,140.42z
			"
          />
          <path
            style={base}
            d="M75.26,128.57c-1.11,0-2,0.9-2,2c0,1.11,0.9,2,2,2c1.11,0,2-0.9,2-2C77.27,129.47,76.37,128.57,75.26,128.57z
			"
          />
          <path
            style={base}
            d="M73.82,122.73c1.11,0,2-0.9,2-2c0-1.11-0.9-2-2-2c-1.11,0-2,0.9-2,2C71.82,121.84,72.72,122.73,73.82,122.73z
			"
          />
          <path
            style={base}
            d="M69.87,110.89c0,1.11,0.9,2,2,2c1.11,0,2-0.9,2-2c0-1.11-0.9-2-2-2C70.76,108.88,69.87,109.78,69.87,110.89z"
          />
          <path
            style={base}
            d="M79.33,97.52c0,1.11,0.9,2,2,2c1.11,0,2-0.9,2-2c0-1.11-0.9-2-2-2C80.23,95.51,79.33,96.41,79.33,97.52z"
          />
          <path
            style={base}
            d="M69.87,103.04c1.11,0,2-0.9,2-2c0-1.11-0.9-2-2-2c-1.11,0-2,0.9-2,2C67.86,102.15,68.76,103.04,69.87,103.04z
			"
          />
          <path
            style={base}
            d="M78.01,100.68c0-1.11-0.9-2-2-2c-1.11,0-2,0.9-2,2c0,1.11,0.9,2,2,2C77.11,102.68,78.01,101.79,78.01,100.68z
			"
          />
          <path
            style={base}
            d="M55.51,158.63c0-1.1-0.9-2-2-2c-1.1,0-2,0.9-2,2v2.61h4V158.63z"
          />
        </g>
        <g>
          <path
            style={highlight}
            d="M511.07,137.01c2.96-3.08,4.69-7.23,4.69-12.28c0.01-6.98-2.8-11.79-7.27-14.8
			c-4.47-3.02-10.56-4.26-17.17-4.27h-22.04v68.46h10v-0.25V143.9h9.78l17.28,30.24h12.11l-19.05-31.27
			C504.13,142,508.18,140.02,511.07,137.01z M479.28,135.43v-21.2h11.37c4.1,0,7.88,0.65,10.6,2.28c2.72,1.64,4.41,4.21,4.41,8.22
			c-0.01,4.01-1.7,6.63-4.5,8.32c-2.8,1.68-6.75,2.38-11.19,2.38H479.28z"
          />
          <polygon
            style={highlight}
            points="550.48,149.89 567.09,128.13 555.9,128.13 544.79,143.6 533.79,128.13 522.01,128.13 538.81,149.98
			520.38,174.13 531.87,174.13 544.6,156.55 557.14,174.13 569.01,174.13 		"
          />
          <path
            style={highlight}
            d="M221.37,149.45c-1.08,0-1.95,0.87-1.95,1.95c0,1.08,0.87,1.95,1.95,1.95c1.08,0,1.95-0.87,1.95-1.95
			C223.33,150.32,222.45,149.45,221.37,149.45z"
          />
          <circle style={highlight} cx="228.62" cy="151.39" r="1.95" />
          <path
            style={highlight}
            d="M235.88,149.44c-1.08,0-1.95,0.87-1.95,1.95c0,1.08,0.87,1.95,1.95,1.95c1.08,0,1.95-0.87,1.95-1.95
			C237.84,150.31,236.96,149.44,235.88,149.44z"
          />
          <path
            style={base}
            d="M194.04,159.14l-0.14,0.2c-4.65,6.93-12.05,9.97-19.76,9.97c-17.32,0-27.96-13.68-27.97-29.41
			c0.01-15.73,10.55-29.41,27.97-29.41c8.55,0,15.3,4.48,18.13,8.81l0.14,0.22l6.17-4.53l-0.14-0.2
			c-4.77-6.71-14.34-10.85-24.39-10.86c-10.19,0-19.11,3.79-25.48,10.19c-6.37,6.39-10.19,15.39-10.19,25.77
			c0,10.38,3.74,19.37,10.08,25.77c6.34,6.4,15.26,10.19,25.59,10.19c12.27,0,21.09-5.9,25.65-12.78l0.14-0.21l-0.21-0.14
			L194.04,159.14z"
          />
          <path
            style={base}
            d="M282.62,127.56c-6.22,0.01-12.58,3.45-15.31,8.46c-0.03-1.98-0.2-4.91-0.38-7.08l-0.02-0.23h-0.23h-6.51
			l0.03,0.27c0.29,3.06,0.38,7.47,0.38,9.86v35.29h7.03v-23.29c0-5.95,1.67-10.28,4.16-13.12c2.5-2.84,5.83-4.2,9.22-4.2
			c4.65,0.01,7.59,1.55,9.42,4.23c1.83,2.69,2.53,6.58,2.52,11.26v25.11h7.03v-27.8c0-5.61-1.43-10.3-4.32-13.6
			C292.74,129.43,288.38,127.55,282.62,127.56z"
          />
          <path
            style={base}
            d="M343.57,163.46c-2.56,3.4-6.4,5.75-11.99,5.76c-5.17,0-9.37-1.94-12.28-5.13c-2.92-3.19-4.55-7.64-4.55-12.67
			c0-5.08,1.66-9.55,4.6-12.75c2.94-3.2,7.16-5.14,12.33-5.14c4.81,0,9.25,2.46,11.31,5.83l0.14,0.23l0.22-0.16l5.29-3.8l-0.15-0.2
			c-3.9-5.26-10.79-7.87-16.9-7.88c-7.31,0-13.38,2.54-17.61,6.81c-4.24,4.26-6.64,10.23-6.64,17.06c0,6.83,2.4,12.8,6.64,17.06
			c4.24,4.26,10.31,6.81,17.61,6.81c7.84,0,13.4-3.11,17.28-7.87l0.17-0.2l-5.3-3.95L343.57,163.46z"
          />
          <path
            style={base}
            d="M376.32,127.56c-6.45,0-12.18,2.55-16.29,6.81c-4.11,4.26-6.61,10.23-6.61,17.06c0,6.83,2.5,12.8,6.68,17.06
			c4.19,4.26,10.06,6.81,16.8,6.81c7.45,0,14.17-2.82,19.49-9.6l0.15-0.2l-5.31-4.17l-0.15,0.24c-2.45,3.96-7.56,7.66-14.19,7.66
			c-8.99,0-15.83-7.04-16.15-15.82h37.21l0.02-0.23c0.1-1.16,0.1-2.23,0.1-3.09c0-6.19-1.86-11.82-5.52-15.91
			C388.88,130.08,383.43,127.55,376.32,127.56z M360.85,147.72c0.7-7.45,6.97-14.19,15.46-14.19c4.6,0,8.15,1.54,10.61,4.1
			c2.41,2.51,3.77,6.02,4,10.09H360.85z"
          />
          <path
            style={base}
            d="M424.99,127.56c-6.22,0-11.69,3.46-14.35,8.45c-0.03-1.98-0.2-4.9-0.38-7.07l-0.02-0.23h-6.74l0.03,0.27
			c0.29,3.06,0.38,7.47,0.38,9.86v35.29h7.03v-23.29c0-5.96,1.55-10.14,3.92-12.83c2.37-2.69,5.58-3.91,8.98-3.91
			c0.85,0,1.8,0.07,2.63,0.18c0.83,0.11,1.54,0.25,1.89,0.38l0.29,0.11l0.94-6.67l-0.21-0.06
			C428,127.65,426.63,127.56,424.99,127.56z"
          />
          <path
            style={base}
            d="M461.6,167.06l-0.34,0.17c-1.69,0.85-3.78,1.41-5.55,1.41c-1.94,0-3.54-0.38-4.66-1.53
			c-1.12-1.15-1.81-3.14-1.81-6.47v-25.96h12.67v-0.25v-5.72h-12.67v-12.67h-7.03v12.67l-9.31,0v5.97h9.31l0,27.78
			c0,5,1.71,8.09,4.06,9.91c2.34,1.81,5.28,2.34,7.71,2.34c3,0,5.44-0.49,7.77-1.36l0.17-0.06L461.6,167.06z"
          />
          <path
            style={base}
            d="M240.84,127.75h-24.42c-3.25,0-6.22,1.33-8.34,3.45c-2.13,2.12-3.46,5.09-3.45,8.34v23.71
			c0,3.25,1.33,6.22,3.45,8.34c2.12,2.13,5.09,3.46,8.34,3.45h24.42c3.25,0,6.22-1.33,8.34-3.45c2.13-2.12,3.46-5.09,3.45-8.34
			v-23.71c0-3.25-1.33-6.22-3.45-8.34C247.06,129.07,244.09,127.75,240.84,127.75z M245.63,163.25c0,1.34-0.53,2.51-1.4,3.39
			c-0.88,0.87-2.06,1.4-3.39,1.4h-24.42c-1.34,0-2.51-0.53-3.39-1.4c-0.87-0.88-1.4-2.06-1.4-3.39v-23.71c0-1.34,0.53-2.51,1.4-3.39
			c0.88-0.87,2.06-1.4,3.39-1.4h24.42c1.34,0,2.51,0.53,3.39,1.4c0.87,0.88,1.4,2.06,1.4,3.39V163.25z"
          />
        </g>
      </g>
    </svg>
  );
};

export default Logo;
