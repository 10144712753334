import { classNames } from "../../utils/helpers";

const TimerUnitSelector = ({
  units = ["mins", "hours", "days"],
  value,
  setValue,
  disabled = false,
}) => {
  const onClick = unit => {
    setValue(unit);
  };

  return (
    <div className="flex w-full space-x-3">
      {units.map(unit => {
        const active = unit === value;

        return (
          <button
            key={unit}
            disabled={disabled}
            type="button"
            className={classNames(
              "flex w-full flex-1 items-center justify-center rounded border capitalize disabled:opacity-50",
              active &&
                "border-blue-600 bg-blue-100 text-blue-600 outline outline-1 outline-blue-600"
            )}
            onClick={() => onClick(unit)}
          >
            {unit}
          </button>
        );
      })}
    </div>
  );
};

export default TimerUnitSelector;
