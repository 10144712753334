import { useForm } from "react-hook-form";
import { useMutation, useQuery, useQueryClient } from "react-query";
import StoreCredit from "../components/StoreCredit";
import {
  getOrCreateStoreCredit,
  getStorePaymentMethods,
} from "../utils/queries";
import { toast } from "react-toastify";
import { buyStoreCredit, updateStoreCredit } from "../utils/mutations";
import { classNames, currencyFormatter } from "../utils/helpers";
import Alert from "../components/Alert";
import { ArrowRightIcon } from "@heroicons/react/solid";
import { CREDIT_PRICE } from "../utils/constants";
import { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";

const BuyCredits = () => {
  const location = useLocation();

  const params = new URLSearchParams(location.search);

  const status = params.get("status");

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm({
    defaultValues: {
      amount: 0,
    },
    shouldUnregister: true,
  });

  const selectedAmount = parseInt(watch("amount")) || 0;

  const { mutate, isLoading } = useMutation(buyStoreCredit, {
    onSuccess: data => {
      window.location.href = data.sessionUrl;
    },
    onError: () => {
      toast.error("Something went wrong");
    },
  });

  const onSubmit = data => {
    mutate({
      data: {
        quantity: parseInt(data.amount),
      },
    });
  };

  const isDisabled = isLoading;

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {status === "success" && (
        <Alert type="success" content="Payment Successfull" />
      )}
      {status === "cancel" && (
        <Alert type="error" content="Payment Cancelled" />
      )}
      <h3 className="mt-4 w-full text-lg font-medium text-slate-700">
        Buy Credits
      </h3>
      <p className="mb-1 mt-2 inline-block text-sm text-gray-500">
        Choose an amount below to buy credits.
      </p>
      <div className="grid grid-cols-1 gap-x-4 md:grid-cols-2">
        <div className="form-group">
          <select
            className={`${classNames(errors.amount && "form-error")}`}
            name="amount"
            placeholder="Select Amount..."
            disabled={isDisabled}
            {...register("amount", {
              validate: value => {
                return parseInt(value) > 0 || "Please select an amount to buy";
              },
            })}
          >
            <option value={0}>Select Amount</option>
            <option value={200}>
              {currencyFormatter.format(CREDIT_PRICE * 200)} (200 Credits)
            </option>
            <option value={500}>
              {currencyFormatter.format(CREDIT_PRICE * 500)} (500 Credits)
            </option>
            <option value={1000}>
              {currencyFormatter.format(CREDIT_PRICE * 1000)} (1000 Credits)
            </option>
          </select>
          {errors.amount && (
            <small className="form-error-text">{errors.amount.message}</small>
          )}
        </div>
        <button
          className="btn-primary mr-auto flex min-w-[120px] items-center self-start"
          disabled={isDisabled || selectedAmount === 0}
        >
          Proceed to Checkout <ArrowRightIcon className="ml-2 h-4 w-4" />
        </button>
      </div>
    </form>
  );
};

const AutoTopUp = () => {
  const queryClient = useQueryClient();

  const { data } = useQuery(["/store/credit"], getOrCreateStoreCredit, {
    retry: false,
  });

  const { data: pData } = useQuery(
    ["/store/payment-method"],
    getStorePaymentMethods,
    {
      retry: false,
    }
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
  } = useForm({
    defaultValues: {
      autoTopUp: 0,
    },
    shouldUnregister: true,
  });

  useEffect(() => {
    if (data?.credit?.autoTopUp) {
      setValue("autoTopUp", parseInt(data?.credit?.autoTopUp));
    }
  }, [data?.credit?.autoTopUp, setValue]);

  const selectedAutoTopUp = parseInt(watch("autoTopUp")) || 0;

  const { mutate, isLoading } = useMutation(updateStoreCredit, {
    onSuccess: () => {
      queryClient.refetchQueries(["/store/credit"]);
      toast.success("Details updated");
    },
    onError: () => {
      toast.error("Error updating details");
    },
  });

  const onSubmit = data => {
    mutate({
      data: {
        autoTopUp: parseInt(data.autoTopUp),
      },
    });
  };

  const isDisabled = isLoading;

  if (!data) {
    return null;
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <h3 className="mt-4 w-full text-lg font-medium text-slate-700">
        Auto TopUp
      </h3>
      {pData && pData.paymentMethods.length === 0 && (
        <div className="mt-2">
          <Alert
            type="warning"
            content={
              <p>
                AutoTopUp will not work until you{" "}
                <Link className="underline" to="/settings/payment-methods">
                  add a payment method
                </Link>
                .
              </p>
            }
          />
        </div>
      )}
      <p className="mb-1 mt-2 inline-block text-sm text-gray-500">
        This feature automatically charges your card to top up your store
        credits every time your balance drops below 20 credits. We recommend
        turning this feature on to avoid messages not being sent.
      </p>
      <div className="grid grid-cols-1 gap-x-4 md:grid-cols-2">
        <div className="form-group">
          <select
            className={`${classNames(errors.autoTopUp && "form-error")}`}
            name="autoTopUp"
            placeholder="Select Amount..."
            disabled={isDisabled}
            {...register("autoTopUp", {})}
          >
            <option value={0}>Disable AutoTopUp</option>
            <option value={200}>
              {currencyFormatter.format(CREDIT_PRICE * 200)} (200 Credits)
            </option>
            <option value={500}>
              {currencyFormatter.format(CREDIT_PRICE * 500)} (500 Credits)
            </option>
            <option value={1000}>
              {currencyFormatter.format(CREDIT_PRICE * 1000)} (1000 Credits)
            </option>
          </select>
          {errors.utcOffset && (
            <small className="form-error-text">
              {errors.utcOffset.message}
            </small>
          )}
        </div>
        <div className="self-start">
          <Alert
            type={selectedAutoTopUp === 0 ? "warning" : "info"}
            content={
              selectedAutoTopUp === 0
                ? "Auto TopUp is disabled"
                : `Auto TopUp is enabled`
            }
          />
        </div>
      </div>
      <button
        className="btn-primary ml-auto mb-10 mt-2 block min-w-[120px]"
        disabled={isDisabled}
      >
        Save
      </button>
    </form>
  );
};

const CreditsPage = () => {
  return (
    <div className="w-full">
      <div className="flex flex-col items-start sm:flex-row sm:items-end sm:justify-between">
        <div>
          <h2 className="w-full text-xl font-semibold text-slate-700">
            Credits
          </h2>
          <p className="text-xs text-slate-600">
            Add credits and configure auto top up balance.
          </p>
        </div>
        <div className="mt-2">
          <StoreCredit linkToCredits={false} />
        </div>
      </div>
      <hr className="my-4" />
      <AutoTopUp />
      <BuyCredits />
      <small className="block max-w-prose whitespace-pre-wrap text-xs text-slate-400">
        <ol className="ml-4 list-decimal">
          <li>Pricing is subject to change.</li>
          <li>Transactions are processed in Australian Dollars (AUD).</li>
          <li>
            We use Stripe to process your payment. It's the same payment
            provider used by products such as Twitter, Pinterest, and Lyft. We
            do not handle your credit card information directly.
          </li>
        </ol>
      </small>
    </div>
  );
};

export default CreditsPage;
