import { strapiAPI } from "./api";

export const getOrCreateStore = async () => {
  const response = await strapiAPI.get("/custom/store");
  return response.data;
};

export const getStoreUsers = async () => {
  const response = await strapiAPI.get(`/custom/store/users`);
  return response.data;
};

export const getStorePaymentMethods = async () => {
  const response = await strapiAPI.get(`/custom/store/payment-method`);
  return response.data;
};

export const getOrCreateStoreCredit = async () => {
  const response = await strapiAPI.get(`/custom/store/credit`);
  return response.data;
};

export const getBillingPortal = async () => {
  const response = await strapiAPI.get(`/custom/store/billing-portal`);
  return response.data;
};

export const getSubscriptionSession = async () => {
  const response = await strapiAPI.get(`/custom/store/subscription`);
  return response.data;
};

export const getStore = async () => {
  const response = await strapiAPI.get("/custom/get-store");
  return response.data;
};

export const findAllBoards = async () => {
  const response = await strapiAPI.get("/boards");
  return response.data;
};

export const findBoardById = async ({ queryKey }) => {
  const [, { boardId }] = queryKey;
  const response = await strapiAPI.get(`/boards/${boardId}`);
  return response.data;
};

export const getCompounding = async () => {
  const response = await strapiAPI.get(`/compounding`);
  return response.data;
};
