import axios from "axios";
import { getCookie } from "react-use-cookie";
import { API_URL, PRINT_NODE_API_KEY, PRINT_NODE_URL } from "./constants";
import { toast } from "react-toastify";

export const strapiAPI = axios.create({
  baseURL: `${API_URL}/api`,
  headers: { "Content-Type": "application/json" },
});

strapiAPI.interceptors.request.use(async extendedConfig => {
  const config = Object.assign({}, extendedConfig);

  const accessToken = getCookie("crt_token") || null;

  if (!config.headers.Authorization) {
    config.headers.Authorization = accessToken && `Bearer ${accessToken}`;
  }

  return config;
});

strapiAPI.interceptors.response.use(
  response => response,
  async error => {
    console.error(error);
    const accessToken = getCookie("crt_token") || null;
    if (error && error.response && error.response.status === 401) {
      // const accessToken = getCookie("crt_token") || null;
      // error.config.headers["Authorization"] = `Bearer ${accessToken}`;
      // return strapiAPI.request(error.config);
    } else if (error && error.response && error.response.status === 403) {
      // IP Login
      if (!accessToken) {
        toast.info(
          "You are not authorized to access this resource. Click here to Login.",
          {
            toastId: "unauthorized",
            autoClose: 10000,
            onClick: () => {
              window.location.href = `${API_URL}/api/connect/auth0`;
            },
          }
        );
      }
    }
    return Promise.reject(error);
  }
);

export const printNodeAPI = axios.create({
  baseURL: `${PRINT_NODE_URL}`,
  headers: { "Content-Type": "application/json" },
  auth: {
    username: PRINT_NODE_API_KEY,
  },
});
