export const classNames = (...classes) => {
  return classes.filter(Boolean).join(" ");
};

export const getTimerInMinutes = (timer, unit) => {
  switch (unit) {
    case "mins":
      return timer;
    case "hours":
      return timer * 60;
    case "days":
      return timer * 60 * 24;
    default:
      return timer;
  }
};

export const getUnitFromTimer = timer => {
  if (!timer) return "mins";

  if (timer % (60 * 24) === 0) {
    return "days";
  } else if (timer % 60 === 0) {
    return "hours";
  } else {
    return "mins";
  }
};

export const getTimerInUnit = timer => {
  if (!timer) return 0;

  if (timer % (60 * 24) === 0) {
    // days
    return timer / (60 * 24);
  } else if (timer % 60 === 0) {
    // hours
    return timer / 60;
  } else {
    // mins
    return timer;
  }
};

export const rgbToHex = (r, g, b) =>
  "#" +
  [r, g, b]
    .map(x => {
      const hex = x.toString(16);
      return hex.length === 1 ? "0" + hex : hex;
    })
    .join("");

export const hexToRgb = hex =>
  hex
    .replace(
      /^#?([a-f\d])([a-f\d])([a-f\d])$/i,
      (m, r, g, b) => "#" + r + r + g + g + b + b
    )
    .substring(1)
    .match(/.{2}/g)
    .map(x => parseInt(x, 16));

export const textClassName = bgHex => {
  if (!bgHex) {
    return "text-gray-800";
  }
  const bgRgb = hexToRgb(bgHex);

  return (bgRgb[0] * 299 + bgRgb[1] * 587 + bgRgb[2] * 114) / 1000 > 125
    ? "text-gray-800"
    : "text-white";
};

export const textColor = bgHex => {
  if (!bgHex) {
    return "rgb(31 41 55)";
  }
  const bgRgb = hexToRgb(bgHex);

  return (bgRgb[0] * 299 + bgRgb[1] * 587 + bgRgb[2] * 114) / 1000 > 125
    ? "rgb(31 41 55)"
    : "white";
};

export const blobToBase64 = blob => {
  return new Promise((resolve, _) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.readAsDataURL(blob);
  });
};

export const getRandomPin = (chars = "0123456789", len = 4) =>
  [...Array(len)]
    .map(i => chars[Math.floor(Math.random() * chars.length)])
    .join("");

export const currencyFormatter = Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "AUD",
  minimumFractionDigits: 2,
});

export const getBgClassName = string => {
  const bg = [
    "blob1",
    "blob2",
    "blob3",
    "blob4",
    "blob5",
    "blob6",
    "blob7",
    "blob8",
    "blob9",
    "blob10",
    "blob11",
    "blob12",
    "blob13",
    "blob14",
  ];

  let hash = 0;
  if (!string || string.length === 0) return hash;
  for (let i = 0; i < string.length; i++) {
    hash = string + ((hash << 5) - hash);
    hash = hash & hash;
  }
  hash = ((hash % bg.length) + bg.length) % bg.length;
  return bg[hash];
};

export const getCurrencySymbol = currency => {
  switch (currency) {
    case "USD":
      return "$";
    case "AUD":
      return "$";
    case "EUR":
      return "€";
    case "GBP":
      return "£";
    default:
      return "$";
  }
};
