import { XIcon } from "@heroicons/react/outline";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import useBoolean from "../hooks/useBoolean";
import { archiveContentType } from "../utils/mutations";
import ConfirmDialog from "./dialogs/ConfirmDialog";

const CONTENT_TYPES = {
  board: { path: "boards", display: "Conductor" },
  column: { path: "columns", display: "Column" },
  card: { path: "cards", display: "Card" },
  compounding: {
    path: "compounding-cards",
    display: "Card",
  },
};

const ArchiveButton = ({
  onClick,
  disabled,
  className = "btn-danger",
  onSuccess,
  contentType,
  contentId,
  children = "Archive",
}) => {
  const [dialogOpen, setDialogOpen] = useBoolean(false);

  const { mutate, isLoading } = useMutation(archiveContentType, {
    onSuccess: (data, variables) => {
      if (onSuccess) {
        onSuccess(data, variables);
      }
      setDialogOpen.off();
      toast.success(`Archived ${CONTENT_TYPES[contentType].display}`);
    },
    onError: () => {
      toast.error(`Error archiving ${CONTENT_TYPES[contentType].display}`);
    },
  });

  const handleClick = () => {
    if (onClick) {
      onClick();
    }
    setDialogOpen.on();
  };

  const handleCancel = () => {
    setDialogOpen.off();
  };

  const handleConfirm = () => {
    mutate({
      contentId: contentId,
      contentTypePath: CONTENT_TYPES[contentType].path,
    });
  };

  return (
    <>
      <button
        type="button"
        disabled={disabled}
        className={className}
        onClick={handleClick}
      >
        {children}
      </button>
      <ConfirmDialog
        open={dialogOpen}
        onCancel={handleCancel}
        onConfirm={handleConfirm}
        icon={
          <span className="flex h-28 w-28 items-center justify-center rounded-full bg-red-100">
            <XIcon className="h-16 w-16 text-red-500" />
          </span>
        }
        title={`Are you sure you want to archive this ${CONTENT_TYPES[contentType].display}?`}
        subtitle="Once completed, this action cannot be reversed."
        confirmBtnClassName="btn-danger"
        cancelBtnClassName="btn-primary-outline"
        confirmText="Yes, Archive"
        cancelText="No, Cancel"
        disabled={isLoading}
      />
    </>
  );
};

export default ArchiveButton;
