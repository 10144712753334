export const API_URL = process.env.REACT_APP_API_URL;

export const AUTH0_DOMAIN = process.env.REACT_APP_AUTH0_DOMAIN;
export const AUTH0_CLIENT_ID = process.env.REACT_APP_AUTH0_CLIENT_ID;

export const PRINT_NODE_URL = process.env.REACT_APP_PRINT_NODE_URL;
export const PRINT_NODE_API_KEY = process.env.REACT_APP_PRINT_NODE_API_KEY;

export const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

export const CARD_COLORS = [
  "#7BC86C",
  "#F5DD29",
  "#FFAF3F",
  "#EF7548",
  "#CD8DE5",
  "#5BA4CF",
  "#29CCE5",
  "#6DECA9",
  "#FF8ED4",
  "#172B4D",
];

export const BOARD_COLORS = [
  "#519839",
  "#0079BF",
  "#D29034",
  "#B04632",
  "#89609E",
  "#CD5A91",
  "#4BBF6B",
  "#00AECC",
  "#838C91",
  "#334663",
];

// In AUD
export const CREDIT_PRICE = 0.1;
