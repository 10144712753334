import { StarIcon } from "@heroicons/react/outline";
import { StarIcon as StarIconSolid } from "@heroicons/react/solid";
import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import { updateBoard } from "../utils/mutations";
import Spinner from "./Spinner";

const PinBoardButton = ({ board }) => {
  const queryClient = useQueryClient();

  const { mutate, isLoading } = useMutation(updateBoard, {
    onSuccess: data => {
      queryClient.setQueryData(
        ["/boards", { boardId: `${board.id}` }],
        oldData => {
          if (!oldData) {
            return oldData;
          }

          return {
            ...oldData,
            data: {
              ...oldData.data,
              attributes: {
                ...oldData.data.attributes,
                pinned: !oldData.data.attributes.pinned,
              },
            },
          };
        },
        {
          exact: true,
        }
      );
      queryClient.setQueryData(["/boards"], oldData => {
        if (!oldData) {
          return oldData;
        }

        return {
          ...oldData,
          data: oldData.data.map(b => {
            if (b.id === board.id) {
              return {
                ...b,
                attributes: {
                  ...b.attributes,
                  pinned: !b.attributes.pinned,
                },
              };
            }
            return b;
          }),
        };
      });
      toast.success(
        `${data.data.attributes.pinned ? "Pinned" : "Unpinned"} conductor`
      );
    },
    onError: (_, { data }) => {
      toast.error(`Error ${data.pinned ? "pinning" : "unpinning"} conductor`);
    },
  });

  const onClick = e => {
    e.preventDefault();
    e.stopPropagation();

    mutate({
      data: {
        pinned: !board.attributes.pinned,
      },
      boardId: board.id,
    });
  };

  if (isLoading) {
    return (
      <button className="-mr-2 flex h-6 w-6 min-w-[1.5rem] items-center justify-center rounded p-1">
        <Spinner />
      </button>
    );
  }

  return (
    <button
      className="-mr-2 flex h-6 w-6 min-w-[1.5rem] items-center justify-center rounded p-1 transition-colors hover:bg-black/5"
      onClick={onClick}
    >
      {board.attributes.pinned ? (
        <StarIconSolid className="h-4 w-4 text-amber-400" />
      ) : (
        <StarIcon className="h-4 w-4" />
      )}
    </button>
  );
};

export default PinBoardButton;
