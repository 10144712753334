const IntegrationsPage = () => {
  return (
    <div className="w-full">
      <h2 className="w-full text-xl font-semibold text-slate-700">
        Integrations
      </h2>
      <p className="text-xs text-slate-500">
        Add integrations like AUS Post, Print Node and many more. Coming Soon.
      </p>
      <hr className="my-4" />
      <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
        <div className="rounded border border-gray-100 p-4">
          <img className="h-6 w-auto" src="/printnode.png" alt="PrintNode" />
          <p className="mt-2 text-sm text-slate-600">
            Print Labels for drugs buy moving cards across columns.
          </p>
        </div>
        <div className="rounded border border-gray-100 p-4">
          <img className="h-6 w-auto" src="/auspost.png" alt="AUS Post" />
          <p className="mt-2 text-sm text-slate-600">
            Easily dispatch e-parcels with AUS Post, all within ConcertRx.
          </p>
        </div>
        <div className="rounded border border-gray-100 p-4">
          <img className="h-6 w-auto" src="/twilio.png" alt="Twilio" />
          <p className="mt-2 text-sm text-slate-600">
            Get an extra number and enable two-way communication with your
            customers.
          </p>
        </div>
        <div className="rounded border border-gray-100 p-4">
          <p className="h-6 text-xl font-bold">And More...</p>
          <p className="mt-2 text-sm text-slate-600">
            Coming soon to ConcertRx.
          </p>
        </div>
      </div>
    </div>
  );
};

export default IntegrationsPage;
