import { printNodeAPI, strapiAPI } from "./api";

export const updateStore = async ({ data }) => {
  const response = await strapiAPI.put(`/custom/store`, { data });
  return response.data;
};

export const updateStoreCredit = async ({ data }) => {
  const response = await strapiAPI.put(`/custom/store/credit`, { data });
  return response.data;
};

export const buyStoreCredit = async ({ data }) => {
  const response = await strapiAPI.post(`/custom/store/credit`, { data });
  return response.data;
};

export const attachStorePaymentMethod = async () => {
  const response = await strapiAPI.post(`/custom/store/payment-method`);
  return response.data;
};

export const detachStorePaymentMethod = async ({ paymentMethodId }) => {
  const response = await strapiAPI.delete(
    `/custom/store/payment-method/${paymentMethodId}`
  );
  return response.data;
};

export const createBoard = async ({ data }) => {
  const response = await strapiAPI.post(`/boards`, { data });
  return response.data;
};

export const createColumn = async ({ data }) => {
  const response = await strapiAPI.post(`/columns`, { data });
  return response.data;
};

export const createCard = async ({ data }) => {
  const response = await strapiAPI.post(`/cards`, { data });
  return response.data;
};

export const bulkCreateCards = async ({ data }) => {
  const response = await strapiAPI.post(`/custom/bluk-create-cards`, { data });
  return response.data;
};

export const updateBoard = async ({ data, boardId }) => {
  const response = await strapiAPI.put(`/boards/${boardId}`, { data });
  return response.data;
};

export const updateColumn = async ({ data, columnId }) => {
  const response = await strapiAPI.put(`/columns/${columnId}`, { data });
  return response.data;
};

export const updateCard = async ({ data, cardId }) => {
  const response = await strapiAPI.put(`/cards/${cardId}`, { data });
  return response.data;
};

export const archiveContentType = async ({ contentTypePath, contentId }) => {
  const response = await strapiAPI.delete(`/${contentTypePath}/${contentId}`);
  return response.data;
};

export const moveCard = async ({ data }) => {
  const response = await strapiAPI.put(`/custom/move-card`, {
    data,
  });
  return response.data;
};

export const moveCardv2 = async ({ data }) => {
  const response = await strapiAPI.put(`/custom/v2/move-card`, {
    data,
  });
  return response.data;
};

export const duplicateBoard = async ({ data }) => {
  const response = await strapiAPI.post(`/custom/duplicate-board`, {
    data,
  });
  return response.data;
};

export const createPrintJob = async ({ data }) => {
  const response = await printNodeAPI.post(`/printjobs`, {
    printerId: data.printerId,
    contentType: data.contentType,
    content: data.content,
    title: data.title,
    source: "ConcertRx",
  });
  return response.data;
};

export const validateAddress = async ({ address }) => {
  const response = await strapiAPI.get(
    `/custom/validate-address?address=${address}`
  );
  return response.data;
};

export const lodgeParcel = async ({ data }) => {
  const response = await strapiAPI.post(`/custom/lodge-parcel`, {
    ...data,
  });
  return response.data;
};
