import dayjs from "dayjs";
import { useQuery } from "react-query";
import Spinner from "../components/Spinner";
import { classNames } from "../utils/helpers";
import { getStoreUsers } from "../utils/queries";

const UsersPage = () => {
  const { data, isLoading } = useQuery(["/store/users"], getStoreUsers, {
    retry: false,
  });

  return (
    <div className="w-full">
      <h2 className="w-full text-xl font-semibold text-slate-700">Users</h2>
      <p className="text-xs text-slate-500">Manage your team.</p>
      <hr className="my-4" />
      {isLoading ? (
        <Spinner />
      ) : (
        <div className="rounded border border-gray-100">
          <table className="w-full table-auto border-collapse text-left text-sm">
            <thead className="">
              <tr>
                <th className="px-4 py-2 font-medium">Email</th>
                <th className="px-4 py-2 font-medium">Role</th>
                <th className="px-4 py-2 font-medium">Created</th>
              </tr>
            </thead>
            <tbody>
              {data.users.map(profile => {
                return (
                  <tr key={profile.id}>
                    <td className="border-t border-gray-100 px-4 py-3 text-slate-700">
                      {profile.user.email}
                    </td>
                    <td className="border-t border-gray-100 px-4 py-3 text-slate-700">
                      <span
                        className={classNames(
                          "rounded-sm border px-1 py-0.5 text-xs uppercase",
                          profile.role === "admin"
                            ? "border-green-500 bg-green-100 text-green-700"
                            : "border-blue-500 bg-blue-100 text-blue-700"
                        )}
                      >
                        {profile.role}
                      </span>
                    </td>
                    <td className="border-t border-gray-100 px-4 py-3 text-slate-700">
                      {dayjs(profile.user.createdAt).format("MMM DD, YYYY")}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default UsersPage;
