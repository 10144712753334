import { Link, Navigate } from "react-router-dom";
import { HomeIcon } from "@heroicons/react/outline";
import { AUTH_STATUS, useAuth } from "../utils/auth";
import { useQuery } from "react-query";
import { getCompounding } from "../utils/queries";
import Spinner from "../components/Spinner";
import CompoundingKanban from "../components/CompoundingKanban";

export const COMPOUNDING_COLUMNS = [
  {
    id: "c0",
    status: "c0",
    position: 0,
    title: "Prescription Recieved",
    description: null,
    message:
      "THANK YOU for using the services of TerryWhite Chemmart Penrith Compounding. We are processing your order.",
    active: true,
    timer: 0,
    type: "default",
    cards: [],
    validStatuses: ["c1"],
  },
  {
    id: "c1",
    status: "c1",
    position: 1,
    title: "Create Payment",
    description: null,
    message: "",
    active: true,
    timer: 0,
    type: "payment",
    cards: [],
    validStatuses: ["c2"],
  },
  {
    id: "c2",
    status: "c2",
    position: 1,
    title: "Awaiting Payment",
    description: null,
    message:
      "Your order is ready to be PROCESSED. To continue, please use this link to check and pay for your order. We will let you know when your order will be ready. {{payment_link}}",
    active: true,
    timer: 0,
    type: "default",
    cards: [],
    validStatuses: ["c3"],
  },
  {
    id: "c3",
    status: "c3",
    position: 2,
    title: "Being Compounded",
    description: null,
    message:
      "Hi. We have STARTED preparing your order. We will let you know when it is ready for pickup or dispatch.",
    active: true,
    timer: 0,
    type: "default",
    cards: [],
    validStatuses: ["c4"],
  },
  {
    id: "c4",
    status: "c4",
    position: 3,
    title: "Ready for pickup",
    description: null,
    message:
      "Your order is READY for pickup. If you have any questions, please feel free to ask our pharmacists.",
    active: true,
    timer: 0,
    type: "default",
    cards: [],
    validStatuses: ["c6"],
  },
  {
    id: "c5",
    status: "c5",
    position: 4,
    title: "Ready for dispatch",
    description: null,
    message:
      "Your order is READY for dispatch. If you have any questions, please feel free to talk to our pharmacists.",
    active: true,
    timer: 0,
    type: "default",
    cards: [],
    validStatuses: ["c6"],
  },
  {
    id: "c6",
    status: "c6",
    position: 5,
    title: "How are you going?",
    description: null,
    message:
      "Hi. Hope you are doing well. Just touching base to check how you are going with the compounded medication we prepared for you. If you have any questions, please contact us at 47212422. Have a great day. ",
    active: true,
    timer: 0,
    type: "default",
    cards: [],
    validStatuses: ["c7"],
  },
  {
    id: "c7",
    status: "c7",
    position: 6,
    title: "Review our service",
    description: null,
    message:
      "THANK YOU for using our service. If you ever need help, you know where to come. Please kindly review our service! :-) https://g.page/r/CVEqTGZXCky3EAg/review",
    active: true,
    timer: 0,
    type: "default",
    cards: [],
    validStatuses: [],
  },
];

const CompoundingPage = () => {
  const { status, store, role } = useAuth();

  const { data, error } = useQuery(["/compounding"], getCompounding, {
    enabled: store?.compoundingEnabled && role === "admin",
  });

  if (status === AUTH_STATUS.LOADING) {
    return null;
  }

  if (!store?.compoundingEnabled || role !== "admin") {
    return <Navigate to="/" replace />;
  }

  if (data) {
    return (
      <>
        <div className="flex h-12 w-full items-center justify-between px-2">
          <div className="flex items-baseline overflow-clip">
            <Link
              to="/"
              className="-mr-2 flex h-6 w-6 min-w-[1.5rem] items-center justify-center rounded p-1 transition-colors hover:bg-black/5"
            >
              <HomeIcon className="h-4 w-4" />
            </Link>
            <h1 className="ml-3 whitespace-nowrap text-2xl">
              {data.data.attributes.title}
            </h1>
            <p className="ml-3 mr-3 w-full overflow-x-hidden text-ellipsis whitespace-nowrap text-sm opacity-80">
              {data.data.attributes.description}
            </p>
          </div>
        </div>
        <div className="w-full">
          <div className="h-compounding-board flex flex-1 flex-col">
            <CompoundingKanban data={data.data} />
          </div>
        </div>
      </>
    );
  }

  if (error) {
    return (
      <>
        <div className="p-4">Error</div>
      </>
    );
  }

  return (
    <>
      <div className="p-4">
        <Spinner />
      </div>
    </>
  );
};

export default CompoundingPage;
