import { useEffect, useState } from "react";

function useKeyPress({
  targetKey,
  modifiers = [],
  callback = () => {},
  disable = false,
}) {
  // State for keeping track of whether key is pressed
  const [keyPressed, setKeyPressed] = useState(false);
  // If pressed key is our target key then set to true
  function downHandler(e) {
    if (e.key === targetKey && modifiers.every(modifier => e[modifier])) {
      setKeyPressed(true);
      callback();
    }
  }
  // If released key is our target key then set to false
  const upHandler = ({ key }) => {
    if (key === targetKey) {
      setKeyPressed(false);
    }
  };
  // Add event listeners
  useEffect(() => {
    if (!disable) {
      window.addEventListener("keydown", downHandler);
      window.addEventListener("keyup", upHandler);
      // Remove event listeners on cleanup
      return () => {
        window.removeEventListener("keydown", downHandler);
        window.removeEventListener("keyup", upHandler);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callback, disable]);
  return keyPressed;
}

export default useKeyPress;
