import { classNames } from "../utils/helpers";
import { CheckIcon } from "@heroicons/react/outline";

const SubscriptionCard = ({
  isActive,
  title,
  description,
  price,
  features = [],
}) => {
  return (
    <div className="rounded border border-gray-100 p-4 md:p-5">
      <h3 className="mb-0.5 text-lg font-semibold text-slate-900">{title}</h3>
      <p className="mb-5 text-sm text-slate-600">{description}</p>
      <div className="mb-9 flex">
        <span className="mr-1 mt-0.5 text-base font-semibold text-slate-700 md:text-lg">
          $
        </span>
        <span className="text-4xl font-semibold text-slate-900 lg:text-5xl">
          {price}
        </span>
        <span className="self-end text-xs font-semibold text-slate-700 md:text-sm">
          {" "}
          / mo
        </span>
      </div>
      <div className="mb-5">
        <button
          disabled
          className={classNames(
            "w-full rounded p-3 text-center text-xs font-bold uppercase tracking-wider md:p-4 md:text-sm",
            isActive
              ? "bg-[#49B649] text-white"
              : "cursor-not-allowed bg-slate-100"
          )}
        >
          {isActive ? "Active" : "Coming Soon"}
        </button>
      </div>
      <ul>
        {features.map((feature, index) => (
          <li
            className="mb-3 flex items-center text-base font-medium text-slate-900"
            key={index}
          >
            <CheckIcon className="mr-2 h-5 w-5 text-slate-400" />
            <p className="text-sm md:text-base">{feature}</p>
          </li>
        ))}
      </ul>
    </div>
  );
};

const SubscriptionPage = () => {
  return (
    <div className="w-full">
      <h2 className="xw-full text-xl font-semibold text-slate-700">
        Subscription
      </h2>
      <p className="text-xs text-slate-500">
        Manage your pharmacy subscription.
      </p>
      <hr className="my-4" />
      <div className="grid grid-cols-1 gap-8 md:grid-cols-3">
        <SubscriptionCard
          isActive
          title="Standard"
          description=""
          price="29.95"
          features={[
            "Unlimited boards",
            "Free SENDER ID",
            "IP address login",
            "Buy SMS credits for $0.1 / credit",
            "GST included in pricing",
            "Credits do not expire",
          ]}
        />
        <SubscriptionCard
          title="Pro"
          description=""
          price="39.95"
          features={[
            "Everything in Standard",
            "Two way messaging",
            "One chatbot",
          ]}
        />
        <SubscriptionCard
          title="Pro Plus"
          description=""
          price="89.95"
          features={[
            "Everything in Pro",
            "Print address labels locally",
            "Integration with Australia Post",
          ]}
        />
      </div>
      <small className="mt-4 block max-w-prose whitespace-pre-wrap text-xs text-slate-400">
        <ol className="ml-4 list-decimal">
          <li>Pricing is subject to change.</li>
          <li>Transactions are processed in Australian Dollars (AUD).</li>
          <li>
            We use Stripe to process your payment. It's the same payment
            provider used by products such as Twitter, Pinterest, and Lyft. We
            do not handle your credit card information directly.
          </li>
        </ol>
      </small>
    </div>
  );
};

export default SubscriptionPage;
