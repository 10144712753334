import { CheckIcon } from "@heroicons/react/outline";
import { classNames } from "../../utils/helpers";

const ColorSelector = ({
  colors = [],
  value,
  setValue,
  disabled = false,
  allowDeselect = true,
}) => {
  const onClick = color => {
    if (color === value && allowDeselect) {
      setValue("");
    } else {
      setValue(color);
    }
  };

  return (
    <div className="grid grid-cols-5 gap-3">
      {colors.map(color => {
        const active = color === value;

        return (
          <button
            key={color}
            disabled={disabled}
            type="button"
            className={classNames(
              "h flex h-8 w-full items-center justify-center rounded disabled:opacity-50"
            )}
            style={{
              backgroundColor: color,
            }}
            onClick={() => onClick(color)}
          >
            {active && <CheckIcon className="h-4 w-4 text-gray-50" />}
          </button>
        );
      })}
    </div>
  );
};

export default ColorSelector;
