import Board from "@asseinfo/react-kanban";
import "@asseinfo/react-kanban/dist/styles.css";
import { Disclosure } from "@headlessui/react";
import {
  BanIcon,
  ChatAltIcon,
  ClockIcon,
  FastForwardIcon,
  XIcon,
  SearchIcon,
  SwitchVerticalIcon,
  ArchiveIcon,
  CashIcon,
  InformationCircleIcon,
  TrashIcon,
} from "@heroicons/react/outline";
import {
  ChevronDownIcon,
  DotsHorizontalIcon,
  PlusIcon,
} from "@heroicons/react/solid";
import dayjs from "dayjs";
import parsePhoneNumber, { isValidPhoneNumber } from "libphonenumber-js/mobile";
import * as React from "react";
import ReactGoogleAutocomplete from "react-google-autocomplete";
import { useForm } from "react-hook-form";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import useBoolean from "../hooks/useBoolean";
import { useAuth } from "../utils/auth";
import { CARD_COLORS, GOOGLE_MAPS_API_KEY } from "../utils/constants";
import {
  classNames,
  getBgClassName,
  getCurrencySymbol,
  getTimerInUnit,
  getUnitFromTimer,
} from "../utils/helpers";

import { getOrCreateStoreCredit } from "../utils/queries";
import ArchiveButton from "./ArchiveButton";
import FormDialog from "./dialogs/FormDialog";
import ColorSelector from "./forms/ColorSelector";
import "./kanban.css";
import Fuse from "fuse.js";
import { COMPOUNDING_COLUMNS } from "../pages/Compounding";
import { strapiAPI } from "../utils/api";

const AddCardButton = ({ column }) => {
  const queryClient = useQueryClient();
  const { store } = useAuth();

  const [dialogOpen, setDialogOpen] = useBoolean(false);

  const {
    register,
    unregister,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    setValue,
  } = useForm({
    defaultValues: {
      phoneNumber: "",
      firstName: "",
      lastName: "",
      address: "",
      description: "",
      color: "",
    },
    shouldUnregister: true,
  });

  React.useEffect(() => {
    if (dialogOpen) {
      register("color", {});
      register("address", {});
    } else {
      unregister("color");
      unregister("address");
    }

    return () => {
      unregister("color");
      unregister("address");
    };
  }, [register, unregister, dialogOpen]);

  const setAddress = address => {
    setValue("address", address);
  };
  const selectedColor = watch("color");
  const setColor = color => {
    setValue("color", color);
  };

  const { mutate, isLoading } = useMutation(
    ({ data }) => strapiAPI.post(`/compounding-cards`, { data }),
    {
      onSuccess: () => {
        queryClient.refetchQueries(["/compounding"]);
        setDialogOpen.off();
        toast.success("Created new card");
      },
      onError: () => {
        toast.error("Error creating card");
      },
    }
  );

  const onSubmit = data => {
    const phoneNumber = isValidPhoneNumber(data.phoneNumber, "AU")
      ? parsePhoneNumber(data.phoneNumber, "AU").number
      : parsePhoneNumber(data.phoneNumber, "PK").number;

    mutate({
      data: {
        phoneNumber: phoneNumber,
        firstName: data.firstName,
        lastName: data.lastName,
        address: data.address,
        description: data.description,
        color: data.color,
        status: "c0",
        store: {
          id: store.id,
        },
      },
    });
  };

  const onCancel = () => {
    reset();
    setDialogOpen.off();
  };

  return (
    <>
      <button
        className="flex h-7 w-full items-center justify-center rounded bg-gray-200 p-1 text-gray-600 transition-colors hover:bg-blue-100 hover:text-blue-600"
        onClick={setDialogOpen.on}
      >
        <PlusIcon className="h-5 w-5" />
      </button>
      <FormDialog
        open={dialogOpen}
        onClose={onCancel}
        title="Add New Card"
        disableClose={isLoading}
      >
        <h4 className="-mt-3 mb-4 text-center text-sm text-gray-500">
          to {column.title}
        </h4>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="form-group">
            <label htmlFor="phoneNumber">Phone Number</label>
            <input
              type="text"
              id="phoneNumber"
              autoFocus
              className={`${classNames(
                dialogOpen && errors.phoneNumber && "form-error"
              )}`}
              placeholder="Enter Phone Number..."
              disabled={isLoading}
              {...register("phoneNumber", {
                required: {
                  value: true,
                  message: "Phone Number is required",
                },
                validate: value => {
                  return (
                    isValidPhoneNumber(value, "AU") ||
                    isValidPhoneNumber(value, "PK") ||
                    "Phone Number must be a valid AU Phone Number"
                  );
                },
              })}
            />
            {dialogOpen && errors.phoneNumber && (
              <small className="form-error-text">
                {errors.phoneNumber.message}
              </small>
            )}
          </div>
          <div className="grid grid-cols-2 gap-4">
            <div className="form-group">
              <label htmlFor="firstName">First Name</label>
              <input
                type="text"
                id="firstName"
                className={`${classNames(
                  dialogOpen && errors.firstName && "form-error"
                )}`}
                placeholder="Enter first name..."
                disabled={isLoading}
                {...register("firstName", {
                  required: {
                    value: true,
                    message: "First Name is required",
                  },
                  maxLength: {
                    value: 50,
                    message: "First Name must be less than 50 characters",
                  },
                })}
              />
              {dialogOpen && errors.firstName && (
                <small className="form-error-text">
                  {errors.firstName.message}
                </small>
              )}
            </div>

            <div className="form-group">
              <label htmlFor="lastName">Last Name</label>
              <input
                type="text"
                id="lastName"
                className={`${classNames(
                  dialogOpen && errors.lastName && "form-error"
                )}`}
                placeholder="Enter last name..."
                disabled={isLoading}
                {...register("lastName", {
                  maxLength: {
                    value: 50,
                    message: "Last Name must be less than 50 characters",
                  },
                })}
              />
              {dialogOpen && errors.lastName && (
                <small className="form-error-text">
                  {errors.lastName.message}
                </small>
              )}
            </div>
          </div>

          <div className="form-group">
            <label htmlFor="description">Description</label>
            <textarea
              id="description"
              placeholder="Enter description..."
              disabled={isLoading}
              rows={2}
              {...register("description", {})}
            />
          </div>

          <div className="form-group">
            <label htmlFor="address">Address</label>

            <ReactGoogleAutocomplete
              className="mt-2"
              type="text"
              id="address"
              placeholder="Enter address..."
              disabled={isLoading}
              apiKey={GOOGLE_MAPS_API_KEY}
              onPlaceSelected={place => {
                setAddress(place.formatted_address);
              }}
              onChange={e => {
                setAddress(e.target.value);
              }}
              language="en-AU"
              options={{
                types: ["address"],
                componentRestrictions: { country: "au" },
              }}
              defaultValue={""}
            />
          </div>

          <div className="form-group">
            <label htmlFor="color">Color</label>
            <ColorSelector
              colors={CARD_COLORS}
              value={selectedColor}
              setValue={setColor}
              disabled={isLoading}
            />
          </div>

          {/* <Disclosure>
            {({ open }) => (
              <>
                <Disclosure.Button className="flex w-full items-center justify-between rounded bg-gray-100 p-3  hover:bg-gray-200">
                  <p className="mr-2 overflow-x-hidden text-ellipsis whitespace-nowrap text-sm">
                    Additional Options
                  </p>
                  <span className="flex min-w-fit items-center">
                    <ChevronDownIcon
                      className={`h-5 w-5 min-w-[1.25rem] ${
                        open ? "rotate-180 transform" : ""
                      }`}
                    />
                  </span>
                </Disclosure.Button>
                <Disclosure.Panel className="relative w-full pt-3 text-sm">

                  <hr />
                </Disclosure.Panel>
              </>
            )}
          </Disclosure> */}

          <div className="mt-4 grid grid-cols-2 gap-4">
            <button
              type="button"
              className="btn-danger-outline"
              disabled={isLoading}
              onClick={onCancel}
            >
              Cancel
            </button>
            <button type="submit" className="btn-primary" disabled={isLoading}>
              Create
            </button>
          </div>
        </form>
      </FormDialog>
    </>
  );
};

const ColumnHeader = ({
  boardId,
  column,
  allowCardAdd,
  filter,
  sort,
  sortColumnCards,
  clearColumnSort,
  filterColumnCards,
  clearColumnFilter,
}) => {
  const { store } = useAuth();

  return (
    <div className="relative mb-2 overflow-x-hidden text-ellipsis whitespace-nowrap pr-1">
      <Disclosure>
        {({ open }) => (
          <>
            <Disclosure.Button className="mb-1 flex w-full items-center justify-between px-1">
              <p className="mr-2 overflow-x-hidden text-ellipsis whitespace-nowrap">
                {column.title} {column.id}
              </p>
              <span className="flex min-w-fit items-center">
                {filter && (
                  <SearchIcon className="mr-1 h-5 w-5 text-blue-500" />
                )}
                {sort && (
                  <SwitchVerticalIcon className="mr-1 h-5 w-5 text-blue-500" />
                )}
                {/* {store.printers.length > 0 && column.type === "print_label" && (
                  <PrinterIcon className="mr-1 h-5 w-5 text-green-600" />
                )}
                {store.auspost && column.type === "lodge_parcel" && (
                  <CubeIcon className="mr-1 h-5 w-5 text-red-600" />
                )}
                {store.phones.length > 0 && column.type === "two_way" && (
                  <SwitchHorizontalIcon className="mr-1 h-5 w-5 text-sky-600" />
                )} */}
                {column.type === "archive" && (
                  <ArchiveIcon className="mr-1 h-5 w-5 text-amber-600" />
                )}
                {column.type === "payment" && (
                  <CashIcon className="mr-1 h-5 w-5 text-emerald-600" />
                )}
                <ChevronDownIcon
                  className={`h-5 w-5 min-w-[1.25rem] ${
                    open ? "rotate-180 transform" : ""
                  }`}
                />
              </span>
            </Disclosure.Button>

            <Disclosure.Panel className="relative w-full px-1 pb-1 text-sm text-gray-500">
              {column.message ? (
                <div className="mb-1 flex">
                  <ChatAltIcon className="mr-2 mt-0.5 h-4 w-4 min-w-[1.25rem] text-blue-600" />
                  <p className="whitespace-pre-wrap">{column.message}</p>
                </div>
              ) : (
                <>
                  {column.type === "payment" ? (
                    <div className="mb-1 flex">
                      <InformationCircleIcon className="mr-2 mt-0.5 h-4 w-4 min-w-[1.25rem] text-blue-600" />
                      <p className="whitespace-pre-wrap">
                        Create a payment for the user by clicking the card. And
                        drag to the next column to send a message with payment
                        link.
                      </p>
                    </div>
                  ) : (
                    <div className="mb-1 flex">
                      <BanIcon className="mr-2 mt-0.5 h-4 w-4 min-w-[1.25rem] text-blue-600" />
                      <p className="whitespace-pre-wrap">Staging Column</p>
                    </div>
                  )}
                </>
              )}
              {column.timer ? (
                <div className="mb-1 flex">
                  <ClockIcon className="mr-2 mt-0.5 h-4 w-4 min-w-[1.25rem] text-blue-600" />
                  <p className="whitespace-pre-wrap">
                    {getTimerInUnit(column.timer)}{" "}
                    {getUnitFromTimer(column.timer)}
                  </p>
                </div>
              ) : null}
              {column?.timerColumn?.data ? (
                <div className="mb-1 flex">
                  <FastForwardIcon className="mr-2 mt-0.5 h-4 w-4 min-w-[1.25rem] text-blue-600" />
                  <p className="whitespace-pre-wrap">
                    Move to{" "}
                    <strong>
                      {
                        column?.timerColumn?.data?.attributes?.board?.data
                          ?.attributes?.title
                      }
                    </strong>{" "}
                    &rarr;{" "}
                    <strong>
                      {column?.timerColumn?.data?.attributes?.title}
                    </strong>
                  </p>
                </div>
              ) : null}
            </Disclosure.Panel>

            <div className="px-1 pt-1">
              <div className="relative mb-2 flex items-center">
                <input
                  className="h-6 w-full rounded px-6 text-xs placeholder:text-inherit"
                  type="text"
                  placeholder="Filter cards (by name or phone)"
                  value={filter}
                  onChange={e => filterColumnCards(e.target.value)}
                  onFocus={e => {
                    e.target.select();
                  }}
                />
                <SearchIcon className="absolute left-2 h-3 w-3 text-slate-600" />
                {filter && (
                  <button
                    className="absolute right-1 flex h-4 w-4 cursor-pointer items-center justify-center rounded-full hover:bg-gray-100"
                    onClick={clearColumnFilter}
                  >
                    <XIcon className="h-3 w-3 text-slate-600" />
                  </button>
                )}
              </div>
              <div className="relative mb-4 flex items-center">
                <select
                  className="h-6 w-full rounded py-0 pl-6 pr-11 text-xs placeholder:text-inherit"
                  type="text"
                  placeholder="Sort cards"
                  value={sort}
                  onChange={e => sortColumnCards(e.target.value)}
                >
                  <option value="">Sort cards</option>
                  <option value="name_ASC">Name (ascending)</option>
                  <option value="name_DESC">Name (descending)</option>
                  <option value="movedAt_ASC">Date moved (newest first)</option>
                  <option value="movedAt_DESC">
                    Date moved (oldest first)
                  </option>
                  <option value="createdAt_ASC">
                    Date created (newest first)
                  </option>
                  <option value="createdAt_DESC">
                    Date created (oldest first)
                  </option>
                </select>
                <SwitchVerticalIcon className="absolute left-2 h-3 w-3 text-slate-600" />
                {sort && (
                  <button
                    className="absolute right-7 flex h-4 w-4 cursor-pointer items-center justify-center rounded-full hover:bg-gray-100"
                    onClick={clearColumnSort}
                  >
                    <XIcon className="h-3 w-3 text-slate-600" />
                  </button>
                )}
              </div>
            </div>
          </>
        )}
      </Disclosure>
      <div className="mt-2 flex items-center justify-between">
        <div className="mr-2 h-7 w-full">
          {allowCardAdd && <AddCardButton boardId={boardId} column={column} />}
        </div>

        <span className="ml-auto w-5 min-w-fit rounded bg-blue-100 p-0.5 text-center text-xs text-blue-600">
          {column.cards.length}
        </span>
      </div>
    </div>
  );
};

const PaymentSummary = ({ card }) => {
  const payment = card.payment;
  const total =
    card?.payment?.cartItems
      ?.reduce((prev, curr) => prev + curr.price * curr.quantity, 0)
      ?.toFixed(2) || "0.00";

  return (
    <div className="flex-1">
      <div className="flex w-full items-center justify-between">
        <h3 className="text-xl font-semibold">Payment</h3>
        <p
          className={classNames(
            "rounded px-1 py-px text-sm font-semibold uppercase",
            payment?.status === "error" && "bg-red-100 text-red-500",
            payment?.status === "success" && "bg-green-100 text-green-500",
            payment?.status === "pending" && "bg-yellow-100 text-yellow-500"
          )}
        >
          {payment?.status}
        </p>
      </div>
      <p className="text-2xl font-bold text-slate-800 md:text-3xl lg:text-4xl">
        {getCurrencySymbol(payment.currency)}
        {total}
      </p>
      <p className="mt-2 max-w-prose text-sm text-slate-700">
        {payment.description}
      </p>
      <div className="mt-8">
        {payment.cartItems.length > 0 && (
          <ul className="list-none rounded-md px-4 py-1 text-sm shadow">
            {payment.cartItems
              .sort((a, b) => {
                const aTotal = a.price * a.quantity;
                const bTotal = b.price * b.quantity;
                return bTotal - aTotal;
              })
              .map((item, idx) => (
                <li
                  key={item.id}
                  className={classNames(
                    "flex items-center justify-between py-3",
                    idx && "border-t border-black/5"
                  )}
                >
                  <div className="flex flex-col">
                    <p className="font-semibold text-slate-900">
                      {item.quantity} x {item.name}
                    </p>
                    <p className="text-xs text-slate-800">
                      {item.price * item.quantity} {payment.currency}
                    </p>
                  </div>
                  <div
                    className={classNames(
                      "h-10 w-10 rounded-sm",
                      getBgClassName(item.id.toString())
                    )}
                  />
                </li>
              ))}
          </ul>
        )}
      </div>
    </div>
  );
};

const CreatePayment = ({ card }) => {
  const queryClient = useQueryClient();

  const { register, handleSubmit } = useForm({
    defaultValues: {
      description: "",
    },
    shouldUnregister: true,
  });

  const { mutate, isLoading } = useMutation(
    ({ data }) => strapiAPI.post(`/payments`, { data }),
    {
      onSuccess: () => {
        queryClient.refetchQueries(["/compounding"]);
        toast.success("Created Payment");
      },
      onError: () => {
        toast.error("Error creating payment");
      },
    }
  );

  const onSubmit = data => {
    mutate({
      data: {
        description: data.description,
        compounding_card: card.id,
      },
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="form-group">
        <label htmlFor="description">Description</label>
        <textarea
          id="description"
          placeholder="Enter description..."
          disabled={isLoading}
          rows={2}
          {...register("description", {})}
        />
      </div>
      <button type="submit" className="btn-primary" disabled={isLoading}>
        Create Payment
      </button>
    </form>
  );
};

const CreateCartItem = ({ payment }) => {
  const queryClient = useQueryClient();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      name: "",
      quantity: 1,
      price: 0,
    },
    shouldUnregister: true,
  });

  const { mutate, isLoading } = useMutation(
    ({ data }) => strapiAPI.post(`/cart-items`, { data }),
    {
      onSuccess: () => {
        queryClient.refetchQueries(["/compounding"]);
        toast.success("Created Cart Item");
        reset();
      },
      onError: () => {
        toast.error("Error creating cart item");
      },
    }
  );

  const onSubmit = data => {
    mutate({
      data: {
        name: data.name,
        quantity: data.quantity,
        price: data.price,
        payment: payment.id,
      },
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="form-group">
        <label htmlFor="name">Name</label>
        <input
          type="text"
          id="name"
          className={`${classNames(errors.name && "form-error")}`}
          placeholder="Enter item name..."
          disabled={isLoading}
          {...register("name", {
            required: {
              value: true,
              message: "Name is required",
            },
            maxLength: {
              value: 50,
              message: "Name must be less than 50 characters",
            },
          })}
        />
        {errors.name && (
          <small className="form-error-text">{errors.name.message}</small>
        )}
      </div>
      <div className="grid grid-cols-2 gap-4">
        <div className="form-group">
          <label htmlFor="price">Price</label>
          <input
            type="text"
            id="price"
            className={`${classNames(errors.price && "form-error")}`}
            placeholder="Enter unit price..."
            disabled={isLoading}
            {...register("price", {
              required: {
                value: true,
                message: "Price is required",
              },
              min: {
                value: 0.1,
                message: "Price must be atleast 0.1",
              },
            })}
          />
          {errors.price && (
            <small className="form-error-text">{errors.price.message}</small>
          )}
        </div>
        <div className="form-group">
          <label htmlFor="quantity">Quantity</label>
          <input
            type="text"
            id="quantity"
            className={`${classNames(errors.quantity && "form-error")}`}
            placeholder="Enter quantity..."
            disabled={isLoading}
            {...register("quantity", {
              required: {
                value: true,
                message: "Quantity is required",
              },
              min: {
                value: 1,
                message: "Quantity must be atleast 1",
              },
            })}
          />
          {errors.quantity && (
            <small className="form-error-text">{errors.quantity.message}</small>
          )}
        </div>
      </div>

      <button type="submit" className="btn-primary" disabled={isLoading}>
        Add Item
      </button>
    </form>
  );
};

const CartItem = ({ item, payment }) => {
  const queryClient = useQueryClient();

  const { mutate, isLoading } = useMutation(
    ({ data }) => strapiAPI.delete(`/cart-items/${item.id}`, { data }),
    {
      onSuccess: () => {
        queryClient.refetchQueries(["/compounding"]);
        toast.success("Deleted Cart Item");
      },
      onError: () => {
        toast.error("Error deleting cart item");
      },
    }
  );
  return (
    <li
      key={item.id}
      className={classNames(
        "mb-2 flex items-center justify-between rounded-md border p-2 pl-3 text-sm"
      )}
    >
      <div className="flex flex-col">
        <p className="font-semibold text-slate-900">
          {item.quantity} x {item.name}
        </p>
        <p className="text-xs text-slate-800">
          {item.price * item.quantity} {payment.currency}
        </p>
      </div>
      <div
        className={classNames(
          "flex h-10 w-10 items-center justify-center rounded bg-red-50 text-red-600 hover:bg-red-100 disabled:cursor-not-allowed disabled:bg-gray-100 disabled:text-gray-600"
        )}
        onClick={mutate}
      >
        <button disabled={isLoading}>
          <TrashIcon className="h-5 w-5" />
        </button>
      </div>
    </li>
  );
};

const Payment = ({ card }) => {
  const payment = card.payment;

  return (
    <div className="basis-1/2">
      <div className="flex w-full items-center justify-between">
        <h3 className="text-xl font-semibold">Payment</h3>
        {payment && (
          <p
            className={classNames(
              "rounded px-1 py-px text-sm font-semibold uppercase",
              payment?.status === "error" && "bg-red-100 text-red-500",
              payment?.status === "success" && "bg-green-100 text-green-500",
              payment?.status === "pending" && "bg-yellow-100 text-yellow-500"
            )}
          >
            {payment?.status}
          </p>
        )}
      </div>
      {payment ? (
        <>
          <p className="mt-1 mb-3 text-sm">{payment.description}</p>
          {payment.cartItems.length > 0 && (
            <ul className="mt-4 mb-2 list-none">
              {payment.cartItems
                .sort((a, b) => {
                  const aTotal = a.price * a.quantity;
                  const bTotal = b.price * b.quantity;
                  return bTotal - aTotal;
                })
                .map((item, idx) => (
                  <CartItem key={item.id} item={item} payment={card.payment} />
                ))}
            </ul>
          )}
          <CreateCartItem payment={card.payment} />
        </>
      ) : (
        <CreatePayment card={card} />
      )}
    </div>
  );
};

const Card = ({ card, cardBag, allowCardEdit }) => {
  const queryClient = useQueryClient();

  const [dialogOpen, setDialogOpen] = useBoolean(false);

  const {
    register,
    unregister,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    setValue,
  } = useForm({
    defaultValues: {
      phoneNumber: card.phoneNumber,
      firstName: card.firstName,
      lastName: card.lastName,
      address: card.address,
      description: card.description,
      color: card.color,
    },
    shouldUnregister: true,
  });

  React.useEffect(() => {
    if (dialogOpen) {
      register("color", {});
      register("address", {});
    } else {
      unregister("color");
      unregister("address");
    }

    return () => {
      unregister("color");
      unregister("address");
    };
  }, [dialogOpen, register, unregister]);

  const setAddress = address => {
    setValue("address", address);
  };

  const selectedColor = watch("color", card.color);
  const setColor = color => {
    setValue("color", color);
  };

  const { mutate, isLoading } = useMutation(
    ({ cardId, data }) =>
      strapiAPI.put(`/compounding-cards/${cardId}`, { data }),
    {
      onSuccess: (_, variables) => {
        queryClient.refetchQueries(["/compounding"]);
        reset({
          ...variables.formData,
        });
        setDialogOpen.off();
        toast.success("Edited card");
      },
      onError: () => {
        toast.error("Error editing card");
      },
    }
  );

  const onSubmit = data => {
    const phoneNumber = isValidPhoneNumber(data.phoneNumber, "AU")
      ? parsePhoneNumber(data.phoneNumber, "AU").number
      : parsePhoneNumber(data.phoneNumber, "PK").number;

    mutate({
      data: {
        phoneNumber: phoneNumber,
        firstName: data.firstName,
        lastName: data.lastName,
        address: data.address,
        description: data.description,
        color: data.color,
      },
      cardId: card.id,
      formData: data,
    });
  };

  const onCancel = () => {
    reset();
    setDialogOpen.off();
  };

  const onArchive = () => {
    onCancel();
    queryClient.refetchQueries(["/compounding"]);
  };

  const onOpen = e => {
    e.stopPropagation();
    if (!allowCardEdit) {
      toast.error("You can't edit this card");
      return;
    }
    setDialogOpen.on();
  };

  return (
    <div
      className={classNames(
        `relative mt-2 w-full max-w-[284px] overflow-x-hidden rounded p-1.5`,
        cardBag.dragging ? "rotate-3 opacity-75 shadow-md" : "shadow"
      )}
      style={{
        borderTop: `5px solid ${card.color || "#fff"}`,
        backgroundColor: `${card.color ? card.color + "22" : "#fff"}`,
      }}
      onClick={onOpen}
    >
      <div className="relative flex w-full flex-col">
        <button
          className="absolute right-0 bottom-0 rounded p-0.5 text-gray-500 hover:bg-black/5"
          onClick={onOpen}
        >
          <DotsHorizontalIcon className="h-4 w-4" />
        </button>
        <div className="ml-auto flex flex-col items-end justify-end">
          <p className="mb-1 rounded bg-white/50 px-1 py-px text-xs font-semibold">
            {card.store.name}
          </p>
          <p
            className={classNames(
              "mb-1 rounded px-1 py-px text-xs font-semibold uppercase",
              card?.payment?.status === "error" && "bg-red-100 text-red-500",
              card?.payment?.status === "success" &&
                "bg-green-100 text-green-500",
              card?.payment?.status === "pending" &&
                "bg-yellow-100 text-yellow-500"
            )}
          >
            {card?.payment?.status}
          </p>
        </div>
        <p
          className={classNames(
            "text-sm font-bold",
            card.payment ? "-mt-10" : "-mt-5"
          )}
        >
          {card.firstName} {card.lastName}
        </p>
        <p className="text-xs text-gray-600">{card.phoneNumber}</p>
        {card.description && (
          <p className="my-1 text-xs text-gray-500">
            {card.description.length > 100
              ? card.description.slice(0, 100) + "..."
              : card.description}
          </p>
        )}
      </div>

      <FormDialog
        expand={Boolean(card?.payment || card?.col?.type === "payment")}
        open={dialogOpen}
        onClose={onCancel}
        title="Edit Card"
        disableClose={isLoading}
      >
        <div className="flex gap-8">
          <form
            className="w-full max-w-xl flex-grow"
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className="form-group">
              <label htmlFor="phoneNumber">Phone Number</label>
              <input
                type="text"
                id="phoneNumber"
                autoFocus
                className={`${classNames(
                  dialogOpen && errors.phoneNumber && "form-error"
                )}`}
                placeholder="Enter Phone Number..."
                disabled={isLoading}
                {...register("phoneNumber", {
                  required: {
                    value: true,
                    message: "Phone Number is required",
                  },
                  validate: value => {
                    return (
                      isValidPhoneNumber(value, "AU") ||
                      isValidPhoneNumber(value, "PK") ||
                      "Phone Number must be a valid AU Mobile Number"
                    );
                  },
                })}
              />
              {dialogOpen && errors.phoneNumber && (
                <small className="form-error-text">
                  {errors.phoneNumber.message}
                </small>
              )}
            </div>
            <div className="grid grid-cols-2 gap-4">
              <div className="form-group">
                <label htmlFor="firstName">First Name</label>
                <input
                  type="text"
                  id="firstName"
                  className={`${classNames(
                    dialogOpen && errors.firstName && "form-error"
                  )}`}
                  placeholder="Enter name..."
                  disabled={isLoading}
                  {...register("firstName", {
                    required: {
                      value: true,
                      message: "First Name is required",
                    },
                    maxLength: {
                      value: 50,
                      message: "First Name must be less than 50 characters",
                    },
                  })}
                />
                {dialogOpen && errors.firstName && (
                  <small className="form-error-text">
                    {errors.firstName.message}
                  </small>
                )}
              </div>

              <div className="form-group">
                <label htmlFor="lastName">Last Name</label>
                <input
                  type="text"
                  id="lastName"
                  className={`${classNames(
                    dialogOpen && errors.lastName && "form-error"
                  )}`}
                  placeholder="Enter name..."
                  disabled={isLoading}
                  {...register("lastName", {
                    maxLength: {
                      value: 50,
                      message: "Last Name must be less than 50 characters",
                    },
                  })}
                />
                {dialogOpen && errors.lastName && (
                  <small className="form-error-text">
                    {errors.lastName.message}
                  </small>
                )}
              </div>
            </div>

            <div className="form-group">
              <label htmlFor="description">Description</label>
              <textarea
                id="description"
                placeholder="Enter description..."
                disabled={isLoading}
                rows={2}
                {...register("description", {})}
              />
            </div>

            <div className="form-group">
              <label htmlFor="address">Address</label>

              <ReactGoogleAutocomplete
                className="mt-2"
                type="text"
                id="address"
                placeholder="Enter address..."
                disabled={isLoading}
                apiKey={GOOGLE_MAPS_API_KEY}
                onPlaceSelected={place => {
                  setAddress(place.formatted_address);
                }}
                onChange={e => {
                  setAddress(e.target.value);
                }}
                language="en-AU"
                options={{
                  types: ["address"],
                  fields: ["formatted_address", "place_id"],
                  componentRestrictions: { country: "au" },
                }}
                defaultValue={card.address}
              />
            </div>

            <div className="form-group">
              <label htmlFor="color">Color</label>
              <ColorSelector
                colors={CARD_COLORS}
                value={selectedColor}
                setValue={setColor}
                disabled={isLoading}
              />
            </div>

            {/* <Disclosure>
            {({ open }) => (
              <>
                <Disclosure.Button className="flex w-full items-center justify-between rounded bg-gray-100 p-3  hover:bg-gray-200">
                  <p className="mr-2 overflow-x-hidden text-ellipsis whitespace-nowrap text-sm">
                    Additional Options
                  </p>
                  <span className="flex min-w-fit items-center">
                    <ChevronDownIcon
                      className={`h-5 w-5 min-w-[1.25rem] ${
                        open ? "rotate-180 transform" : ""
                      }`}
                    />
                  </span>
                </Disclosure.Button>
                <Disclosure.Panel className="relative w-full pt-3 text-sm">
                  <div className="my-2 flex w-full items-center justify-between rounded bg-red-50 p-3">
                    <p>Archive this card?</p>

                    <ArchiveButton
                      disabled={isLoading}
                      contentType="card"
                      contentId={card.id}
                      onSuccess={onArchive}
                    />
                  </div>
                  <hr />
                </Disclosure.Panel>
              </>
            )}
          </Disclosure> */}

            <div className="mt-4 grid grid-cols-3 gap-4">
              <ArchiveButton
                disabled={isLoading}
                contentType="compounding"
                contentId={card.id}
                onSuccess={onArchive}
              />
              <button
                type="button"
                className="btn-danger-outline"
                disabled={isLoading}
                onClick={onCancel}
              >
                Cancel
              </button>

              <button
                type="submit"
                className="btn-primary"
                disabled={isLoading}
              >
                Save
              </button>
            </div>
          </form>
          {card.payment && card?.col?.type !== "payment" && (
            <PaymentSummary card={card} />
          )}
          {card?.col?.type === "payment" && <Payment card={card} />}
        </div>
      </FormDialog>
    </div>
  );
};

const CompoundingKanban = ({ data }) => {
  const { store } = useAuth();
  const queryClient = useQueryClient();

  const { mutate, isLoading } = useMutation(
    ({ data }) => strapiAPI.post(`/custom/compounding/move`, { data }),
    {
      onMutate: variables => {
        queryClient.setQueryData(["/compounding"], oldData => {
          return {
            ...oldData,
            data: {
              ...oldData.data,
              attributes: {
                ...oldData.data.attributes,
                cards: oldData.data.attributes.cards.map(card => {
                  if (card.id === variables.data.cardId) {
                    return {
                      ...card,
                      status: variables.data.destination,
                    };
                  }

                  return card;
                }),
              },
            },
          };
        });
      },
      onSuccess: () => {
        queryClient.refetchQueries(["/compounding"]);
        toast.success("Card Moved");
      },
      onError: () => {
        queryClient.refetchQueries(["/compounding"]);
        toast.error("Error moving card");
      },
    }
  );

  const [filters, setFilters] = React.useState({});

  const filterColumnCards = columnId => query => {
    setFilters(f => ({ ...f, [columnId]: query }));
  };

  const clearColumnFilter = columnId => () => {
    setFilters(f => ({ ...f, [columnId]: "" }));
  };

  // const clearAllFilters = () => {
  //   setFilters({});
  // };

  const [sorts, setSorts] = React.useState({});

  const sortColumnCards = columnId => query => {
    setSorts(s => ({ ...s, [columnId]: query }));
  };

  const clearColumnSort = columnId => () => {
    setSorts(s => ({ ...s, [columnId]: "" }));
  };

  // const clearAllSorts = () => {
  //   setSorts({});
  // };

  const { data: creditData } = useQuery(
    ["/store/credit"],
    getOrCreateStoreCredit,
    {
      retry: false,
    }
  );

  if (data) {
    const isAdmin = data?.attributes?.adminStore?.data?.id === store?.id;
    const columns = COMPOUNDING_COLUMNS.map(column => {
      let cards =
        data?.attributes?.cards
          ?.filter(
            card =>
              (isAdmin ? true : card?.store?.id === store?.id) &&
              card?.status === column.status
          )
          ?.map(card => ({ ...card, col: column })) || [];

      const filter = filters[column.id];
      if (filter) {
        const fuse = new Fuse(cards, {
          includeScore: true,
          threshold: 0.0,
          keys: [
            {
              name: "name",
              getFn: card => `${card.firstName} ${card.lastName}`,
            },
            "phoneNumber",
          ],
          useExtendedSearch: false,
          ignoreLocation: true,
        });
        cards = fuse.search(filter).map(result => result.item);
      }

      const sort = sorts[column.id];
      if (sort) {
        const [sortBy, sortOrder] = sort.split("_");
        cards = cards.sort((a, b) => {
          if (sortBy === "name") {
            return sortOrder === "ASC"
              ? b.firstName.localeCompare(a.firstName)
              : a.firstName.localeCompare(b.firstName);
          }
          if (sortBy === "createdAt") {
            return sortOrder === "ASC"
              ? dayjs(a.createdAt).isBefore(dayjs(b.createdAt))
                ? 1
                : -1
              : dayjs(a.createdAt).isAfter(dayjs(b.createdAt))
              ? 1
              : -1;
          }
          if (sortBy === "movedAt") {
            return sortOrder === "ASC"
              ? dayjs(a.movedAt).isBefore(dayjs(b.movedAt))
                ? 1
                : -1
              : dayjs(a.movedAt).isAfter(dayjs(b.movedAt))
              ? 1
              : -1;
          }
          return 0;
        });
      }

      return {
        ...column,
        cards,
      };
    });
    const handleCardDragEnd = async (card, source, destination) => {
      if (!isAdmin) {
        return;
      }

      if (source.fromColumnId === destination.toColumnId) {
        return;
      }

      const sourceColumn = columns.find(col => col.id === source.fromColumnId);

      const destinationColumn = columns.find(
        col => col.id === destination.toColumnId
      );

      if (
        destinationColumn.message &&
        sourceColumn.position > destinationColumn.position
      ) {
        toast.error("Cannot move card to a previous column");
        return;
      }

      mutate({
        data: {
          cardId: card.id,
          destination: destination.toColumnId,
        },
      });
    };

    return (
      <div className={classNames("board", isLoading && "pointer-events-none")}>
        <Board
          disableCardDrag={
            !isAdmin || (creditData && creditData?.credit?.amount === 0)
          }
          disableColumnDrag
          onCardDragEnd={handleCardDragEnd}
          renderCard={(card, cardBag) => (
            <Card
              card={card}
              cardBag={cardBag}
              allowCardEdit={isAdmin || card.col.id === "c0"}
            />
          )}
          renderColumnHeader={column => (
            <ColumnHeader
              column={column}
              filter={filters[column.id]}
              filterColumnCards={filterColumnCards(column.id)}
              clearColumnFilter={clearColumnFilter(column.id)}
              sort={sorts[column.id]}
              sortColumnCards={sortColumnCards(column.id)}
              clearColumnSort={clearColumnSort(column.id)}
              allowCardAdd={column.id === "c0"}
            />
          )}
        >
          {{ columns }}
        </Board>
      </div>
    );
  }
};

export default CompoundingKanban;
