import { Menu, Transition } from "@headlessui/react";
import { DotsHorizontalIcon } from "@heroicons/react/solid";
import dayjs from "dayjs";
import * as React from "react";
import { useQuery } from "react-query";
import { Link, Outlet, useLocation, useParams } from "react-router-dom";
import useIntersectionObserver from "../hooks/useIntersectionObserver";
import { classNames, textColor } from "../utils/helpers";
import { findAllBoards } from "../utils/queries";
import { useTheme } from "../utils/theme";

const PinnedBoardLink = ({ board, root, setVisibility, roundedR = false }) => {
  const { boardId } = useParams();
  const { setColor } = useTheme();

  const ref = React.useRef(null);
  const entry = useIntersectionObserver(ref, { root });
  const isVisible = !!entry?.isIntersecting;

  React.useEffect(() => {
    setVisibility(isVisible);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible]);

  return (
    <Link
      ref={ref}
      to={`/boards/${board.id}`}
      key={board.id}
      className={classNames(
        "w-[100px] min-w-fit max-w-full p-0",
        isVisible
          ? "bg-white"
          : "pointer-events-none cursor-default bg-transparent",
        roundedR && "rounded-r"
      )}
      onClick={() => {
        setColor(board.attributes.color || null);
      }}
    >
      <div
        className={classNames(
          "m-0 flex h-full w-full items-center justify-center border border-black/10 px-2 py-1 text-sm",
          parseInt(boardId) === board.id &&
            "outline outline-2 outline-offset-[-2px] outline-black/75",
          !isVisible && "opacity-0",
          roundedR && "rounded-r"
        )}
        style={{
          backgroundColor: board.attributes.color
            ? `${board.attributes.color}${
                parseInt(boardId) === board.id ? "ff" : "77"
              }`
            : `#0000`,
          color: textColor(board.attributes.color),
        }}
      >
        {board.attributes.title}
      </div>
    </Link>
  );
};

const PinnedBoardLinks = ({ pinnedBoards }) => {
  const { boardId } = useParams();
  const { setColor } = useTheme();
  const rootRef = React.useRef(null);
  const [boardsVisibility, setBoardsVisibility] = React.useState(() =>
    pinnedBoards.map(() => false)
  );

  const setVisibility = idx => isVisible => {
    setBoardsVisibility(boards => {
      boards[idx] = isVisible;
      return [...boards];
    });
  };

  const menuBoards = React.useMemo(() => {
    return pinnedBoards.filter((_, idx) => !boardsVisibility[idx]);
  }, [boardsVisibility, pinnedBoards]);

  return (
    <>
      <div
        ref={rootRef}
        className="mr-[2rem] flex max-w-full basis-full overflow-x-clip"
      >
        {pinnedBoards.map((board, idx) => (
          <PinnedBoardLink
            key={board.id}
            board={board}
            root={rootRef.current}
            setVisibility={setVisibility(idx)}
            roundedR={idx === pinnedBoards.length - 1}
          />
        ))}
        {menuBoards.length > 0 ? (
          <Menu
            as="div"
            className="absolute right-2 top-0 inline-block text-left"
          >
            <Menu.Button className="mr-auto flex h-8 w-8 min-w-[1.75rem] items-center justify-center rounded-r border border-black/10 p-1 transition-colors hover:bg-black/5">
              <DotsHorizontalIcon className="h-4 w-4" />
            </Menu.Button>
            <Transition
              as={React.Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="absolute right-0 z-50 mt-2 w-44 origin-top-right space-y-1 overflow-hidden text-ellipsis whitespace-nowrap rounded bg-white p-1 text-gray-800 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                {menuBoards.map(board => (
                  <Menu.Item key={board.id}>
                    <Link
                      to={`/boards/${board.id}`}
                      className={classNames(
                        `group flex h-8 w-full items-center overflow-hidden text-ellipsis whitespace-nowrap rounded-sm px-2 py-1 text-sm hover:opacity-80`,
                        parseInt(boardId) === board.id &&
                          "outline outline-2 outline-offset-[-1px] outline-black/75"
                      )}
                      onClick={() => {
                        setColor(board.attributes.color || null);
                      }}
                      style={{
                        backgroundColor: board.attributes.color
                          ? `${board.attributes.color}${
                              parseInt(boardId) === board.id ? "ff" : "77"
                            }`
                          : `#000${parseInt(boardId) === board.id ? "2" : "0"}`,
                        color: textColor(board.attributes.color),
                      }}
                    >
                      {board.attributes.title}
                    </Link>
                  </Menu.Item>
                ))}
              </Menu.Items>
            </Transition>
          </Menu>
        ) : (
          <span className="min-w-[2rem]" />
        )}
      </div>
    </>
  );
};

const AllBoardsLink = ({ roundedR = false, roundedL = false }) => {
  const location = useLocation();
  const { setColor } = useTheme();

  return (
    <Link
      to="/boards"
      className={classNames(
        "w-fit bg-white p-0",
        roundedR && "rounded-r",
        roundedL && "rounded-l"
      )}
      onClick={() => {
        setColor(null);
      }}
    >
      <div
        className={classNames(
          "m-0 flex h-full w-full items-center justify-center border border-black/10 px-2 py-1 text-sm",
          ["/", "/boards"].includes(location?.pathname) &&
            "outline outline-2 outline-offset-[-2px] outline-black/75",
          roundedR && "rounded-r",
          roundedL && "rounded-l"
        )}
        style={{
          backgroundColor: `#0000`,
          color: textColor(null),
        }}
      >
        All Conductors
      </div>
    </Link>
  );
};

const PinnedBoards = () => {
  const { data } = useQuery(["/boards"], findAllBoards);

  const pinnedBoards = React.useMemo(
    () =>
      data?.data
        ?.filter(board => board.attributes.pinned)
        ?.sort((a, b) => {
          return dayjs(a.attributes.updatedAt).isBefore(
            dayjs(b.attributes.updatedAt)
          )
            ? 1
            : -1;
        }) || [],
    [data]
  );

  if (data) {
    return (
      <div className="relative mt-1 flex h-8 w-full whitespace-nowrap px-2">
        <AllBoardsLink roundedL roundedR={pinnedBoards.length === 0} />
        <PinnedBoardLinks pinnedBoards={pinnedBoards} />
      </div>
    );
  }

  return (
    <div className="min-h-8 mt-1 flex w-full whitespace-nowrap px-2">
      <AllBoardsLink roundedR roundedL />
    </div>
  );
};

const BoardsLayout = () => {
  return (
    <>
      <PinnedBoards />
      <Outlet />
    </>
  );
};

export default BoardsLayout;
